import { decodeToken } from "react-jwt";

export const verifyUserRole = (allowedUserRole) => { 
    if(!allowedUserRole?.length){
        return true;
     }
     
    const token = localStorage.getItem('token');
    if (!token) {
        return false;
    }

    const decodedToken = decodeToken(token);

    // Mapping user roles to token fields for easier checks
    const userRoles = {
        Admin: decodedToken?.isAdmin,
        Accountant: decodedToken?.isAccountant,
        CardHolder: decodedToken?.cardHolder,
        Reviewer: decodedToken?.reviewer?.length > 0, // Check for reviewer presence
    };

    // If Admin is in allowedUserRole and the user is Admin, prioritize returning true
    if (allowedUserRole.includes("Admin") && userRoles.Admin) {
        return true;
    }

    // Check if any allowed role matches the user's roles
    for (const role of allowedUserRole) {
        if (userRoles[role]) {
            return true;
        }
    }

    return false;
};

export const userRoles = {
    admin: 'Admin',
    accountant: 'Accountant',
    cardHolder: 'CardHolder',
    reviewer: 'Reviewer',
}; 

export const allowedUserRoles = {
    admin: [userRoles.admin],
    adminAndAccountant: [userRoles.admin, userRoles.accountant],
    adminAccountantAndCardHolder: [userRoles.admin, userRoles.accountant, userRoles.cardHolder],
    allRoles: Object.values(userRoles),
    adminAccountantAndReviewer: [userRoles.admin, userRoles.accountant, userRoles.reviewer],
    adminAccountantReviewerAndCardHolder: [userRoles.admin, userRoles.accountant, userRoles.reviewer, userRoles.cardHolder],
};