// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphCustomCss .transaction-text {
  font-size: 18px;
  color: #248F71;
  margin-left: 4px;
  line-height: 24.51px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/Ghaph/Graph.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,cAAA;EACA,gBAAA;EACA,oBAAA;EACA,gBAAA;AAAR","sourcesContent":[".GraphCustomCss {\n    .transaction-text {\n        font-size: 18px;\n        color: #248F71;\n        margin-left: 4px;\n        line-height: 24.51px;\n        text-align: left;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
