import "./expense.scss";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { addCardMoney, getCardEmployees, getEmployees } from '../Cards/slice/cardListSlice';
import { addExpenseTransaction, approveRejectExpense, exportAllExpenses, fetchCurrencies, getAllExpenseListByCompany, getAllTagByGroup, getAvaliableBalance, getCardByemployeeId, getCategoryList, getEmployeeExpenseList, getExpenseDetails, getExpensesForApproval, handleExpenseFilters, saveTags, updateCardTransaction, uploadTransactionReceipt, simulateTransaction } from './expensesSlice';
import { useDispatch, useSelector } from 'react-redux';
import AccountsIcon from "../../assets/images/Accounts icon.png";
import CrossImage from "../../assets/images/close-square-8.png";
import ExpensesComponent from "../../components/Expenses/ExpensesComponent";
import ExpensesModalTabs from "../../components/Expenses/ExpensesModalTabs";
import FilterButton from "../../assets/images/filter_icon.png";
import ForYourReviewTabs from "../../components/Expenses/ForYourReviewTabs";
import ImagesCross from "../../assets/images/close-square-8.png";
import Logo2 from "../../assets/images/polo.png";
import Modal from "react-bootstrap/Modal";
import MytrascationTabs from "../../components/Expenses/MytrascationTabs";
import OtpInput from "react-otp-input";
import Select from "react-select";
import addBtn from "../../assets/images/AddGreenIcon.png";
import { cleanAmount, createEndDateWithTimezone, createStartDateWithTimezone } from "../../utils/XpendlessUtils";
import cookies from "js-cookie";
import { createAuditTrail } from "../TopUpRequest/slice/topUpSlice";
import { decodeToken } from "react-jwt";
import { exportTransactionRecords, } from '../Export/exportSlice';
import { getAllDepartment } from '../Teams/teamSlice';
import lineBackground from '../../assets/images/backgroundLineDesign.png';
import moment from 'moment-timezone';
import notFound from '../../assets/images/not_found.png';
import selectedFilterButton from '../../assets/images/fluent_filter-24-filled.png';
import { toast } from 'react-toastify';
import useDidUpdateEffect from '../../utils/useDidUpdateHook';
import { useLocation } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import { MAX_VALUE } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingComponent from '../../components/Loader/LoadingComponent';
import { allowedUserRoles, verifyUserRole } from '../../utils/verifyUserRole';
import { activeBlocked, verifyCompanyStatus } from '../../utils/verifyCompanyStatus';
import { useMutation } from "@tanstack/react-query";
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import IconButton from '../../components/Buttons/IconButton';
import { Stack } from "@mui/material";

function Expenses() {
  const { state } = useLocation();
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [checkedAllPages, setChekedAllPages] = useState(false);
  const [pseudoCheckedAll, setPseudoCheckedAll] = useState(false);
  const [uncheckedTransaction, setUncheckedTransaction] = useState([]);
  const [cardTransactionId, setCardTransactionId] = useState(null);
  const {
    isLoading,
    isBalanceLoading,
    isExpenseDetailLoading,
    isExpenseExportLoading,
    avaliableBalanceDetails,
    expenseList,
    expenseDetails,
    categoryList,
    tags,
    companyAllExpenses,
    employeeCardList,
    currencies,
    filters,
  } = useSelector((state) => state.expenseReducer);
  const cardReducer = useSelector((state) => state.cardListReducer);
  const { isExportLoading } = useSelector((state) => state.exportReducer);
  const { departmentData } = useSelector((state) => state?.teamSlice);
  const dispatch = useDispatch();
  const { employees, cardEmployees } = useSelector((state) => state?.cardListReducer);
  const decodedToken = decodeToken(token);
  const { t } = useTranslation();
  const [employeeCards, setEmployeeCards] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {
    mutateAsync: mutateSimulateTransaction,
    isPending: isPendingSimulateTransaction,
  } = useMutation({
    mutationFn: (payload) => simulateTransaction(payload),
    onSuccess: () => {
      setIsModalVisible(false);
      toast.success("Transaction simulated successfully");
    },
    onError: (error) => {
      toast.error(error.message || t("server_issue"));
    },
  });
  const handleSimulate = async (data) => {
    const transactionData = {
      message_type: "AUTHORIZATION",
      transaction_type: "PURCHASE",
      card_number: data.card_number,
      card_expiry_date: data.expire,
      cvv2: data.cvv,
      transaction_amount: 20,
      transaction_currency: "QAR",
      billing_amount: 20,
      billing_currency: "QAR",
      date_time_acquirer: "0928201947",
      network: "VISA",
      network_transaction_id: "234551234561008",
      terminal_id: "TERMID01",
      merchant_id: "MERCHID01",
      mcc: "5411",
      merchant_name: "ACQUIRER NAME",
      merchant_country: "USA",
      merchant_city: "New York",
      pin: data.pin,
    };
    await mutateSimulateTransaction(transactionData);
    resetSimulateForm();
  };
  const handleCloseModal = () => {
    resetSimulateForm();
    setIsModalVisible(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = (cardTransactionId) => {
    setShow(true);
    setCardTransactionId(cardTransactionId);
    if (cardTransactionId) {
      dispatch(getExpenseDetails(`?cardTransactionId=${cardTransactionId}`)).unwrap().catch((error) => {
        console.error("Error occurred:", error);
        toast.error(error.message || t("server_issue"));
      });
      if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
        dispatch(getAllTagByGroup()).unwrap().catch((error) => {
          console.error("Error occurred:", error);
          toast.error(error.message || t("server_issue"));
        });
      }
    }
  };
  const handleCloseModalTwo = () => {
    setEmployeeCards([]);
    setShowTwo(false);
  };
  const handleShowTwo = () => setShowTwo(true);
  const handleCloseModalThree = () => setShowThree(false);
  const handleShowThree = () => setShowThree(true);
  const handleCloseModalFilter = () => {
    setShowFilter(false);
  };
  const handleShowFilter = () => setShowFilter(true);
  const [filterType, setFilterType] = useState('');
  const [activeTab, setActiveTab] = useState((decodedToken?.isAdmin || decodedToken?.isAccountant) ? '1' : '2');
  const [defaultActiveKey] = useState(state?.parentTab ? state?.parentTab : (decodedToken?.isAdmin || decodedToken?.isAccountant) ? "1" : "2");
  const [pageNo, setPageNo] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [wholePart, decimalPart] = avaliableBalanceDetails?.avaliableBalance
    ? avaliableBalanceDetails?.avaliableBalance
      .toString()
      .split(".")
      .map((part, index) =>
        index === 1 ? part.padEnd(2, "0") : Number(part)
      )
    : ["00", "00"];
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const handleShowVerifyModal = () => setShowVerifyModal(true);
  const handleCloseVerifyModal = () => setShowVerifyModal(false);
  const [timer, setTimer] = useState(60);
  const [topUpDataData, setTopUpDataData] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const {
    control: controlRejectForm,
    handleSubmit: handleRejectSubmit,
    formState: { errors: errorsRejectForm },
    reset: resetRejectForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        comments: yup.string().required(),
      })
    ),
  });

  const {
    control: transactionControl,
    handleSubmit: handleTransactionSubmit,
    watch: transactionWatch,
    formState: { errors: errorTransaction },
    reset: resetTransactionForm,
    resetField,
    clearErrors,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        merchantName: yup.string().required("Merchant name is required"),
        amount: yup
          .string()
          .required("Amount is required")
          .matches(/^[^a-zA-Z]*$/, "Please enter valid amount"),
        employeeId: yup.string().required("Please select Employee"),
        cardId: yup.string().required("Please select a card"),
        currencyId: yup.object().shape({
          value: yup.string().required("Please select Currency"),
          label: yup.string(),
          unit: yup.number(),
        }),
        selectedCurrencyAmount: yup.string().when("currencyId", {
          is: (currencyId) => currencyId && currencyId.value,
          then: () =>
            yup.string().test({
              name: "is-valid-decimal",
              test: function (value) {
                const unit = Number(this.parent.currencyId.unit);
                if (unit > 0) {
                  const regex = new RegExp(`^[0-9]+\\.[0-9]{${unit}}?$`);
                  if (!regex.test(value)) {
                    throw this.createError({
                      path: "selectedCurrencyAmount",
                      message: `Please enter a valid amount with ${unit} decimal(s)`,
                    });
                  } else return true;
                } else {
                  const noDecimalRegex = /^[0-9]+$/;
                  if (!noDecimalRegex.test(value)) {
                    throw this.createError({
                      path: "selectedCurrencyAmount",
                      message:
                        "Please enter a valid amount without decimal places",
                    });
                  } else return true;
                }
              },
            }),
          otherwise: () =>
            yup.string().required("Selected currency requires an amount"),
        }),
      })
    ),
  });

  const selectedEmployeeId = transactionWatch("employeeId");

  useEffect(() => {
    resetField("cardId", { defaultValue: "" });
    clearErrors("cardId");
  }, [selectedEmployeeId, resetField, clearErrors]);

  const handleShowFour = () => setShowFour(true);

  const handleCloseModalFour = () => {
    resetTransactionForm();
    setShowFour(false);
    setEmployeeCards([]);
  };

  const transactionValues = transactionWatch();

  const filePermission = useMemo(() => {
    return (
      decodedToken?.isAccountant ||
      decodedToken?.isAdmin ||
      decodedToken?.isGlobalReviewer ||
      decodedToken?.reviewer?.length > 0 ||
      (activeTab && activeTab === "2")
    );
  }, [decodedToken, activeTab]);

  const hasAccess = useMemo(() => {
    return (
      decodedToken?.isAccountant ||
      decodedToken?.isAdmin ||
      decodedToken?.isGlobalReviewer ||
      decodedToken?.reviewer?.length > 0
    );
  }, [decodedToken]);

  useEffect(() => {
    if (transactionValues?.employeeId) {
      dispatch(getCardByemployeeId(transactionValues?.employeeId))
        .unwrap()
        .then((data) => {
          setEmployeeCards(data?.data);
        })
        .catch((error) => {
          toast.error(error.message || t("server_issue"));
          setEmployeeCards([]);
        });
    }
  }, [transactionValues?.employeeId]);

  useEffect(() => {
    dispatch(
      createAuditTrail({
        eventType: "access",
        module: "expenses",
        description: `Fetch all expenses`,
      })
    ).catch((error) => {
      console.error("Error occurred:", error);
      toast.error(error.message || t("server_issue"));
    });
    let queryParam = `?filterType=company`;
    if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
      dispatch(getAvaliableBalance(queryParam)).catch((error) => {
        console.error("Error occurred:", error);
        toast.error(error.message || t("server_issue"));
      });
    }
    dispatch(getEmployees());
    dispatch(getCardEmployees());
    if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
      dispatch(getCategoryList()).catch((error) => {
        console.error("Error occurred:", error);
        toast.error(error.message || t("server_issue"));
      });
    }
    dispatch(fetchCurrencies());
    if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
      dispatch(getAllDepartment()).catch((error) => {
        console.error("Error occurred:", error);
        toast.error(error.message || t("server_issue"));
      });
    }
    if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
      dispatch(getAllTagByGroup()).catch((error) => {
        console.error("Error occurred:", error);
        toast.error(error.message || t("server_issue"));
      });
    }
    let queryParams = `?page=${pageNo}&`;

    if (
      state?.prevPath &&
      state?.prevPath.includes("/expenses/expense-details")
    ) {
      const filterData = filters;
      if (filterData?.selectCategoryId > 0) {
        setFilterValue("selectCategoryId", filterData?.selectCategoryId);
        const selectedValues = filterData?.selectCategoryId?.map(
          (option) => option.value
        );
        const result = selectedValues.join(",");
        queryParams += `selectCategoryId=${result}&`;
      }

      if (filterData?.tagId?.length > 0) {
        setFilterValue("tagId", filterData?.tagId);
        const selectedTagValues = filterData?.tagId?.map(
          (option) => option.value
        );
        const result = selectedTagValues.join(",");
        queryParams += `tagId=${result}&`;
      }
      if (filterData?.startDate && filterData?.endDate) {
        setFilterValue("startDate", filterData?.startDate);
        setFilterValue("endDate", filterData?.endDate);
        queryParams += `startDate=${filterData?.startDate}&endDate=${filterData?.endDate}&`;
      }

      const fromAmount = filterData?.fromAmount
        ? cleanAmount(filterData.fromAmount)
        : "";
      const toAmount = filterData?.toAmount
        ? cleanAmount(filterData.toAmount)
        : "";

      if (fromAmount && toAmount) {
        setFilterValue("fromAmount", filterData?.fromAmount);
        setFilterValue("toAmount", filterData?.toAmount);
        queryParams += `fromAmount=${fromAmount}&toAmount=${toAmount}&`;
      }

      if (filterData?.receiptUploaded !== undefined) {
        setFilterValue("receiptUploaded", filterData?.receiptUploaded);
        queryParams += `receiptUploaded=${filterData?.receiptUploaded}&`;
      }

      if (filterData?.categorySelected !== undefined) {
        setFilterValue("categorySelected", filterData?.categorySelected);
        queryParams += `categorySelected=${filterData?.categorySelected}&`;
      }

      if (filterData?.transactionStatus !== undefined) {
        setFilterValue("transactionStatus", filterData?.transactionStatus);
        queryParams += `transactionStatus=${filterData?.transactionStatus}&`;
      }

      if (filterData?.exported !== undefined) {
        setFilterValue("exported", filterData?.exported);
        queryParams += `exported=${filterData?.exported}&`;
      }

      if (filterData?.departmentId !== undefined) {
        setFilterValue("departmentId", filterData?.departmentId);
        queryParams += `departmentId=${filterData?.departmentId}&`;
      }

      queryParams = queryParams.slice(0, -1);
    }
    if (activeTab === "1") {
      dispatch(getAllExpenseListByCompany(queryParams))
        .unwrap()
        .then((res) => {
          if (pseudoCheckedAll) {
            if (res?.data?.rows?.length > 0) {
              const selected = [...selectedItems];
              for (const row of res?.data?.rows) {
                for (const data of row?.records) {
                  if (
                    !uncheckedTransaction?.includes(data?.cardTransactionId) &&
                    !selected.some(
                      (element) =>
                        element?.transactionId == data?.cardTransactionId
                    )
                  )
                    selected.push({
                      transactionId: data?.cardTransactionId,
                      transactionType: data?.type
                        ? data?.type
                        : "cardTransaction",
                    });
                }
              }
              setSelectedItems(selected);
            }
          }
        }).catch((error) => {
          console.error("Error occurred:", error);
          toast.error(error.message || t("server_issue"));
        });
    } else if (activeTab === "2") {
      if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
        const finalFilterType = filterType || 'all';
        dispatch(
          getEmployeeExpenseList(`?page=${pageNo}&filterType=${finalFilterType}`)
        ).unwrap()
          .then((res) => {
            if (pseudoCheckedAll) {
              if (res?.data?.rows?.length > 0) {
                const selected = [...selectedItems];
                for (const row of res?.data?.rows) {
                  for (const data of row?.records) {
                    if (
                      !uncheckedTransaction?.includes(data?.cardTransactionId) &&
                      !selected.some(
                        (element) =>
                          element?.transactionId == data?.cardTransactionId
                      )
                    )
                      selected.push({
                        transactionId: data?.cardTransactionId,
                        transactionType: data?.type
                          ? data?.type
                          : "cardTransaction",
                      });
                  }
                }
                setSelectedItems(selected);
              }
            }
          }).catch((error) => {
            console.error("Error occurred:", error);
            toast.error(error.message || t("server_issue"));
          });
      }
    }
  }, []);

  const {
    control: controlFilterForm,
    handleSubmit: handleFilterSubmit,
    watch: controlFormWatch,
    setValue: setFilterValue,
    formState: { errors: errorsFilterForm },
    reset: resetFilterForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        startDate: yup.string().optional(),
        endDate: yup
          .string()
          .optional()
          .test(
            "is-valid-end-date",
            "End date should not be before start date",
            function (value) {
              const startDate = this.resolve(yup.ref("startDate"));
              return !startDate || !value || value >= startDate;
            }
          ),
        fromAmount: yup
          .string()
          .optional()
          .matches(/^\d*\.?\d*$/, "Please enter valid amount"),
        toAmount: yup
          .string()
          .optional()
          .matches(/^\d*\.?\d*$/, "Please enter valid amount")
          .test(
            "is-greater-than-from",
            "To amount must be greater than from amount",
            function (value) {
              const fromAmount = this.resolve(yup.ref("fromAmount"));
              return (
                !fromAmount ||
                !value ||
                parseFloat(value) > parseFloat(fromAmount)
              );
            }
          ),
        receiptUploaded: yup.string().optional(),
        categorySelected: yup.string().optional(),
        export: yup.string().optional(),
        transactionStatus: yup.string().optional(),
        selectCategoryId: yup.array().of(
          yup.object().shape({
            label: yup.string().optional(),
            value: yup.string().optional(),
          })
        ),
        departmentId: yup.string().optional(),
        tagId: yup.array().of(
          yup.object().shape({
            label: yup.string().optional(),
            value: yup.string().optional(),
          })
        ),
      })
    ),
  });

  useDidUpdateEffect(() => {
    let queryParams = `?page=${pageNo}&`;
    const filterData = controlFormWatch();
    if (filterData?.selectCategoryId > 0) {
      const selectedValues = filterData?.selectCategoryId?.map(
        (option) => option.value
      );
      const result = selectedValues.join(",");
      queryParams += `selectCategoryId=${result}&`;
    }

    if (filterData?.tagId?.length > 0) {
      const selectedTagValues = filterData?.tagId?.map(
        (option) => option.value
      );
      const result = selectedTagValues.join(",");
      queryParams += `tagId=${result}&`;
    }
    if (filterData?.startDate && filterData?.endDate) {
      queryParams += `startDate=${filterData?.startDate}&endDate=${filterData?.endDate}&`;
    }

    const fromAmount = filterData?.fromAmount
      ? cleanAmount(filterData.fromAmount)
      : "";
    const toAmount = filterData?.toAmount
      ? cleanAmount(filterData.toAmount)
      : "";

    if (fromAmount && toAmount) {
      queryParams += `fromAmount=${fromAmount}&toAmount=${toAmount}&`;
    }

    if (filterData?.receiptUploaded !== undefined) {
      queryParams += `receiptUploaded=${filterData?.receiptUploaded}&`;
    }

    if (filterData?.categorySelected !== undefined) {
      queryParams += `categorySelected=${filterData?.categorySelected}&`;
    }

    if (filterData?.transactionStatus !== undefined) {
      queryParams += `transactionStatus=${filterData?.transactionStatus}&`;
    }

    if (filterData?.exported !== undefined) {
      queryParams += `exported=${filterData?.exported}&`;
    }

    if (filterData?.departmentId !== undefined) {
      queryParams += `departmentId=${filterData?.departmentId}&`;
    }

    if (activeTab === "1") {
      dispatch(getAllExpenseListByCompany(queryParams))
        .unwrap()
        .then((res) => {
          if (pseudoCheckedAll) {
            if (res?.data?.rows?.length > 0) {
              const selected = [...selectedItems];
              for (const row of res?.data?.rows) {
                for (const data of row?.records) {
                  if (
                    !uncheckedTransaction?.includes(data?.cardTransactionId) &&
                    !selected.some(
                      (element) =>
                        element?.transactionId == data?.cardTransactionId
                    )
                  )
                    selected.push({
                      transactionId: data?.cardTransactionId,
                      transactionType: data?.type
                        ? data?.type
                        : "cardTransaction",
                    });
                }
              }
              setSelectedItems(selected);
            }
          }
        }).catch((error) => {
          console.error("Error occurred:", error);
          toast.error(error.message || t("server_issue"));
        });
    } else if (activeTab === "2") {
      if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
        dispatch(
          getEmployeeExpenseList(`?page=${pageNo}&filterType=${filterType}`)
        ).unwrap()
          .then((res) => {
            if (pseudoCheckedAll) {
              if (res?.data?.rows?.length > 0) {
                const selected = [...selectedItems];
                for (const row of res?.data?.rows) {
                  for (const data of row?.records) {
                    if (
                      !uncheckedTransaction?.includes(data?.cardTransactionId) &&
                      !selected.some(
                        (element) =>
                          element?.transactionId == data?.cardTransactionId
                      )
                    )
                      selected.push({
                        transactionId: data?.cardTransactionId,
                        transactionType: data?.type
                          ? data?.type
                          : "cardTransaction",
                      });
                  }
                }
                setSelectedItems(selected);
              }
            }
          }).catch((error) => {
            console.error("Error occurred:", error);
            toast.error(error.message || t("server_issue"));
          });
      }
    } else {
      dispatch(
        getExpensesForApproval(`?page=${pageNo}&filterType=${filterType}`)
      )
        .unwrap()
        .then((res) => {
          if (pseudoCheckedAll) {
            if (res?.data?.rows?.length > 0) {
              const selected = [...selectedItems];
              for (const row of res?.data?.rows) {
                for (const data of row?.records) {
                  if (
                    !uncheckedTransaction?.includes(data?.cardTransactionId) &&
                    !selected.some(
                      (element) =>
                        element?.transactionId == data?.cardTransactionId
                    )
                  )
                    selected.push({
                      transactionId: data?.cardTransactionId,
                      transactionType: data?.type
                        ? data?.type
                        : "cardTransaction",
                    });
                }
              }
              setSelectedItems(selected);
            }
          }
        }).catch((error) => {
          console.error("Error occurred:", error);
          toast.error(error.message || t("server_issue"));
        });
    }
  }, [pageNo]);

  const filterValues = controlFormWatch();

  const filterSelected = useMemo(() => {
    return !!(
      filterValues?.categorySelected ||
      filterValues?.departmentId ||
      filterValues?.endDate ||
      filterValues?.exported ||
      filterValues?.fromAmount ||
      filterValues?.receiptUploaded ||
      (filterValues?.selectCategoryId &&
        filterValues?.selectCategoryId?.length > 0) ||
      filterValues?.startDate ||
      (filterValues?.tagId && filterValues?.tagId?.length > 0) ||
      filterValues?.toAmount ||
      filterValues?.transactionStatus
    );
  }, [filterValues]);

  const changeTab = (eventKey) => {
    if (eventKey === "1") {
      setActiveTab("1");
      setFilterType("");
      setPageNo(1);
      setSelectedItems([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
    if (eventKey === "2") {
      setFilterType("all");
      let queryParams = `?filterType=all&`;
      if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
        dispatch(getEmployeeExpenseList(queryParams)).catch((error) => {
          console.error("Error occurred:", error);
          toast.error(error.message || t("server_issue"));
        });
      }
      setActiveTab('2');
      setPageNo(1);
      setSelectedItems([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
    if (eventKey === "3") {
      setFilterType("pending");
      let queryParams = `?filterType=pending`;
      dispatch(getExpensesForApproval(queryParams)).catch((error) => {
        console.error("Error occurred:", error);
        toast.error(error.message || t("server_issue"));
      });
      setActiveTab("3");
      setPageNo(1);
      setSelectedItems([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
  };

  const changeTabOnTabClick = (eventKey, filterPkey) => {
    setFilterType(filterPkey);
    if (eventKey === "2") {
      let queryParams = `?filterType=${filterPkey}`;
      if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
        dispatch(getEmployeeExpenseList(queryParams)).catch((error) => {
          console.error("Error occurred:", error);
          toast.error(error.message || t("server_issue"));
        });
      }
      setSelectedItems([]);
      setChekedAllPages(false);
      setPageNo(1);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
    if (eventKey === "3") {
      let queryParams = `?filterType=${filterPkey}`;
      dispatch(getExpensesForApproval(queryParams)).catch((error) => {
        console.error("Error occurred:", error);
        toast.error(error.message || t("server_issue"));
      });
      setSelectedItems([]);
      setChekedAllPages(false);
      setPageNo(1);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
    }
  };

  useDidUpdateEffect(() => {
    const timer = setTimeout(() => {
      if (searchQuery === "") {
        searchListByEmployee();
      } else {
        searchListByEmployee();
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery]);

  const searchListByEmployee = () => {
    const requestData = controlFormWatch();
    let queryParams = "?page=1&";
    setPageNo(1);
    if (requestData?.selectCategoryId?.length > 0) {
      const selectedValues = requestData.selectCategoryId?.map(
        (option) => option.value
      );
      const result = selectedValues.join(",");
      queryParams += `selectCategoryId=${result}&`;
    }

    if (requestData?.tagId?.length > 0) {
      const selectedTagValues = requestData.tagId?.map(
        (option) => option.value
      );
      const result = selectedTagValues.join(",");
      queryParams += `tagId=${result}&`;
    }
    if (requestData?.startDate && requestData?.endDate) {
      queryParams += `startDate=${requestData.startDate}&endDate=${requestData.endDate}&`;
    }

    const fromAmount = requestData?.fromAmount
      ? cleanAmount(requestData.fromAmount)
      : "";
    const toAmount = requestData?.toAmount
      ? cleanAmount(requestData.toAmount)
      : "";

    if (fromAmount && toAmount) {
      queryParams += `fromAmount=${fromAmount}&toAmount=${toAmount}&`;
    }

    if (requestData?.receiptUploaded !== undefined) {
      queryParams += `receiptUploaded=${requestData.receiptUploaded}&`;
    }

    if (requestData?.categorySelected !== undefined) {
      queryParams += `categorySelected=${requestData.categorySelected}&`;
    }

    if (requestData?.transactionStatus !== undefined) {
      queryParams += `transactionStatus=${requestData.transactionStatus}&`;
    }

    if (requestData?.exported !== undefined) {
      queryParams += `exported=${requestData.exported}&`;
    }

    if (requestData?.departmentId !== undefined) {
      queryParams += `departmentId=${requestData.departmentId}&`;
    }

    if (searchQuery && searchQuery !== "") {
      queryParams += `search=${searchQuery}&`;
    }

    queryParams = queryParams.slice(0, -1);

    dispatch(getAllExpenseListByCompany(queryParams))
      .unwrap()
      .then(() => {
        handleCloseModalFilter();
      })
      .catch((error) => {
        toast.error(error.message || t("server_issue"));
      });
  };
  const {
    control: controlTopUpForm,
    handleSubmit: handleAddTopUpSubmit,
    formState: { errors: errorsTopUpForm },
    reset: resetTopUpForm,
    watch,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        employeeId: yup.number().required(),
        cardId: yup.number().required(),
        amount: yup.number().required(),
        comments: yup.string().required(),
      })
    ),
  });
  const topUpCardValues = watch();
  const resetTopUpFormFunction = () => {
    resetTopUpForm();
  };

  const {
    control: controlVerifyModal,
    handleSubmit: handleOtpSubmit,
    formState: { errors: errorsOtpForm },
    reset: otpFormReset,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        otp: yup
          .string()
          .required("Otp is required")
          .matches(/^123456$/, "OTP must be exactly 6 digits"),
      })
    ),
  });
  const {
    control: controlSimulateForm,
    handleSubmit: handleSimulateSubmit,
    formState: { errors: errorsSimulateForm },
    reset: resetSimulateForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        card_number: yup
          .string()
          .required("Card Number is required")
          .matches(/^\d{16}$/, "Card Number must be 16 digits"),
        expire: yup
          .string()
          .required("Expire is required")
          .matches(/^\d{4}$/, "Expire must be 4 digits"),
        cvv: yup
          .string()
          .required("Cvv is required")
          .matches(/^\d{3}$/, "Cvv must be 3 digits"),
        pin: yup
          .string()
          .required("Pin is required")
          .matches(/^\d{4}$/, "Pin must be 4 digits"),
      })
    ),
  });

  useEffect(() => {
    if (topUpCardValues?.employeeId) {
      dispatch(getCardByemployeeId(topUpCardValues?.employeeId))
        .unwrap()
        .then((data) => {
          setEmployeeCards(data?.data);
        })
        .catch((error) => {
          toast.error(error.message || t("server_issue"));
          setEmployeeCards([]);
        });
    }
  }, [topUpCardValues?.employeeId]);

  const onTopUpFormSubmit = () => {
    delete topUpDataData.employeeId;
    topUpDataData.eventType = 'expenses';
    dispatch(addCardMoney(topUpDataData)).unwrap().then(() => {
      handleCloseModalTwo();
      let queryParams = `?filterType=company&`;
      if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
        dispatch(getAvaliableBalance(queryParams)).catch((error) => {
          console.error("Error occurred:", error);
          toast.error(error.message || t("server_issue"));
        });
      }
      toast.success('Card Topup Added Successfully!');
      resetTopUpFormFunction();
      otpFormReset({ otp: '' });
      handleCloseVerifyModal();
    }).catch((error) => {
      toast.error(error.message || t("server_issue"));
    });
  };

  const approveRejectRequest = (data) => {
    if (cardTransactionId) {
      let requestData = {
        approveStatus: data.type,
        cardTransactionId,
      };

      if (typeof data.comments !== "undefined") {
        requestData.comments = data.comments;
      }

      dispatch(approveRejectExpense(requestData))
        .unwrap()
        .then(() => {
          let queryParams = `?filterType=${filterType}`;
          dispatch(getExpensesForApproval(queryParams));
          toast.success("Status Changed successfully");
          resetRejectForm();
          handleClose();
          handleCloseModalThree();
        })
        .catch((error) => {
          toast.error(error.message || t("server_issue"));
        });
    }
  };

  const updateTransaction = (requestData, imageUpload) => {
    if (
      cardTransactionId &&
      requestData &&
      requestData.categoryId !== "" &&
      !imageUpload
    ) {
      requestData.cardTransactionId = cardTransactionId;
      dispatch(updateCardTransaction(requestData)).unwrap().then(() => {
        toast.success('Data updated successfully');
        if (cardTransactionId) {
          dispatch(getExpenseDetails(`?cardTransactionId=${cardTransactionId}`)).catch((error) => {
            console.error("Error occurred:", error);
            toast.error(error.message || t("server_issue"));
          });
          if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
            dispatch(getAllTagByGroup()).catch((error) => {
              console.error("Error occurred:", error);
              toast.error(error.message || t("server_issue"));
            });
          }
        }
        if (activeTab === '1') {
          dispatch(getAllExpenseListByCompany('')).catch((error) => {
            console.error("Error occurred:", error);
            toast.error(error.message || t("server_issue"));
          });
        }
        if (activeTab === '2') {
          let queryParams = `?filterType=${filterType}&`;
          if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
            dispatch(getEmployeeExpenseList(queryParams)).catch((error) => {
              console.error("Error occurred:", error);
              toast.error(error.message || t("server_issue"));
            });
          }
        }
        if (activeTab === '3') {
          let queryParams = `?filterType=${filterType}`;
          dispatch(getExpensesForApproval(queryParams)).catch((error) => {
            console.error("Error occurred:", error);
            toast.error(error.message || t("server_issue"));
          });
        }
      }).catch((error) => {
        toast.error(error.message || t("server_issue"));
      });
    }
    if (cardTransactionId && requestData && (imageUpload && imageUpload === 'imageUpload')) {
      dispatch(uploadTransactionReceipt({ requestData, cardTransactionId })).unwrap().then(() => {
        if (cardTransactionId) {
          dispatch(getExpenseDetails(`?cardTransactionId=${cardTransactionId}`)).catch((error) => {
            console.error("Error occurred:", error);
            toast.error(error.message || t("server_issue"));
          });
          if (verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)) {
            dispatch(getAllTagByGroup()).catch((error) => {
              console.error("Error occurred:", error);
              toast.error(error.message || t("server_issue"));
            });
          }
        }
        toast.success('Data updated successfully');
        if (activeTab === '1') {
          dispatch(getAllExpenseListByCompany('')).catch((error) => {
            console.error("Error occurred:", error);
            toast.error(error.message || t("server_issue"));
          });
        }
        if (activeTab === '2') {
          let queryParams = `?filterType=${filterType}`;
          if (verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAccountantReviewerAndCardHolder)) {
            dispatch(getEmployeeExpenseList(queryParams)).catch((error) => {
              console.error("Error occurred:", error);
              toast.error(error.message || t("server_issue"));
            });
          }
        }
        if (activeTab === '3') {
          let queryParams = `?filterType=${filterType}`;
          dispatch(getExpensesForApproval(queryParams)).catch((error) => {
            console.error("Error occurred:", error);
            toast.error(error.message || t("server_issue"));
          });
        }
      }).catch((error) => {
        toast.error(error.message || t("server_issue"));
      });
    }
  };

  const addExpenseData = (data) => {
    const expenseData = {
      merchantName: data?.merchantName,
      amount: Number(data?.amount),
      userEmployeeId: Number(data?.employeeId),
      cardId: Number(data?.cardId),
      currencyAmount: Number(data?.selectedCurrencyAmount),
      currencyId: Number(data?.currencyId.value),
    };
    dispatch(addExpenseTransaction(expenseData))
      .unwrap()
      .then(() => {
        resetFilterForm();
        dispatch(getAllExpenseListByCompany("")).catch((error) => {
          console.error("Error occurred:", error);
          toast.error(error.message || t("server_issue"));
        });
        toast.success("Expense Added Successfully!");
        resetTransactionForm({
          merchantName: "",
          amount: "",
          employeeId: "",
          cardId: "",
          currencyId: {
            value: "",
            label: `${t("expenses.Select_currency")}`,
            unit: 0,
          },
          selectedCurrencyAmount: "",
        });
        handleCloseModalFour();
      })
      .catch((error) => {
        toast.error(error.message || t("server_issue"));
      });
  };
  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  const onPreviousClick = () => {
    setPageNo((prev) => prev - 1);
  };

  const onNextClick = () => {
    setPageNo((prev) => prev + 1);
  };

  const handleCheckboxChange = (id, type) => {
    const selected = [...selectedItems];
    const unchecked = [...uncheckedTransaction];
    const index = selected.findIndex(
      (item) => item.transactionId == id && item.transactionType == type
    );
    if (index === -1) {
      if (pseudoCheckedAll) {
        const uncheckedIndex = unchecked.findIndex((trans) => trans == id);
        unchecked.splice(uncheckedIndex, 1);
        setUncheckedTransaction(unchecked);
      }
      selected.push({
        transactionId: id,
        transactionType: type ? type : "cardTransaction",
      });
    } else {
      if (pseudoCheckedAll) {
        unchecked.push(id);
        setUncheckedTransaction(unchecked);
      }
      selected.splice(index, 1);
    }
    if (checkedAllPages) setChekedAllPages(false);
    setSelectedItems(selected);
  };

  const handleCheckAllboxChange = () => {
    if (checkedAllPages) {
      setSelectedItems([]);
      setUncheckedTransaction([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
    } else {
      let allRecords = [];
      const mapData = activeTab === "1" ? companyAllExpenses : expenseList;
      mapData?.rows?.map((element) =>
        element?.records?.map((record) =>
          allRecords.push({
            transactionId: record?.cardTransactionId,
            transactionType: record?.type,
          })
        )
      );
      setUncheckedTransaction([]);
      setSelectedItems(allRecords);
      setChekedAllPages(true);
      setPseudoCheckedAll(true);
    }
  };

  const exportRecords = async (type = "csv", selectedRecords = []) => {
    try {
      let reponse = null;
      if (pseudoCheckedAll) {
        const mainType =
          activeTab === "1"
            ? "allTransaction"
            : activeTab === "2"
              ? "myTransaction"
              : "forReview";

        let queryParams = `?mainType=${mainType}&`;

        const filterData = controlFormWatch();

        if (filterType) {
          queryParams += `filterType=${filterType}&`;
        }
        if (filterData?.selectCategoryId > 0) {
          const selectedValues = filterData?.selectCategoryId?.map(
            (option) => option.value
          );
          const result = selectedValues.join(",");
          queryParams += `selectCategoryId=${result}&`;
        }

        if (filterData?.tagId?.length > 0) {
          const selectedTagValues = filterData?.tagId?.map(
            (option) => option.value
          );
          const result = selectedTagValues.join(",");
          queryParams += `tagId=${result}&`;
        }
        if (filterData?.startDate && filterData?.endDate) {
          queryParams += `startDate=${filterData?.startDate}&endDate=${filterData?.endDate}&`;
        }

        const fromAmount = filterData?.fromAmount
          ? cleanAmount(filterData.fromAmount)
          : "";
        const toAmount = filterData?.toAmount
          ? cleanAmount(filterData.toAmount)
          : "";

        if (fromAmount && toAmount) {
          queryParams += `fromAmount=${fromAmount}&toAmount=${toAmount}&`;
        }

        if (filterData?.receiptUploaded !== undefined) {
          queryParams += `receiptUploaded=${filterData?.receiptUploaded}&`;
        }

        if (filterData?.categorySelected !== undefined) {
          queryParams += `categorySelected=${filterData?.categorySelected}&`;
        }

        if (filterData?.transactionStatus !== undefined) {
          queryParams += `transactionStatus=${filterData?.transactionStatus}&`;
        }

        if (filterData?.exported !== undefined) {
          queryParams += `exported=${filterData?.exported}&`;
        }

        if (filterData?.departmentId !== undefined) {
          queryParams += `departmentId=${filterData?.departmentId}&`;
        }

        queryParams += `unchecked=${JSON.stringify(uncheckedTransaction)}&`;

        queryParams = queryParams.slice(0, -1);
        reponse = await dispatch(exportAllExpenses(queryParams)).unwrap();
      } else {
        const postData = { type, selectedRecords, eventType: "expenses" };
        reponse = await dispatch(exportTransactionRecords(postData)).unwrap().catch((error) => {
          console.error("Error occurred:", error);
          toast.error(error.message || t("server_issue"));
        });
      }
      let blob = "";
      if (type == "csv") {
        blob = new Blob([reponse.data], {
          type: reponse.headers["content-type"],
        });
      }
      if (type == "json") {
        blob = await new Blob([JSON.stringify(reponse.data)], {
          type: reponse.headers["content-type"],
        });
      }
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      const contentDisposition = reponse.headers["Content-disposition"];
      const match =
        contentDisposition && contentDisposition.match(/filename=(.*)/);
      const filename = match ? match[1] : "exported";
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setSelectedItems([]);
      setChekedAllPages(false);
      setPseudoCheckedAll(false);
      setUncheckedTransaction([]);
      toast.success("Record Exported Successfully!");
    } catch (error) {
      toast.error(t("server_issue") || error.message);
    }
  };

  const handleResetFilterForm = () => {
    resetFilterForm({
      startDate: "",
      endDate: "",
      fromAmount: "",
      toAmount: "",
      receiptUploaded: "",
      categorySelected: "",
      export: "",
      transactionStatus: "",
      selectCategoryId: yup.array().of(
        yup.object().shape({
          label: "Select Options",
          value: "",
        })
      ),
      departmentId: "",
      tagId: yup.array().of(
        yup.object().shape({
          label: "Select Options",
          value: "",
        })
      ),
    });
    dispatch(getAllExpenseListByCompany("?page=1"))
      .unwrap()
      .then(() => {
        handleCloseModalFilter();
      })
      .catch((error) => {
        toast.error(error.message || t("server_issue"));
      });
  };

  const onFilterFormSubmit = (requestData) => {
    console.log(requestData);
    dispatch(handleExpenseFilters(requestData)).catch((error) => {
      console.error("Error occurred:", error);
      toast.error(error.message || t("server_issue"));
    });
    let queryParams = "?page=1";
    setPageNo(1);
    if (requestData?.selectCategoryId?.length > 0) {
      const selectedValues = requestData.selectCategoryId?.map(
        (option) => option.value
      );
      const result = selectedValues.join(",");
      queryParams += `&selectCategoryId=${result}`;
    }

    if (requestData?.tagId?.length > 0) {
      const selectedTagValues = requestData.tagId?.map(
        (option) => option.value
      );
      const result = selectedTagValues.join(",");
      queryParams += `&tagId=${result}`;
    }

    if (requestData?.startDate) {
      const startDate = createStartDateWithTimezone(requestData.startDate);
      queryParams += `&startDate=${startDate}`;
    }

    if (requestData?.endDate) {
      const endDate = createEndDateWithTimezone(requestData.endDate);
      queryParams += `&endDate=${endDate}`;
    }

    const fromAmount = requestData?.fromAmount
      ? cleanAmount(requestData.fromAmount)
      : "";
    const toAmount = requestData?.toAmount
      ? cleanAmount(requestData.toAmount)
      : "";

    if (fromAmount) {
      queryParams += `&fromAmount=${fromAmount}`;
    }

    if (toAmount) {
      queryParams += `&toAmount=${toAmount}`;
    }

    if (requestData?.receiptUploaded !== undefined) {
      queryParams += `&receiptUploaded=${requestData.receiptUploaded}`;
    }

    if (requestData?.categorySelected !== undefined) {
      queryParams += `&categorySelected=${requestData.categorySelected}`;
    }

    if (requestData?.transactionStatus !== undefined) {
      queryParams += `&transactionStatus=${requestData.transactionStatus}`;
    }

    if (requestData?.exported !== undefined) {
      queryParams += `&exported=${requestData.exported}`;
    }

    if (requestData?.departmentId !== undefined) {
      queryParams += `&departmentId=${requestData.departmentId}`;
    }

    if (searchQuery && searchQuery !== "") {
      queryParams += `&search=${searchQuery}`;
    }

    dispatch(getAllExpenseListByCompany(queryParams))
      .unwrap()
      .then(() => {
        handleCloseModalFilter();
      })
      .catch((error) => {
        toast.error(error.message || t("server_issue"));
      });
  };

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const onClickVerifyModal = (data) => {
    setTimer(60);
    setTopUpDataData(data);
    handleCloseModalTwo();
    otpFormReset({ otp: "" });
    setTimeout(() => {
      handleShowVerifyModal();
    }, 200);
  };

  const requiredFieldLabel = (labelText, htmlfor) => {
    return (
      <label className="form-label" htmlFor={htmlfor}>
        {labelText}
        <span
          className="absolute top-0 left-0"
          style={{ transform: "translate(-50%, -50%)", color: "#FF0000" }}
        >
          {" "}
          *
        </span>
      </label>
    );
  };

  const selectOptions = useMemo(() => {
    return currencies?.map((res) => ({
      value: res.currencyId,
      label: res.currency_name,
      unit: res.unit,
    }));
  }, [currencies]);

  return (
    <div
      className={`${currentLanguageCode === "en"
        ? "white-container expensesPageCss"
        : "white-container-arabic expensesPageCss"
        }`}
    >
      <h1 className="text-green mb-3">
        {t("sidebar.xpendless_expenses_title")}
      </h1>
      {hasAccess && (
        <div
          className="col-12 cards-custom border-grey mt-4 p-3 pb-4"
          style={{ position: "relative" }}
        >
          <div className="d-flex align-items-center justify-content-between green-header px-2">
            <div className="d-flex align-items-center">
              <img
                src={AccountsIcon}
                style={{ width: 24, objectFit: "contain" }}
              />
              <h2 className="text-green px-1">
                {t("expenses.account_balance")}
              </h2>
            </div>
          </div>
          <div className="accountBalanceCardCss">
            {isBalanceLoading && <LoadingComponent />}
            <div className="d-flex align-items-end ms-2">
              <h1 className="box-1" style={{ fontSize: 36, color: "#00D094" }}>
                {wholePart.toLocaleString()}.
                <span
                  className="text-grey"
                  style={{ fontSize: 16, fontWeight: "600" }}
                >{`${decimalPart ? decimalPart : "00"} QAR`}</span>
              </h1>
            </div>
            <div>
              <PrimaryButton
                type={'button'}
                onClick={() => {
                  handleShowTwo();
                  resetTopUpForm();
                }}
                buttonText={t("expenses.top_up_card")}
                isEnabled={true}
                id={'add_topup_expenses'}
                style={{ marginLeft: 10, zIndex: 2, position: "relative" }}
              />
            </div>
          </div>
          <img
            src={lineBackground}
            height={80}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              zIndex: 1,
              width: "100%",
            }}
          />
        </div>
      )}

      <div
        className="settings setting-font mt-4"
        style={{ position: "relative", zIndex: 1 }}
      >
        <Stack
          className={`${currentLanguageCode === "en" ? "add-expenses-en" : "add-expenses-ab"}`}
          direction={'row'}
          spacing={2}
        >
          <PrimaryButton
            type={'button'}
            onClick={() => setIsModalVisible(true)}
            buttonText={t("expenses.simulate_transaction")}
            isEnabled={true}
            id={'show_add_expenses'}
            style={{ width: '200px' }}
          />

          <IconButton
            type={'button'}
            onClick={handleShowFour}
            buttonText={t("expenses.add_expenses")}
            id={'show_add_expenses'}
            src={addBtn}
            iconStyle={{ width: 14, marginRight: 6, marginBottom: 3, marginLeft: 6 }}
            btnStyle={{ width: '170px' }}
            currentLanguageCode={currentLanguageCode}
            isFilled={true}
          />
        </Stack>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          animation="true"
          id="hasAccess"
          onSelect={(eventKey) => changeTab(eventKey)}
          style={{ fontSize: 17 }}
        >
          {(decodedToken?.isAdmin || decodedToken?.isAccountant) && (
            <Tab
              eventKey={1}
              title={`${t("expenses.all")}`}
              className="expenses-tab-pad"
            >
              <div
                className=" cards-custom p-3 mt-4"
                style={{ position: "relative" }}
              >
                {isLoading && <LoadingComponent />}
                <div
                  className={
                    window.innerWidth > 500
                      ? "d-flex align-items-center justify-content-between"
                      : ""
                  }
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="expensesCheckAll"
                      checked={checkedAllPages}
                      onChange={handleCheckAllboxChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="expensesCheckAll"
                    >
                      {t("export.select_all")}
                    </label>
                  </div>
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="col-lg-4 col-md-12">
                        <div className="form-group has-search">
                          <span className="fa fa-search form-control-feedback mt-1"></span>
                          <input
                            style={{ height: 48 }}
                            type="text"
                            id="searchEmployee"
                            className="form-control"
                            placeholder={`${t("search_employee")}`}
                            onKeyUp={(event) =>
                              setSearchQuery(event.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div className="position-relative">
                        {filterSelected && (
                          <div className="position-absolute green-icon"></div>
                        )}
                        <img
                          className="mx-4"
                          onClick={handleShowFilter}
                          src={
                            filterSelected ? selectedFilterButton : FilterButton
                          }
                          style={{
                            width: 48,
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                        />
                      </div>

                      <PrimaryButton
                        type={'button'}
                        onClick={() => exportRecords("csv", selectedItems)}
                        buttonText={t("export.export")}
                        isEnabled={selectedItems.length >= 1}
                        id={'export_all_expenses'}
                        isExportBtn={true}
                        isLoading={pseudoCheckedAll ? isExpenseExportLoading : isExportLoading}
                        style={{ width: '10%' }}
                      />
                    </div>
                  </div>
                </div>
                {companyAllExpenses &&
                  companyAllExpenses["rows"]?.length > 0 ? (
                  companyAllExpenses["rows"]?.map((item, i) => {
                    return (
                      <div
                        className="export-border table-border p-3 mt-3"
                        key={"listsData" + i}
                      >
                        <h2 className="text-grey">
                          {moment(item?.date).format("DD MMM YYYY")}
                        </h2>
                        <ExpensesComponent
                          index={i}
                          data={item?.records}
                          showExpenseDetailModal={handleShow}
                          parentTab={"1"}
                          currencies={currencies}
                          showCheckBox={true}
                          selectedItems={selectedItems}
                          handleCheckboxChange={handleCheckboxChange}
                          filePermission={filePermission}
                          prevPath={"/expenses"}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="d-flex align-items-center justify-content-center p-3 px-2">
                    <img src={notFound} width={200} height={200} />
                  </div>
                )}
                <div
                  className="d-flex align-items-center justify-content-between px-2"
                  style={{ width: "100%", borderTop: "0px !imporant" }}
                >
                  <nav aria-label="Page navigation example">
                    <ul className="pagination mt-2 pt-1 mt-3 pe-0">
                      <li className="page-item">
                        <SecondaryButton
                          type={'button'}
                          onClick={() => onPreviousClick()}
                          buttonText={t("previous")}
                          isEnabled={pageNo !== 1}
                          id={'prev_all_expenses'}
                        />
                      </li>
                      <li className="page-item mx-3">
                        <SecondaryButton
                          type={'button'}
                          onClick={() => onNextClick()}
                          buttonText={t("next")}
                          isEnabled={!(pageNo == companyAllExpenses?.totalPages ||
                            companyAllExpenses?.totalPages == 0)}
                          id={'next_all_expenses'}
                        />
                      </li>
                    </ul>
                  </nav>
                  <div
                    className="page-count d-flex align-items-center"
                    style={{ color: "#00D094" }}
                  >
                    <h4>
                      Page{" "}
                      {companyAllExpenses?.count == 0
                        ? 0
                        : companyAllExpenses?.currentPage}{" "}
                      of {companyAllExpenses?.totalPages}
                    </h4>
                  </div>
                </div>
              </div>
            </Tab>
          )}
          <Tab
            eventKey={2}
            title={`${t("expenses.my_transactions")}`}
            className="expenses-tab-pad"
          >
            <div className="inner-tabs mt-4" style={{ position: "relative" }}>
              {isLoading && <LoadingComponent />}
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="myTransactionsCheckAll"
                    checked={checkedAllPages}
                    onChange={handleCheckAllboxChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="myTransactionsCheckAll"
                  >
                    {t("export.select_all")}
                  </label>
                </div>
                <PrimaryButton
                  type={'button'}
                  onClick={() => exportRecords("csv", selectedItems)}
                  buttonText={t("export.export")}
                  isEnabled={selectedItems.length >= 1}
                  id={'export_mytrans_expenses'}
                  isExportBtn={true}
                  isLoading={pseudoCheckedAll ? isExpenseExportLoading : isExportLoading}
                  style={{ width: '10%' }}
                />

              </div>
              <MytrascationTabs
                listData={expenseList["rows"]}
                clickOnTabChange={changeTabOnTabClick}
                activeTab={activeTab}
                parentTab={"2"}
                currencies={currencies}
                showExpenseDetailModal={handleShow}
                selectedItems={selectedItems}
                handleCheckboxChange={handleCheckboxChange}
                filePermission={filePermission}
                prevPath={"/expenses"}
              />

              <div
                className="d-flex align-items-center justify-content-between px-2"
                style={{ width: "100%", borderTop: "0px !imporant" }}
              >
                <nav aria-label="Page navigation example">
                  <ul className="pagination mt-2 pt-1 mt-3 pe-0">
                    <li className="page-item">
                      <SecondaryButton
                        type={'button'}
                        onClick={() => onPreviousClick()}
                        buttonText={t("previous")}
                        isEnabled={pageNo !== 1}
                        id={'prev_mytrans_expenses'}
                      />
                    </li>
                    <li className="page-item mx-3">
                      <SecondaryButton
                        type={'button'}
                        onClick={() => onNextClick()}
                        buttonText={t("next")}
                        isEnabled={!(pageNo == expenseList?.totalPages ||
                          expenseList?.totalPages == 0)}
                        id={'next_mytrans_expenses'}
                      />
                    </li>
                  </ul>
                </nav>
                <div
                  className="page-count d-flex align-items-center"
                  style={{ color: "#00D094" }}
                >
                  <h4>
                    Page{" "}
                    {expenseList?.count == 0 ? 0 : expenseList?.currentPage} of{" "}
                    {expenseList?.totalPages}
                  </h4>
                </div>
              </div>
            </div>
          </Tab>
          {(decodedToken?.isAdmin ||
            decodedToken?.isAccountant ||
            decodedToken?.isGlobalReviewer ||
            decodedToken?.reviewer?.length > 0) && (
              <Tab
                eventKey={3}
                title={`${t("expenses.for_your_review")}`}
                className="expenses-tab-pad"
              >
                <div className="inner-tabs mt-4" style={{ position: "relative" }}>
                  {isLoading && <LoadingComponent />}
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="forReviewCheckAll"
                        checked={checkedAllPages}
                        onChange={handleCheckAllboxChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="forReviewCheckAll"
                      >
                        {t("export.select_all")}
                      </label>
                    </div>
                    <PrimaryButton
                      type={'button'}
                      onClick={() => exportRecords("csv", selectedItems)}
                      buttonText={t("export.export")}
                      isEnabled={selectedItems.length >= 1}
                      id={'export_forreview_expenses'}
                      isExportBtn={true}
                      isLoading={pseudoCheckedAll ? isExpenseExportLoading : isExportLoading}
                      style={{ width: '10%' }}
                    />
                  </div>
                  <ForYourReviewTabs
                    listData={expenseList["rows"]}
                    clickOnTabChange={changeTabOnTabClick}
                    activeTab={activeTab}
                    parentTab={"3"}
                    currencies={currencies}
                    showExpenseDetailModal={handleShow}
                    selectedItems={selectedItems}
                    handleCheckboxChange={handleCheckboxChange}
                    filePermission={filePermission}
                    showCheckBox={true}
                    prevPath={"/expenses"}
                  />
                  <div
                    className="d-flex align-items-center justify-content-between px-2"
                    style={{ width: "100%", borderTop: "0px !imporant" }}
                  >
                    <nav aria-label="Page navigation example">
                      <ul className="pagination mt-2 pt-1 mt-3 pe-0">
                        <li className="page-item">
                          <SecondaryButton
                            type={'button'}
                            onClick={() => onPreviousClick()}
                            buttonText={t("previous")}
                            isEnabled={pageNo !== 1}
                            id={'prev_forreview_expenses'}
                          />
                        </li>
                        <li className="page-item mx-3">
                          <SecondaryButton
                            type={'button'}
                            onClick={() => onNextClick()}
                            buttonText={t("next")}
                            isEnabled={!(pageNo == expenseList?.totalPages ||
                              expenseList?.totalPages == 0)}
                            id={'next_forreview_expenses'}
                          />
                        </li>
                      </ul>
                    </nav>
                    <div
                      className="page-count d-flex align-items-center"
                      style={{ color: "#00D094" }}
                    >
                      <h4>
                        Page{" "}
                        {expenseList?.count == 0 ? 0 : expenseList?.currentPage}{" "}
                        of {expenseList?.totalPages}
                      </h4>
                    </div>
                  </div>
                </div>
              </Tab>
            )}
        </Tabs>
        <Modal
          className="UserModal"
          show={show}
          onHide={handleClose}
          style={{ paddingRight: 0 }}
        >
          <Modal.Body>
            <div className="d-flex justify-content-between align-items-start">
              <div className="col-9">
                <p className="text-black modal-title" style={{ fontSize: 20 }}>
                  {t("expenses.expense_details")}
                </p>
                <p className="text-grey">
                  {t("expenses.expense_details_desc")}
                </p>
              </div>
              <div
                onClick={handleClose}
                className="clo-2"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={ImagesCross}
                  style={{ width: 20, objectFit: "contain" }}
                />
              </div>
            </div>
            {expenseDetails && !isExpenseDetailLoading && (
              <ExpensesModalTabs
                cardTransactionId={cardTransactionId}
                handleClose={handleClose}
                saveTags={saveTags}
                isLoading={isLoading}
                tagGroups={tags}
                details={expenseDetails}
                dispatch={dispatch}
                activeTab={activeTab}
                updateCardTransactionData={updateTransaction}
                categoryList={categoryList}
              />
            )}
          </Modal.Body>
          {activeTab &&
            activeTab === "3" &&
            expenseList &&
            expenseList.length > 0 && (
              <Modal.Footer>
                <SecondaryButton
                  type={'button'}
                  onClick={() => {
                    handleClose();
                    handleShowThree();
                  }}
                  buttonText={t("reject")}
                  isEnabled={true}
                  id={'reject_expenses'}
                />

                <PrimaryButton
                  type={'button'}
                  onClick={() => approveRejectRequest({ type: "approve" })}
                  buttonText={t("approve")}
                  isEnabled={true}
                  id={'approve_expenses'}
                  isLoading={isLoading}
                />
              </Modal.Footer>
            )}
        </Modal>
        <Modal
          className="TaxcodeModalOne"
          show={showTwo}
          onHide={handleCloseModalTwo}
          style={{ paddingRight: 0 }}
        >
          <form onSubmit={handleAddTopUpSubmit(onClickVerifyModal)}>
            <Modal.Body>
              {isLoading && cardReducer?.isLoading && <LoadingComponent />}
              <div className="d-flex justify-content-between align-items-start green-header p-3 py-2">
                <div className="col-9">
                  <p
                    className="text-green modal-title"
                    style={{ fontSize: 20 }}
                  >
                    {t("expenses.top_up")}
                  </p>
                </div>
                <div
                  onClick={() => {
                    handleCloseModalTwo();
                    resetTopUpForm();
                  }}
                  className="clo-2"
                  style={{ cursor: "pointer" }}
                  id="close_modal"
                >
                  <img
                    src={CrossImage}
                    style={{
                      width: 20,
                      objectFit: "contain",
                      marginTop: 5,
                      marginBottom: 3,
                    }}
                  />
                </div>
              </div>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t("expenses.select_employee"), "expensesSelectEmployee")}
                <Controller
                  name="employeeId"
                  control={controlTopUpForm}
                  render={({ field }) => (
                    <select
                      {...field}
                      id="expensesSelectEmployee"
                      className={`form-select form-control-lg ${currentLanguageCode === "en"
                        ? "form-control"
                        : "form-control-arabic"
                        } text-capitalize`}
                      defaultValue=""
                      style={{ color: field.value ? "black" : "grey" }}
                    >
                      <option value="">{t("expenses.select_employee")}</option>
                      {cardEmployees &&
                        cardEmployees?.length > 0 &&
                        cardEmployees?.map((option, i) => (
                          <option
                            className="text-capitalize"
                            value={option?.employeeId}
                            key={"employeeList" + i}
                          >
                            {option?.firstName + " " + option?.lastName}
                          </option>
                        ))}
                    </select>
                  )}
                />
                <p className="text-danger">
                  {errorTransaction?.employeeId?.message}
                </p>
              </div>
              <div className="form-outline mt-4 mb-3" style={{ position: 'relative' }}>
                {requiredFieldLabel(t('cards.card_number'), "selectCard")}
                <Controller
                  name="cardId"
                  control={controlTopUpForm}
                  render={({ field }) => (
                    <select
                      {...field}
                      id="selectCard"
                      className={`form-select form-control-lg ${currentLanguageCode === "en"
                        ? "form-control"
                        : "form-control-arabic"
                        }`}
                      defaultValue=""
                      style={{ color: field.value ? "black" : "grey" }}
                    >
                      <option value="">{"Select Card"}</option>
                      {employeeCardList &&
                        employeeCardList.length > 0 &&
                        employeeCardList?.map((option, i) => (
                          <option value={option?.cardId} key={"cardlist" + i}>
                            {option?.cardToken}
                          </option>
                        ))}
                    </select>
                  )}
                />
                <p className="text-danger">
                  {errorTransaction?.cardId?.message}
                </p>
              </div>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t("settingstab.Amount"), "amt")}
                <Controller
                  name="amount"
                  control={controlTopUpForm}
                  render={({ field }) => (
                    <CurrencyInput
                      type="text"
                      id="amt"
                      className="form-control form-control-lg"
                      placeholder={`${t("settingstab.Amount")}`}
                      value={field.value || ""}
                      decimalsLimit={2}
                      decimalScale={2}
                      disablenegative={"true"}
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onValueChange={(value) => {
                        const numericValue =
                          parseFloat(value?.replace(/,/g, "")) || 0;
                        if (numericValue < MAX_VALUE) {
                          field.onChange(
                            value === ""
                              ? ""
                              : value?.startsWith(".")
                                ? `0${value}`
                                : value
                          );
                        }
                      }}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsTopUpForm?.amount?.message}
                </p>
              </div>
              <div className="form-outline my-4">
                <label className="form-label" htmlFor="narrative">
                  {t("accounts.narrative")}
                </label>
                <Controller
                  name="comments"
                  control={controlTopUpForm}
                  render={({ field }) => (
                    <input
                      type="text"
                      {...field}
                      id="narrative"
                      className="form-control form-control-lg"
                      placeholder={`${t("accounts.narrative")}`}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsTopUpForm?.narrative?.message}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="px-0">
              <SecondaryButton
                type={'button'}
                onClick={() => {
                  handleCloseModalTwo();
                  resetTopUpForm();
                }}
                buttonText={t("cancel")}
                isEnabled={true}
                id={'cancel_topup_expenses'}
                style={{ marginTop: '8px', width: '96px' }}
              />

              <PrimaryButton
                type={'submit'}
                buttonText={t("save")}
                isEnabled={true}
                id={'submit_topup_expenses'}
                style={{ width: '149px' }}
              />
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          className="TaxcodeModalOne"
          show={showThree}
          onHide={handleCloseModalThree}
          style={{ paddingRight: 0 }}
        >
          <form onSubmit={handleRejectSubmit(approveRejectRequest)}>
            <Modal.Body>
              <div className="d-flex justify-content-between align-items-start">
                <div className="col-9">
                  <p
                    className="text-black modal-title"
                    style={{ fontSize: 20 }}
                  >
                    {t("reject")}
                  </p>
                  <p className="text-grey">{t("reject")}</p>
                </div>
                <div
                  onClick={handleCloseModalThree}
                  className="clo-2"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={ImagesCross}
                    style={{ width: 20, objectFit: "contain" }}
                  />
                </div>
              </div>
              <Controller
                name="type"
                control={controlRejectForm}
                defaultValue="reject"
                render={({ field }) => (
                  <input
                    {...field}
                    type="hidden"
                    id="rejectComments"
                    className="form-control form-control-lg"
                    placeholder={t("cards.comments")}
                  />
                )}
              />
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="comments">
                  {t("cards.comments")}
                </label>
                <Controller
                  name="comments"
                  control={controlRejectForm}
                  render={({ field }) => (
                    <input
                      type="text"
                      {...field}
                      id="comments"
                      className="form-control form-control-lg"
                      placeholder={`${t("cards.comments")}`}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsRejectForm?.comments?.message}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <SecondaryButton
                type={'button'}
                onClick={handleCloseModalThree}
                buttonText={t("cancel")}
                isEnabled={true}
                id={'cancel_reject_expenses'}
              />
              <PrimaryButton
                type={'submit'}
                buttonText={t("save")}
                isEnabled={true}
                id={'submit_reject_expenses'}
              />
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          className="UserModal"
          show={showFour}
          onHide={handleCloseModalFour}
          style={{ paddingRight: 0 }}
        >
          <form
            onSubmit={handleTransactionSubmit(addExpenseData)}
            onKeyDown={(e) => {
              console.log(e);
              if (e.key === "Enter") {
                e.preventDefault();
                handleTransactionSubmit(addExpenseData)();
              }
            }}
          >
            <Modal.Body>
              {isLoading && cardReducer?.isLoading && <LoadingComponent />}
              <div className="d-flex justify-content-between align-items-start green-header p-3 py-2">
                <div className="col-9">
                  <p
                    className="text-green modal-title"
                    style={{ fontSize: 20 }}
                  >
                    {t("expenses.add_expenses")}
                  </p>
                </div>
                <div
                  onClick={handleCloseModalFour}
                  className="clo-2"
                  style={{ cursor: "pointer" }}
                  id="close_modal"
                >
                  <img
                    src={CrossImage}
                    style={{
                      width: 20,
                      objectFit: "contain",
                      marginTop: 5,
                      marginBottom: 3,
                    }}
                  />
                </div>
              </div>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t("expenses.select_employee"), "selectEmployee")}
                <Controller
                  name="employeeId"
                  control={transactionControl}
                  render={({ field }) => (
                    <select
                      {...field}
                      id="selectEmployee"
                      className={`form-select form-control-lg ${currentLanguageCode === "en"
                        ? "form-control"
                        : "form-control-arabic"
                        }`}
                      defaultValue=""
                      style={{ color: field.value ? "black" : "grey" }}
                    >
                      <option value="">{t("expenses.select_employee")}</option>
                      {employees &&
                        employees.length > 0 &&
                        employees?.map((option, i) => (
                          <option
                            value={option?.employeeId}
                            key={"employeeList" + i}
                          >
                            {option?.firstName + " " + option?.lastName}
                          </option>
                        ))}
                    </select>
                  )}
                />
                <p className="text-danger">
                  {errorTransaction?.employeeId?.message}
                </p>
              </div>
              <div className="form-outline my-4">
                {requiredFieldLabel(t("cards.card_number"), "cardNumber")}
                <Controller
                  name="cardId"
                  control={transactionControl}
                  render={({ field }) => (
                    <select
                      {...field}
                      id="cardNumber"
                      className={`form-select form-control-lg ${currentLanguageCode === "en"
                        ? "form-control"
                        : "form-control-arabic"
                        }`}
                      defaultValue=""
                      style={{ color: field.value ? "black" : "grey" }}
                    >
                      <option value="">{'Select Card'}</option>
                      {
                        employeeCards && employeeCards.length > 0 &&
                        employeeCards?.map((option, i) => (
                          <option value={option?.cardId} key={'cardlist' + i}>{option?.cardToken}</option>
                        ))}
                    </select>
                  )}
                />
                <p className="text-danger">
                  {errorTransaction?.cardId?.message}
                </p>
              </div>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t("expenses.merchant_name"), "merchantName")}
                <Controller
                  name="merchantName"
                  control={transactionControl}
                  render={({ field }) => (
                    <input
                      type="text"
                      id="merchantName"
                      {...field}
                      className={`form-control form-control-lg ${errorTransaction?.merchantName ? "is-invalid" : ""
                        }`}
                      placeholder={`${t("expenses.merchant_name")}`}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorTransaction?.merchantName?.message}
                </p>
              </div>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t("expenses.Select_currency"), "selectCurrency")}
                <Controller
                  name="currencyId"
                  control={transactionControl}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isSearchable
                      options={selectOptions}
                      placeholder={t("expenses.Select_currency")}
                      styles={{
                        placeholder: (provided) => ({
                          ...provided,
                          color: "grey",
                        }),
                      }}
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption)
                      }
                      id="selectCurrency"
                    />
                  )}
                />
                <p className="text-danger">
                  {errorTransaction?.currencyId?.value?.message}
                </p>
              </div>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t("teams.selectedAmount"), "selectedAmount")}
                <Controller
                  name="selectedCurrencyAmount"
                  control={transactionControl}
                  render={({ field }) => (
                    <CurrencyInput
                      type="text"
                      id="selectedAmount"
                      className={`form-control form-control-lg ${errorTransaction?.selectedCurrencyAmount
                        ? "is-invalid"
                        : ""
                        }`}
                      placeholder={`${t("teams.selectedAmount")}`}
                      value={field.value || ""}
                      disablenegative={"true"}
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onValueChange={(value) => {
                        const numericValue =
                          parseFloat(value?.replace(/,/g, "")) || 0;
                        if (numericValue < MAX_VALUE) {
                          field.onChange(
                            value === ""
                              ? ""
                              : value?.startsWith(".")
                                ? `0${value}`
                                : value
                          );
                        }
                      }}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorTransaction?.selectedCurrencyAmount?.message}
                </p>
              </div>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t("teams.amount_QAR"), "amount")}
                <Controller
                  name="amount"
                  control={transactionControl}
                  render={({ field }) => (
                    <CurrencyInput
                      type="text"
                      id="amount"
                      className={`form-control form-control-lg ${errorTransaction?.amount ? "is-invalid" : ""
                        }`}
                      placeholder={`${t("teams.amount")}`}
                      decimalsLimit={2}
                      decimalScale={2}
                      value={field.value || ""}
                      disablenegative={"true"}
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onValueChange={(value) => {
                        const numericValue =
                          parseFloat(value?.replace(/,/g, "")) || 0;
                        if (numericValue < MAX_VALUE) {
                          field.onChange(
                            value === ""
                              ? ""
                              : value?.startsWith(".")
                                ? `0${value}`
                                : value
                          );
                        }
                      }}
                    />
                  )}
                />
                <p className="text-danger">
                  {errorTransaction?.amount?.message}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="px-0 pt-3">
              <SecondaryButton
                type={'button'}
                onClick={(e) => {
                  e.preventDefault();
                  handleCloseModalFour();
                }}
                buttonText={t("cancel")}
                isEnabled={true}
                id={'cancel_add_expenses'}
                style={{ width: '96px' }}
              />

              <PrimaryButton
                type={'submit'}
                buttonText={t("save")}
                isEnabled={true}
                id={'submit_add_expenses'}
                isLoading={isLoading}
                style={{ width: '148px' }}
              />
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          className="UserModal"
          show={showFilter}
          onHide={handleCloseModalFilter}
          style={{ paddingRight: 0 }}
        >
          <form
            onSubmit={handleFilterSubmit(onFilterFormSubmit)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleFilterSubmit(onFilterFormSubmit)();
              }
            }}
          >
            <Modal.Body>
              {isLoading && cardReducer?.isLoading && <LoadingComponent />}
              <div className="d-flex justify-content-between align-items-start green-header p-3 py-2">
                <div className="col-9">
                  <p
                    className="text-green modal-title"
                    style={{ fontSize: 20 }}
                  >
                    {"Filters"}
                  </p>
                </div>
                <div
                  onClick={() => {
                    handleCloseModalFilter();
                  }}
                  className="clo-2"
                  style={{ cursor: "pointer" }}
                  id="close_modal"
                >
                  <img
                    src={CrossImage}
                    style={{
                      width: 20,
                      objectFit: "contain",
                      marginTop: 5,
                      marginBottom: 3,
                    }}
                  />
                </div>
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="startDate">
                  Start Date
                </label>
                <Controller
                  name="startDate"
                  control={controlFilterForm}
                  render={({ field }) => (
                    <input
                      type="date"
                      id="startDate"
                      className="form-control form-control-lg"
                      placeholder="Start Date"
                      autoComplete="off"
                      {...field}
                      style={{
                        color: field.value ? "black" : "grey",
                      }}
                      max={moment().add(1, "days").format("YYYY-MM-DD")}
                    />
                  )}
                />
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="endDate">
                  End Date
                </label>
                <Controller
                  name="endDate"
                  control={controlFilterForm}
                  render={({ field }) => (
                    <input
                      type="date"
                      id="endDate"
                      className="form-control form-control-lg"
                      placeholder="Start Date"
                      autoComplete="off"
                      {...field}
                      style={{
                        color: field.value ? "black" : "grey",
                      }}
                      max={moment().add(1, "days").format("YYYY-MM-DD")}
                    />
                  )}
                />
                {errorsFilterForm?.endDate && (
                  <div style={{ color: "red" }}>
                    {errorsFilterForm?.endDate?.message}
                  </div>
                )}
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="fromAmount">
                  {t("expenses.from_amount")}
                </label>
                <Controller
                  name="fromAmount"
                  control={controlFilterForm}
                  render={({ field }) => (
                    <CurrencyInput
                      type="text"
                      id="fromAmount"
                      className="form-control form-control-lg"
                      placeholder="From Amount"
                      autoComplete="off"
                      value={field.value || ""}
                      decimalsLimit={2}
                      decimalScale={2}
                      disablenegative={"true"}
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onValueChange={(value) => {
                        const numericValue =
                          parseFloat(value?.replace(/,/g, "")) || 0;
                        if (numericValue < MAX_VALUE) {
                          field.onChange(
                            value === ""
                              ? ""
                              : value?.startsWith(".")
                                ? `0${value}`
                                : value
                          );
                        }
                      }}
                    />
                  )}
                />
                {errorsFilterForm?.fromAmount && (
                  <div style={{ color: "red" }}>
                    {errorsFilterForm?.fromAmount?.message}
                  </div>
                )}
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="toAmount">
                  {t("expenses.to_amount")}
                </label>
                <Controller
                  name="toAmount"
                  control={controlFilterForm}
                  render={({ field }) => (
                    <CurrencyInput
                      type="text"
                      id="toAmount"
                      className="form-control form-control-lg"
                      placeholder="To Amount"
                      autoComplete="off"
                      value={field.value || ""}
                      decimalsLimit={2}
                      decimalScale={2}
                      disablenegative={"true"}
                      onKeyDown={(e) => {
                        if (e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      onValueChange={(value) => {
                        const numericValue =
                          parseFloat(value?.replace(/,/g, "")) || 0;
                        if (numericValue < MAX_VALUE) {
                          field.onChange(
                            value === ""
                              ? ""
                              : value?.startsWith(".")
                                ? `0${value}`
                                : value
                          );
                        }
                      }}
                    />
                  )}
                />
                {errorsFilterForm?.toAmount && (
                  <div style={{ color: "red" }}>
                    {errorsFilterForm?.toAmount?.message}
                  </div>
                )}
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="selectDepartment">
                  {t("expenses.select_department")}
                </label>
                <Controller
                  name="departmentId"
                  control={controlFilterForm}
                  render={({ field }) => (
                    <select
                      value={field.value ?? ""}
                      id="selectDepartment"
                      onChange={(e) => {
                        const selectedValue =
                          e.target.value === "" ? undefined : e.target.value;
                        field.onChange(selectedValue);
                        e.target.classList.add("select-placeholder-selected");
                      }}
                      onBlur={field.onBlur}
                      className={`form-select form-control-lg ${currentLanguageCode === "en"
                        ? "form-control"
                        : "form-control-arabic"
                        } ${!field.value ? "select-placeholder" : ""
                        } text-capitalize`}
                    >
                      <option value={""}>{"Select Department"}</option>
                      {departmentData &&
                        departmentData.length > 0 &&
                        departmentData?.map((option, i) => (
                          <option
                            className="text-capitalize"
                            value={option?.departmentId}
                            key={"employeeList" + i}
                          >
                            {option?.departmentName}
                          </option>
                        ))}
                    </select>
                  )}
                />
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="receiptUploaded">
                  {t("expenses.receipt_uploaded")}
                </label>
                <Controller
                  name="receiptUploaded"
                  control={controlFilterForm}
                  render={({ field }) => (
                    <select
                      onChange={(e) => {
                        const selectedValue =
                          e.target.value === "" ? undefined : e.target.value;
                        field.onChange(selectedValue);
                      }}
                      onBlur={field.onBlur}
                      value={field.value || ""}
                      id="receiptUploaded"
                      className={`form-select form-control-lg ${currentLanguageCode === "en"
                        ? "form-control"
                        : "form-control-arabic"
                        }`}
                      defaultValue=""
                      style={{ color: field.value ? "black" : "grey" }}
                    >
                      <option value="">{"Select Options"}</option>
                      {[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" },
                      ].map((option, i) => (
                        <option
                          value={option?.value}
                          key={"receiptUploaded" + i}
                        >
                          {option?.label}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="categorySelected">
                  {t("expenses.category_selected")}
                </label>
                <Controller
                  name="categorySelected"
                  control={controlFilterForm}
                  render={({ field }) => (
                    <select
                      onChange={(e) => {
                        const selectedValue =
                          e.target.value === "" ? undefined : e.target.value;
                        field.onChange(selectedValue);
                      }}
                      onBlur={field.onBlur}
                      value={field.value || ""}
                      id="categorySelected"
                      className={`form-select form-control-lg ${currentLanguageCode === "en"
                        ? "form-control"
                        : "form-control-arabic"
                        }`}
                      defaultValue=""
                      style={{ color: field.value ? "black" : "grey" }}
                    >
                      <option value="">{"Select Options"}</option>
                      {[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" },
                      ].map((option, i) => (
                        <option
                          value={option?.value}
                          key={"categorySelected" + i}
                        >
                          {option?.label}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="exported">
                  {t("export.exported")}
                </label>
                <Controller
                  name="exported"
                  control={controlFilterForm}
                  render={({ field }) => (
                    <select
                      onChange={(e) => {
                        const selectedValue =
                          e.target.value === "" ? undefined : e.target.value;
                        field.onChange(selectedValue);
                      }}
                      onBlur={field.onBlur}
                      value={field.value || ""}
                      id="exported"
                      className={`form-select form-control-lg ${currentLanguageCode === "en"
                        ? "form-control"
                        : "form-control-arabic"
                        }`}
                      defaultValue=""
                      style={{ color: field.value ? "black" : "grey" }}
                    >
                      <option value="">{"Select Options"}</option>
                      {[
                        { label: "Yes", value: "yes" },
                        { label: "No", value: "no" },
                      ].map((option, i) => (
                        <option value={option?.value} key={"exported" + i}>
                          {option?.label}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="transactionStatus">
                  {t("expenses.transaction_status")}
                </label>
                <Controller
                  name="transactionStatus"
                  control={controlFilterForm}
                  render={({ field }) => (
                    <select
                      onChange={(e) => {
                        const selectedValue =
                          e.target.value === "" ? undefined : e.target.value;
                        field.onChange(selectedValue);
                      }}
                      onBlur={field.onBlur}
                      value={field.value || ""}
                      id="transactionStatus"
                      className={`form-select form-control-lg ${currentLanguageCode === "en"
                        ? "form-control"
                        : "form-control-arabic"
                        }`}
                      defaultValue=""
                      style={{ color: field.value ? "black" : "grey" }}
                    >
                      <option value="">{"Select Options"}</option>
                      {[
                        { label: "Pending", value: "pending" },
                        { label: "Approve", value: "approve" },
                        { label: "Reject", value: "reject" },
                      ].map((option, i) => (
                        <option
                          value={option?.value}
                          key={"transactionStatus" + i}
                        >
                          {option?.label}
                        </option>
                      ))}
                    </select>
                  )}
                />
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="selectCategoryId">
                  {t("expenses.select_category_id")}
                </label>
                <Controller
                  name="selectCategoryId"
                  control={controlFilterForm}
                  render={({ field: { onChange, value = [] } }) => (
                    <Select
                      isMulti
                      options={categoryList.map((option) => ({
                        label: option.name,
                        value: option.categoryId,
                      }))}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderColor: "#EDEDED",
                          padding: "6.8px 3px",
                          borderRadius: 12,
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "grey",
                        }),
                      }}
                      placeholder={"Select Category Id"}
                      value={value}
                      onChange={(selectedOptions) => {
                        onChange(selectedOptions);
                      }}
                      id="selectCategoryId"
                    />
                  )}
                />
              </div>
              <div className="form-outline mt-4 mb-3">
                <label
                  className="form-label"
                  htmlFor="tagId"
                >{`Select Tags`}</label>
                <Controller
                  name="tagId"
                  control={controlFilterForm}
                  render={({ field: { onChange, value = [] } }) => (
                    <Select
                      isMulti
                      options={tags?.map((option) => ({
                        label: option.label,
                        value: option.tagGroupId,
                      }))}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderColor: "#EDEDED",
                          padding: "6.8px 3px",
                          borderRadius: 12,
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "grey",
                        }),
                      }}
                      placeholder={"Select Tags"}
                      value={value}
                      onChange={(selectedOptions) => {
                        onChange(selectedOptions);
                      }}
                      id="tagId"
                    />
                  )}
                />
              </div>
            </Modal.Body>
            <Modal.Footer className="px-0">
              <SecondaryButton
                type={'button'}
                onClick={() => {
                  handleResetFilterForm();
                  handleCloseModalFilter();
                }}
                buttonText={t("cancel")}
                isEnabled={true}
                id={'cancel_filter_expenses'}
                style={{ marginTop: '8px', width: '96px' }}
              />
              <PrimaryButton
                type={'submit'}
                buttonText={t("save")}
                isEnabled={true}
                id={'submit_filter_expenses'}
                style={{ marginTop: '8px', width: '148px' }}
                isLoading={isLoading}
              />
            </Modal.Footer>
          </form>
        </Modal>
        <Modal
          aria-labelledby="contained-modal-title-center"
          centered
          show={showVerifyModal}
          onHide={handleCloseVerifyModal}
        >
          <form
            className="form"
            style={{ padding: 24 }}
            onSubmit={handleOtpSubmit(onTopUpFormSubmit)}
          >
            <div className="d-flex align-items-start justify-content-between">
              <img
                src={Logo2}
                className="logoso mt-1 mb-4"
                alt="Sample image"
              />
              <div onClick={handleCloseVerifyModal}>
                <img
                  src={ImagesCross}
                  className="small-addjust"
                  style={{ width: 20, objectFit: "contain", marginLeft: 15 }}
                />
              </div>
            </div>
            <h1 className="text-blue-login mb-2">
              {t("login.verify_your_mobile_number")}
            </h1>
            <div className="divider d-flex align-items-center my-2">
              <p className="mb-0 text-subtitle2" style={{ color: "#8B9299" }}>
                {t("login.verify_your_mobile_number_desc")}
              </p>
            </div>

            <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
              <Controller
                name="otp"
                control={controlVerifyModal}
                defaultValue={0}
                render={({ field: { onChange, value } }) => (
                  <OtpInput
                    inputStyle="inputStyle"
                    value={value}
                    onChange={onChange}
                    shouldAutoFocus={true}
                    numInputs={6}
                    renderInput={(props) => (
                      <input
                        {...props}
                        id="input_otp"
                        onInput={(e) => {
                          const value = e.target.value;
                          if (!/^\d*$/.test(value)) {
                            e.target.value = value.slice(0, -1);
                          }
                        }}
                      />
                    )}
                  />
                )}
              />
            </div>
            <p className="text-danger">{errorsOtpForm?.otp?.message}</p>
            <div className="d-flex justify-content-end">
              {timer === 0 ? (
                <a href="javascript:void(0)" id="resend_otp">
                  Resend OTP
                </a>
              ) : (
                <p>
                  Resend OTP in{" "}
                  <span className="text-blue">{timer} seconds</span>{" "}
                </p>
              )}
            </div>
            <div className="text-center text-lg-start mt-4 w-100">
              <PrimaryButton
                type={'submit'}
                buttonText={"Continue to Topup Card"}
                isEnabled={true}
                id={'continue'}
                isLoading={isLoading || isBalanceLoading}
                style={{ position: "relative", zIndex: "1" }}
              />
            </div>
          </form>
        </Modal>
        <Modal
          className="modal-overlay"
          show={isModalVisible}
          onHide={handleCloseModal}
          style={{ padding: 120 }}
        >
          <form
            onSubmit={handleSimulateSubmit(handleSimulate)}
            className="simulate"
          >
            <Modal.Body>
              <div className="d-flex justify-content-end ">
                <div
                  onClick={handleCloseModal}
                  className=""
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={ImagesCross}
                    style={{ width: 20, objectFit: "contain" }}
                  />
                </div>
              </div>

              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="card_number">
                  {t("expenses.card_number")}
                </label>
                <Controller
                  name="card_number"
                  control={controlSimulateForm}
                  render={({ field }) => (
                    <input
                      type="text"
                      {...field}
                      id="card_number"
                      className="form-control form-control-lg"
                      placeholder={`${t("expenses.card_number")}`}
                      maxLength={16}
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/\D/g, ""))
                      }
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsSimulateForm?.card_number?.message}
                </p>
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="expire">
                  {t("expenses.expire")}
                </label>
                <Controller
                  name="expire"
                  control={controlSimulateForm}
                  render={({ field }) => (
                    <input
                      type="text"
                      {...field}
                      id="expire"
                      className="form-control form-control-lg"
                      placeholder={`${t("expenses.expire_placeholder")}`}
                      maxLength={4}
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/\D/g, ""))
                      }
                    />
                  )}
                />
                <p className="text-danger">
                  {errorsSimulateForm?.expire?.message}
                </p>
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="cvv">
                  {t("expenses.cvv")}
                </label>
                <Controller
                  name="cvv"
                  control={controlSimulateForm}
                  render={({ field }) => (
                    <input
                      type="text"
                      {...field}
                      id="cvv"
                      className="form-control form-control-lg"
                      placeholder={`${t("expenses.cvv")}`}
                      maxLength={3}
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/\D/g, ""))
                      }
                    />
                  )}
                />
                <p className="text-danger">{errorsSimulateForm?.cvv?.message}</p>
              </div>
              <div className="form-outline mt-4 mb-3">
                <label className="form-label" htmlFor="pin">
                  {t("expenses.pin")}
                </label>
                <Controller
                  name="pin"
                  control={controlSimulateForm}
                  render={({ field }) => (
                    <input
                      type="text"
                      {...field}
                      id="pin"
                      className="form-control form-control-lg"
                      placeholder={`${t("expenses.pin")}`}
                      maxLength={4}
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/\D/g, ""))
                      }
                    />
                  )}
                />
                <p className="text-danger">{errorsSimulateForm?.pin?.message}</p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <SecondaryButton
                type={'button'}
                onClick={handleCloseModal}
                buttonText={t("cancel")}
                isEnabled={true}
                id={'cancel_card'}
                style={{ width: '96px' }}
              />

              <PrimaryButton
                type={'submit'}
                buttonText={t("expenses.confirm")}
                isEnabled={!isPendingSimulateTransaction}
                id={'submit_card'}
                isLoading={isPendingSimulateTransaction}
                style={{ width: '148px' }}
              />
            </Modal.Footer>
          </form>
        </Modal>

      </div>
    </div>
  );
}

export default Expenses;