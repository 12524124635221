import React, { useState } from 'react';
import { CChart } from '@coreui/react-chartjs';
import { FaCircle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import './DonutChart.scss';
import { dashboardTransactionByVendorColor } from '../../utils/XpendlessUtils';
import ImagesCross from '../../assets/images/close-square-8.png';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import List2 from '../../assets/images/list1.png';
import Dropdown from 'react-bootstrap/Dropdown';
import SecondaryButton from '../Buttons/SecondaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import { Stack } from '@mui/material';
import LoadingComponent from '../Loader/LoadingComponent';
import PropTypes from 'prop-types';

function DonutChart(props) {
    const { transactionByVendorsLoading, transactionByVendors, handleFilterClick, section, globalSelectedFil, setSelectedFilterDounut, selectedFilterDounut } = props;
    const { t } = useTranslation();
    const options = [
        { value: 'current_month', label: t('dashboard.current_month') },
        { value: 'current_year', label: t('dashboard.current_year') },
        { value: 'last_3_year', label: t('dashboard.last_3_years') },
        { value: 'custom', label: t('dashboard.custom') },
    ];
    const [show, setShow] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const setLoader = () => {
        setIsLoading(true);

        const timerId = setTimeout(() => {
            setIsLoading(false);
        }, 5000);

        return () => clearTimeout(timerId);
    };
    const schema = yup.object().shape({
        startDate: yup
            .string()
            .required('Start Date is required'),
        endDate: yup
            .string()
            .required('End Date is required')
            .test('is-valid-end-date', 'End date should not be before start date', function (value) {
                const startDate = this.resolve(yup.ref('startDate'));
                return !startDate || !value || value >= startDate;
            }),
    });

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const handleClose = () => setShow(false);

    const handleCloseMenu = (selectedOption) => {
        if (selectedOption) {
            const { value, label } = selectedOption;
            setSelectedFilterDounut(label);

            if (value === 'custom') {
                setShow(true);
            } else {
                handleFilterClick(section, value, {}, true);
            }
        }

    };


    const onSubmit = (data) => {
        setLoader();
        setSelectedFilterDounut(`${moment(data?.startDate).format('DD/MM/YYYY')} - ${moment(data?.endDate).format('DD/MM/YYYY')}`);
        handleFilterClick(section, 'custom', data, true);
        setShow(false);
    };

    return (
        <div className="p-3 position-relative donutchatPage-Css" style={{ height: '100%' }}>
            {transactionByVendorsLoading && <LoadingComponent />}
            <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
                <div className='d-flex align-items-center justify-content-center'>
                    <img src={List2} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
                    <h2 className='header-text mb-0'>{t('dashboard.transactions')}{t('dashboard.by_vendors')}</h2>
                </div>
                <Dropdown>
                    <Dropdown.Toggle
                        id="custom-dropdown"
                        className="custom-dropdown-toggle btn-back custom-dashboard-button"
                    >
                        {selectedFilterDounut === null ? globalSelectedFil : selectedFilterDounut}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-dropdown-menu">
                        {options.map((option, index) => (
                            <Dropdown.Item
                                key={index}
                                onClick={() => handleCloseMenu(option)}
                                className="custom-dropdown-item"
                            >
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Stack>
            <hr />
            {transactionByVendors && transactionByVendors.length < 1 &&
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {!transactionByVendorsLoading && 'No data'}
                </div>
            }
            {transactionByVendors && transactionByVendors.length > 0 &&
                <CChart className='doughnut-chart-dashboard'
                    type="doughnut"
                    data={{
                        datasets: [
                            {
                                backgroundColor: dashboardTransactionByVendorColor,
                                data: transactionByVendors?.map((item) => {
                                    return item?.totalAmount || 0;
                                }),
                                borderRadius: 5,
                            },
                        ],
                        labels: transactionByVendors?.map((item) => item?.merchantName),
                    }}
                    options={{
                        plugins: {
                            legend: {
                                display: false,
                                labels: {
                                }
                            },
                            tooltip: {
                                enabled: true,
                                callbacks: {
                                    label: (tooltipItem) => {
                                        const label = tooltipItem?.label;
                                        const value = tooltipItem?.parsed;
                                        return `${label}: ${value} QAR`;
                                    },
                                },
                            },
                        },
                    }}
                />
            }
            <div className="row g-2 mt-3 justify-content-center" style={{ paddingInline: '100px' }}>
                {transactionByVendors?.map((item, index) => {
                    const isLastOdd = index === transactionByVendors.length - 1 && transactionByVendors.length % 2 !== 0;
                    return (
                        <div className={isLastOdd ? "col-12" : "col-6"} key={index}>
                            <div className="d-flex align-items-center">
                                <FaCircle size={8} color={dashboardTransactionByVendorColor[index]} />
                                <h4 className="text-grey" style={{ marginLeft: 5, marginRight: 5 }}>
                                    {item.merchantName}
                                </h4>
                            </div>
                        </div>
                    );
                })}
            </div>
            <Modal className='TaxcodeModalOne' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
                <form className='form' onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start green-header p-2'>
                            <div className='col-9'>
                                <p className='text-green modal-title' style={{ fontSize: 18 }}>
                                    Select Custom Date
                                </p>
                            </div>
                            <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="startDate">
                                Start Date
                            </label>

                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="startDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.startDate && (
                                <div style={{ color: 'red' }}>{errors?.startDate?.message}</div>
                            )}
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="endDate">
                                End Date
                            </label>

                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="endDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.endDate && (
                                <div style={{ color: 'red' }}>{errors?.endDate?.message}</div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <SecondaryButton
                            type={'button'}
                            onClick={(e) => handleClose(e)}
                            buttonText={t('cancel')}
                            id={'cancel_custom_date'}
                            style={{ width: '104px' }}
                        />

                        <PrimaryButton
                            type={'submit'}
                            buttonText={t('submit')}
                            isEnabled={true}
                            id={'submit_custom_date'}
                            style={{ width: '104px' }}
                            isLoading={isLoading}
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

DonutChart.propTypes = {
    transactionByVendorsLoading: PropTypes.bool.isRequired,
    transactionByVendors: PropTypes.arrayOf(
        PropTypes.shape({
            totalAmount: PropTypes.number,
            merchantName: PropTypes.string,
        })
    ).isRequired,
    handleFilterClick: PropTypes.func.isRequired,
    section: PropTypes.string.isRequired,
    globalSelectedFil: PropTypes.string,
    setSelectedFilterDounut: PropTypes.func.isRequired,
    selectedFilterDounut: PropTypes.string,
};

export default DonutChart;
