import React, { useState } from "react";
import "./customSlider.scss";
import { useNavigate } from "react-router-dom";
import CompanyWalletPage from "./CompanyWallet";
import TransactionPage from "./Transactions";
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import ReimbursementPage from "./Reimbursement";
import ExpensePage from "./Expense";
import i18next from 'i18next';
import Logo3 from '../../assets/images/newXpendlessLogo.png';
import InfoSection from "./ContentSection";
import { useTranslation } from "react-i18next";
import { useInfoSectionProps } from "./constants";

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];


export default function WalkThrough() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation()

  const settings = {
    dots: false,
    infinite: false,
    speed: 5,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    swipe: false,
    draggable: false
  };

  const totalSlides = 4;


  const handleFilterClick = (index, code) => {
    setSelectedIndex(index);
    i18next.changeLanguage(code);
  };

  const getRightArea = () => {
    switch (currentSlide) {
      case 0:
        return <CompanyWalletPage />

      case 1:
        return <TransactionPage />

      case 2:
        return <ReimbursementPage />

      case 3:
        return <ExpensePage />

      default:
        break;
    }
  }
  const infoSectionProps = useInfoSectionProps();

  return (
    <div className="walk-through px-2 " style={{ overflowX: 'hidden', maxHeight: '100vh', width: '100vw', overflowY: "hidden" }}>
      <div className='d-flex flex-column overflow-hidden onboarding-container' >
        <div className="d-flex flex-column " style={{ overflow: "hidden", flexGrow: 1, position: 'relative', }}>
          <div
            className="d-flex header-container mt-3 justify-content-between align-items-center"
          >
            <img style={{ height: 50 }} src={Logo3} alt="Logo" />
            <div>
              <div className="language-selector d-flex justify-content-between">
                {languages?.map(({ code, name }, index) => (
                  <div
                    key={index}
                    onClick={() => handleFilterClick(index, code)}
                    className={`language-option flex-grow-1 mx-1 ${selectedIndex === index ? "selected" : ""}`}
                  >
                    {name}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`container-fluid ${[1, 2].includes(currentSlide) ? 'container-with-gap' : 'section-container'}`}>
            <div className="row">
              <div className="col-md-7">
                {getRightArea()}
              </div>

              <div className="col-md-5 left-section ">
                <div className={`info-section-container ${[2, 3].includes(currentSlide) ? "top-space" : ""}`}>
                  <InfoSection
                    {...infoSectionProps[currentSlide]}
                    currentSlide={currentSlide}
                    totalSlides={totalSlides}
                    setCurrentSlide={setCurrentSlide}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className='login_bottom_image-1' src={login_bottom_image} />
      <div className="radio-buttons-container mb-3" >
        {Array.from({ length: totalSlides }).map((_, index) => (
          <input
            key={index}
            type="radio"
            checked={currentSlide === index}
            onChange={() => setCurrentSlide(index)}
            className="radio-button form-check-input"
            style={{ cursor: "pointer" }}
          />
        ))}
      </div>
    </div>
  );
}