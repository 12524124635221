import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ImagesCross from '../../assets/images/close-square-8.png';
import ProfileIcon from '../ProfileIcon';
import moment from 'moment';
import SpendsImg from '../../assets/images/card-send.png';
import './Dashboard.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import cookies from 'js-cookie';
import { formatQAR } from '../../utils/XpendlessUtils';
import { Stack } from '@mui/material';
import SecondaryButton from '../Buttons/SecondaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import LoadingComponent from '../Loader/LoadingComponent';

function DashboardTopSpends(props) {
    const { spendPerCategory, spendPerCategoryLoading, handleFilterClick, globalSelectedFil, selectedFilterSpendsOne, setSelectedFilterSpendsOne, globalOption, globalCustomDate } = props;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [show, setShow] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState('');
    const [customDate, setCustomDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const setLoader = () => {
        setIsLoading(true);

        const timerId = setTimeout(() => {
            setIsLoading(false);
        }, 5000);

        return () => clearTimeout(timerId);
    };

    const { t } = useTranslation();
    const options = [
        { value: 'current_month', label: t('dashboard.current_month') },
        { value: 'current_year', label: t('dashboard.current_year') },
        { value: 'last_3_year', label: t('dashboard.last_3_years') },
        { value: 'custom', label: t('dashboard.custom') },
    ];
    const filters = [t('dashboard.employees'), t('dashboard.department'),];
    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'ar',
            name: 'العربية',
            dir: 'rtl',
            country_code: 'sa',
        },
    ];
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    const schema = yup.object().shape({
        startDate: yup
            .string()
            .required('Start Date is required'),
        endDate: yup
            .string()
            .required('End Date is required')
            .test('is-valid-end-date', 'End date should not be before start date', function (value) {
                const startDate = this.resolve(yup.ref('startDate'));
                return !startDate || !value || value >= startDate;
            }),
    });

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (globalOption) {
            setSelectedOptions('');
        }
    }, [globalOption]);

    useEffect(() => {
        handleFilterClick(selectedIndex === 0 ? 'most_spend_employee' : 'most_spend_department', selectedOptions ? selectedOptions : globalOption, selectedFilterSpendsOne ? customDate : globalCustomDate, true);
    }, [selectedIndex]);

    useEffect(() => {
        if (globalSelectedFil) {
            setCustomDate(null);
            setSelectedIndex(0);
        }
    }, [globalSelectedFil]);

    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
        reset();
    };

    const handleCloseMenu = (option, section) => {
        if (option === 'current_month') {
            setSelectedFilterSpendsOne('Current Month');
        }
        if (option === 'current_year') {
            setSelectedFilterSpendsOne('Current Year');
        }
        if (option === 'last_3_year') {
            setSelectedFilterSpendsOne('Last 3 Years');
        }
        setSelectedOptions(option);
        if (option != '' && typeof option === 'string') {
            if (option === 'custom') {
                setShow(true);
            } else {
                handleFilterClick(section, option, {}, true);
            }
        }
    };

    const onSubmit = (data) => {
        setLoader();
        setSelectedFilterSpendsOne(`${moment(data?.startDate).format('DD/MM/YYYY')} - ${moment(data?.endDate).format('DD/MM/YYYY')}`);
        setCustomDate(data);
        handleFilterClick(selectedIndex === 0 ? 'most_spend_employee' : 'most_spend_department', 'custom', data, true);
        setShow(false);
        reset();
    };
    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    return (
        <div className='cards-custom position-relative p-3 dashboardtopSpendsPageCss'>
            {spendPerCategoryLoading && <LoadingComponent/>}
            <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={SpendsImg} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
                    <h2 className='green-header-text'>{t('dashboard.most_spends')}</h2>
                </div>
                <Dropdown>
                    <Dropdown.Toggle
                        id="custom-dropdown"
                        className="custom-dropdown-toggle btn-back custom-dashboard-button"
                    >
                        {selectedFilterSpendsOne !== null ? selectedFilterSpendsOne : globalSelectedFil}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="custom-dropdown-menu">
                        {options.map((option, index) => (
                            <Dropdown.Item
                                key={index}
                                onClick={() => handleCloseMenu(option.value, selectedIndex === 0 ? 'most_spend_employee' : 'most_spend_department')}
                                className="custom-dropdown-item"
                            >
                                {option.label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </Stack>
            <div className='col-lg-6 col-md-12 mt-2'>
                <div style={{ backgroundColor: '#FFFFFF', padding: 4, borderWidth: 1, marginTop: window.innerWidth < 700 ? 15 : 0 }} className='d-flex align-items-center justify-content-between green-radius'>
                    {
                        filters?.map((item, index) => (
                            <div key={index} onClick={() => setSelectedIndex(index)} style={{ backgroundColor: selectedIndex === index ? '#01DD9D' : 'transparent', color: selectedIndex === index ? '#FFFFFF' : '#667085', width: '49%', textAlign: 'center', paddingTop: 9, paddingBottom: 9, borderRadius: 12, cursor: 'pointer' }}>
                                {item}
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='table-responsive mt-4'>
                <table id="topSpends" cellSpacing="0" style={{ borderCollapse: 'separate', borderSpacing: '0 16px', paddingInline: '16px' }} width="100%">
                    <thead>
                        <tr style={{ borderTopWidth: 0 }} className='table-head'>
                            <th scope="col" style={{textAlign: 'left'}}>{selectedIndex === 0 ? t('name') : t('cards.department')}</th>
                            <th scope="col" style={{textAlign: 'right'}}>{t('dashboard.amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {spendPerCategory?.map((item, index) => (
                            <tr key={index} className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`} style={{ backgroundColor: '#A9AFB60D', borderRadius: 15 }}>
                                {
                                    selectedIndex === 0 ?
                                        <td style={{ textAlign: 'left' }}>
                                            <Stack direction={'row'} alignItems={'baseline'}>
                                                <ProfileIcon
                                                    imageUrl={item?.icon}
                                                    name={item?.firstName + ' ' + item?.lastName}
                                                    textStyle={{
                                                        alignItems: 'center',
                                                        justifyContent: 'space-around'
                                                    }}
                                                />
                                                <p className='mb-0'>{item?.firstName} {item?.lastName}</p>
                                            </Stack>
                                        </td> :
                                        <td style={{ textAlign: 'right' }}>
                                            <Stack direction={'row'} alignItems={'baseline'}>
                                                <ProfileIcon
                                                    imageUrl={item?.icon}
                                                    name={item?.departmentName}
                                                    textStyle={{
                                                        alignItems: 'center',
                                                        justifyContent: 'space-around'
                                                    }}
                                                />
                                                <p>{item?.departmentName}</p>
                                            </Stack>
                                        </td>
                                }
                                <td style={{ textAlign: 'right' }}>{formatQAR(item?.total_amount)} QAR</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal className='TaxcodeModalOne' show={show} onHide={(e) => handleClose(e)} style={{ paddingRight: 0 }}>
                <form className='form' onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start green-header p-2'>
                            <div className='col-9'>
                                <p className='text-green modal-title' style={{ fontSize: 18 }}>
                                    Select Custom Date
                                </p>
                            </div>
                            <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="startDate">
                                Start Date
                            </label>

                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="startDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.startDate && (
                                <div style={{ color: 'red' }}>{errors?.startDate?.message}</div>
                            )}
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="endDate">
                                End Date
                            </label>

                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="endDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.endDate && (
                                <div style={{ color: 'red' }}>{errors?.endDate?.message}</div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <SecondaryButton
                            type={'button'}
                            onClick={(e) => handleClose(e)}
                            buttonText={t('cancel')}
                            id={'cancel_custom_date'}
                            style={{ width: '104px' }}
                        />

                        <PrimaryButton
                            type={'submit'}
                            buttonText={t('submit')}
                            isEnabled={true}
                            id={'submit_custom_date'}
                            style={{ width: '104px' }}
                            isLoading={isLoading}
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default DashboardTopSpends;
