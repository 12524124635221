import React from 'react';
import cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const InfoSection = ({ title, content, iconSrc,currentSlide, totalSlides, setCurrentSlide }) => {
  const navigate = useNavigate();
  const currentLanguageCode = cookies.get('i18next') || 'en';


  const handleNavigate = () => {
    navigate('/companyverification');
  };

  const handleNextSlide = () => {
    if (currentSlide === totalSlides - 1) {
      handleNavigate();
    } else {
      setCurrentSlide(currentSlide + 1); 
    }
  };

  return (
    <div className='info-section col d-flex flex-column'>
      <div className="info-header d-flex align-items-center">
        <img
          src={iconSrc}
          alt="Icon"
          className="info-icon"
        />
        <h1 className="text-white card-title">{title}</h1>
      </div>
      <div className="info-content mt-4">
        <ul className="list-unstyled custom-list">
          {content.map((text, index) => (
            <li key={index} className="d-flex align-items-start mb-4">
              <span className="custom-circle rounded-circle text-white  d-flex justify-content-center mt-2" />
              <span className="list-text">{text}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="walk-through-action-buttons mt-5" 
      >
        {currentSlide !== totalSlides - 1 && (
          <div className="skip-styles mt-2" onClick={handleNavigate}>
            {currentLanguageCode === 'ar' ? "يتخطى" : "Skip"}
          </div>
        )}
        <button onClick={handleNextSlide} className="next-btn finish-button"
        >
          {currentSlide === totalSlides - 1
            ? currentLanguageCode === 'ar' ? "إنهاء" : "Finish"
            : currentLanguageCode === 'ar' ? "التالي" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default InfoSection;