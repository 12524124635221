import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import DebitCard from "../../components/Teams/DebitCard";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Freeze from "../../assets/images/freeze.svg";

const PreviousBtn = (props) => {
  const { className, goToPrevSlide } = props;
  return (
    <div className={className} onClick={goToPrevSlide} id="to_prev_slide">
      <img
        src={require(`../../assets/images/sliderprevimg.png`)}
        alt="Previous"
      />
    </div>
  );
};
const PreviousBtnArabic = (props) => {
  const { className, goToPrevSlide } = props;
  return (
    <div className={className} onClick={goToPrevSlide} id="to_prev_slide">
      <img
        src={require(`../../assets/images/slidernextimg.png`)}
        alt="Previous"
        style={{ position: "relative", right: 155 }}
      />
    </div>
  );
};

const NextBtnArabic = (props) => {
  const { className, goToNextSlide } = props;

  return (
    <div className={className} onClick={goToNextSlide} id="to_next_slide">
      <img
        src={require(`../../assets/images/sliderprevimg.png`)}
        alt="Next"
        style={{ position: "relative", left: 170 }}
      />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, goToNextSlide } = props;

  return (
    <div className={className} onClick={goToNextSlide} id="to_next_slide">
      <img src={require(`../../assets/images/slidernextimg.png`)} alt="Next" />
    </div>
  );
};

function CardSlider({
  details,
  activeCardIndex,
  setActiveCard,
  handleCardFreezUnfreezAction,
}) {
  const sliderRef = React.createRef();
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState({});
  const activeCardDetail = details[activeCardIndex];
  const status = activeCardDetail?.status;
  const isStatusInactiveOrSuspended = status === "inactive" || status === "suspended";
  useEffect(() => {
    const languages = [
      {
        code: "en",
        name: "English",
        country_code: "gb",
      },
      {
        code: "ar",
        name: "العربية",
        dir: "rtl",
        country_code: "sa",
      },
    ];
    const currentLanguageCode = cookies.get("i18next") || "en";
    const language = languages.find((l) => l.code === currentLanguageCode);
    setCurrentLanguage(language || {});
    document.body.dir = language?.dir || "ltr";
    document.title = t("app_title");
  }, [t]);

  const settings = {
    dots: true,
    arrows: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: activeCardIndex,
    className: "center",
    responsive: [
    ],
    afterChange: (currentSlide) => {
      setActiveCard(currentSlide);
    },
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };



  return (
    <>
      <div style={{
        filter: isStatusInactiveOrSuspended ? "grayscale(100%)" : "none",
        cursor: isStatusInactiveOrSuspended ? 'not-allowed' : 'pointer'
      }}>
        {currentLanguage.code === "en" ? (
          <Slider
            ref={sliderRef}
            prevArrow={<PreviousBtn goToPrevSlide={goToPrevSlide} />}
            nextArrow={<NextBtn goToNextSlide={goToNextSlide} />}
            {...settings}
          >

            {details.map((detail, index) => (

              <div className="p-3 px-3" key={index}>
                <DebitCard
                  backgroundColor={index % 2 === 0 ? "" : "grey"}
                  detail={detail}
                  handleCardFreezUnfreezAction={handleCardFreezUnfreezAction}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <Slider
            ref={sliderRef}
            prevArrow={<PreviousBtnArabic goToPrevSlide={goToPrevSlide} />}
            nextArrow={<NextBtnArabic goToNextSlide={goToNextSlide} />}
            {...settings}
          >
            {details.map((detail, index) => (
              <div className="p-3 px-3" key={index}>
                <DebitCard
                  backgroundColor={index % 2 === 0 ? "" : "grey"}
                  detail={detail}
                  handleCardFreezUnfreezAction={handleCardFreezUnfreezAction}
                />
              </div>
            ))}
          </Slider>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center pt-4">
        <div>
          <img src={Freeze} className="mx-2" alt="Freeze" />
          <span className="balance-text">Freeze card</span>
        </div>
        <div className="form-check form-switch">
          <input
            id={`${activeCardDetail?.isFreezed}_${activeCardDetail?.cardId}`}
            // disabled={false} // Set to true if you want to disable it
            className="form-check-input"
            type="checkbox"
            checked={activeCardDetail?.isFreezed}
            onChange={() =>
              handleCardFreezUnfreezAction(
                !activeCardDetail?.isFreezed,
                activeCardDetail?.cardId
              )
            }
          />
        </div>
      </div>
      <div className="balance-texttt">Lock this card temporarily</div>
    </>
  );
}




export default CardSlider;
