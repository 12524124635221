import React from 'react';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import NewSidebar from '../components/Sidebar/NewSidebar';
import { responseInterceptor } from './api';
import { verifyCompanyStatus } from './verifyCompanyStatus';
import InValidCompanyStatusComponent from '../components/InValidCompanyStatusComponent';
import { verifyUserRole } from './verifyUserRole';


const PrivateRoute = ({
    component: Component,
    allowedCompanyStatus = [],
    allowedUserRoles = []
}) => {
    const history = useNavigate();
    const location = useLocation();
    responseInterceptor(history);
    const isAuthenticated = localStorage.getItem('token');
    
    if (isAuthenticated) {
        const isValid = verifyCompanyStatus(allowedCompanyStatus);
        const isValidUserRole = verifyUserRole(allowedUserRoles);

        return (
        <div className='container-main-class row'>
            <NewSidebar 
               component={
                    isValid && isValidUserRole 
                    ? () => <Component /> 
                    : () => <InValidCompanyStatusComponent message={!isValidUserRole ? 'Access Denied, you cannot access this route' : ''} />
                }                 
            />
          </div>);
        
    } else {
        return <Navigate
            to={"/login"}
            replace
            state={{ from: location.pathname }}
        />;
    }
};

export default PrivateRoute;
