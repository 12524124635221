import React from "react";
import './buttonsStyle.scss';

function DeleteButton({ type, onClick, buttonText, isEnabled, id, style }) {
    return (
        <button
            type={type}
            {...(type === "button" && { onClick })}
            className={isEnabled ? 'btn-primary-small deleteStyledBtn' : 'disabledButton'}
            onClick={onClick}
            disabled= {!isEnabled}
            id={id}
            style={style}
        >
            {buttonText}
        </button>
    );
}

export default DeleteButton;
