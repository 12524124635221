import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ImagesCross from '../../assets/images/close-square-8.png';
import moment from 'moment';
import List2 from '../../assets/images/list1.png';
import './Graph.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import SecondaryButton from '../Buttons/SecondaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import { Stack } from '@mui/material';

function Graph(props) {

  const { fetchTransactionByVolumeLoading, fetchTransactionByVolume, section, handleFilterClick, globalSelectedFil, setSelectedFilterGraph, selectedFilterGraph } = props;

  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const options = [
    { value: 'current_month', label: t('dashboard.current_month') },
    { value: 'current_year', label: t('dashboard.current_year') },
    { value: 'last_3_year', label: t('dashboard.last_3_years') },
    { value: 'custom', label: t('dashboard.custom') },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const setLoader = () => {
    setIsLoading(true);

    const timerId = setTimeout(() => {
      setIsLoading(false);
    }, 5000);

    return () => clearTimeout(timerId);
  };

  const schema = yup.object().shape({
    startDate: yup
      .string()
      .required('Start Date is required'),
    endDate: yup
      .string()
      .required('End Date is required')
      .test('is-valid-end-date', 'End date should not be before start date', function (value) {
        const startDate = this.resolve(yup.ref('startDate'));
        return !startDate || !value || value >= startDate;
      }),
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const handleClose = () => setShow(false);

  const handleCloseMenu = (selectedOption) => {
    if (selectedOption) {
      const { value, label } = selectedOption;
      setSelectedFilterGraph(label);

      if (value === 'custom') {
        setShow(true);
      } else {
        handleFilterClick(section, value, {}, true);
      }
    }
  };


  const onSubmit = (data) => {
    setLoader();
    setSelectedFilterGraph(`${moment(data?.startDate).format('DD/MM/YYYY')} - ${moment(data?.endDate).format('DD/MM/YYYY')}`);
    handleFilterClick(section, 'custom', data, true);
    setShow(false);
  };

  useEffect(() => {
    // Access the canvas element using a ref after the component has mounted.
    const ctx = document.getElementById('myChart').getContext('2d');

    // Check if a chart instance already exists
    const existingChart = Chart.getChart(ctx);

    // Define gradient colors
    const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
    gradientStroke.addColorStop(0, 'rgba(0, 211,50, 1)');
    gradientStroke.addColorStop(1, 'rgba(0, 211,50, 1)');

    const gradientFill = ctx.createLinearGradient(0, 0, 0, 200);
    gradientFill.addColorStop(0, 'rgba(0, 211,50, 0.5)');
    gradientFill.addColorStop(1, 'rgba(0, 211,50, 0.05)');

    // Create the chart
    if (existingChart) {
      existingChart.destroy();
    }
    const XAxis = fetchTransactionByVolume?.map((obj) => obj.x_axis)?.reverse();
    const YAxis = fetchTransactionByVolume?.map((obj) => obj.y_axis)?.reverse();

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: XAxis,
        datasets: [
          {
            borderColor: gradientStroke,
            pointBorderColor: gradientStroke,
            pointBackgroundColor: gradientStroke,
            pointHoverBackgroundColor: gradientStroke,
            pointHoverBorderColor: gradientStroke,
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBorderWidth: 1,
            pointRadius: 0,
            fill: true,
            backgroundColor: gradientFill,
            borderWidth: 2,
            data: YAxis,
            tension: 0.2
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
              beginAtZero: true,
              maxTicksLimit: 5,
              padding: 20,
            },
            grid: {
              zeroLineColor: 'transparent',
            },
            title: {
              display: true,
              text: `${t('dashboard.transactions')}`,
              padding: 0,
            }
          },
          x: {
            grid: {
              display: false,
            },
            ticks: {
              padding: 20,
              fontColor: 'rgba(0,0,0,0.5)',
              fontStyle: 'bold',
            },
            title: {
              display: true,
              text: `${t('teams.date')}`,
              padding: 0,
            }
          },
        },
      },
    });
  }, [fetchTransactionByVolume]);

  return (
    <div className="p-3 cards-custom position-relative GraphCustomCss" style={{height: '100%'}}>
      {fetchTransactionByVolumeLoading && <div className="dashboardloader-container" style={{ height: '100%', width: '100%', left: 0, top: 0 }}>
        <div className="spinner-border text-light" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>}

      <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
        <div className='d-flex align-items-center'>
          <img src={List2} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
          <h2 className='header-text mb-0'>{t('dashboard.transactions')}{t('dashboard.by_amount')}</h2>
        </div>
        <Dropdown>
          <Dropdown.Toggle
            id="custom-dropdown"
            className="custom-dropdown-toggle btn-back custom-dashboard-button"
          >
            {selectedFilterGraph ? selectedFilterGraph : globalSelectedFil}
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            {options.map((option, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => handleCloseMenu(option)}
                className="custom-dropdown-item"
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Stack>

      <hr />
      <div className='graph-dashboard'>
        <canvas id="myChart"></canvas>
      </div>
      <Modal className='TaxcodeModalOne' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-start green-header p-2'>
              <div className='col-9'>
                <p className='text-green modal-title' style={{ fontSize: 18 }}>
                  Select Custom Date
                </p>
              </div>
              <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
              </div>
            </div>
            <div className="form-outline my-3">
              <label className="form-label" htmlFor="startDate">
                Start Date
              </label>

              <Controller
                name="startDate"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    type="date"
                    id="startDate"
                    className="form-control form-control-lg"
                    placeholder="Start Date"
                    autoComplete="off"
                    {...field}
                  />
                )}
              />
              {errors?.startDate && (
                <div style={{ color: 'red' }}>{errors?.startDate?.message}</div>
              )}
            </div>
            <div className="form-outline my-3">
              <label className="form-label" htmlFor="endDate">
                End Date
              </label>

              <Controller
                name="endDate"
                control={control}
                defaultValue=''
                render={({ field }) => (
                  <input
                    type="date"
                    id="endDate"
                    className="form-control form-control-lg"
                    placeholder="Start Date"
                    autoComplete="off"
                    {...field}
                  />
                )}
              />
              {errors?.endDate && (
                <div style={{ color: 'red' }}>{errors?.endDate?.message}</div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <SecondaryButton
              type={'button'}
              onClick={handleClose}
              buttonText={t('cancel')}
              id={'cancel_custom_date'}
              style={{ width: '102px' }}
            />
            <PrimaryButton
              type={'submit'}
              buttonText={t('submit')}
              isEnabled={true}
              id={'submit_custom_date'}
              style={{ width: '149px' }}
              isLoading={isLoading}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default Graph;
