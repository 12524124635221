import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Cards.scss";
import { useDispatch, useSelector } from "react-redux";
import { formatTime } from "../../utils/XpendlessUtils";
import { Controller, useForm } from "react-hook-form";
import Delete from "../../assets/images/delete.svg";
import Edit from "../../assets/images/edit.svg";
import LoadingComponent from "../Loader/LoadingComponent";
import { toast } from "react-toastify";
import {
  getMccCategoryDetails,
  updateMcc,
} from "../../pages/Cards/slice/cardListSlice";
import { getCardDetails } from "../../pages/Cards/slice/cardListSlice";
import { useParams } from "react-router-dom";
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";
function SingleCardTabs({
  details,
  spendings,
  fetchCardSpendingFunc,
  handleCardControl,
  activeTab,
  setActiveTab,
  isLoading,
}) {
  const [pageNo, setPageNo] = useState(1);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const mccCategory = useSelector((state) => state?.cardListReducer);
  const [categories, setCategories] = useState([]);
  const { control } = useForm();
  const { employeeId } = useParams();
  const [checkboxValues, setCheckboxValues] = useState({
    cardcontroldata: {
      allowAtm: false,
      allowPos: false,
      allowChip: false,
      allowWithoutPin: false,
      allowEcommerceNon: false,
      allowEcommerce: false,
      allowCvv: false,
      allowQuasiCash: false,
      allowCountless: false,
    },
    cardcategorycontroldata: {
      gasFuel: false,
      hotels: false,
      travels: false,
    },
  });
  const dispatch = useDispatch();
  useEffect(() => {
    let queryParams = `?page=${pageNo}&cardId=${details?.cardId}`;
    dispatch(fetchCardSpendingFunc(queryParams));
  }, [dispatch, pageNo]);

  const onPreviousClick = () => {
    setPageNo((prev) => prev - 1);
  };

  const onNextClick = () => {
    setPageNo((prev) => prev + 1);
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    dispatch(getMccCategoryDetails());
  }, []);

  const handleAddCategory = () => {
    setCategories([...categories, { id: "", value: "" }]);
  };

  const handleCategoryChange = (index, event) => {
    const category = mccCategory?.mccCategory?.find(
      (category) => category.id === parseInt(event.target.value)
    );
    if (category) {
      const updatedCategories = [...categories];
      updatedCategories[index] = {
        id: category.id,
        value: category.category,
      };
      setCategories(updatedCategories);
    }
  };

  const handleDeleteCategory = (index) => {
    const updatedCategories = categories.filter((_, i) => i !== index);
    setCategories(updatedCategories);
  };

  const handleSave = () => {
    const payload = {
      type: selectedOption.charAt(0).toUpperCase() + selectedOption.slice(1),
      ids: categories.map((category) => category.id),
      cardId: details?.cardId,
    };

    dispatch(updateMcc(payload))
      .unwrap()
      .then((data) => {
        toast.success(data.message || "Updated Successfully");
        dispatch(getCardDetails(employeeId));
      })
      .catch((error) => {
        toast.error(error.message || "Request Failed Please Try Again");
      });
  };

  useEffect(() => {
    if (details) {
      setCheckboxValues({
        cardcontroldata: {
          allowAtm: details?.cardcontroldata?.allowAtm || false,
          allowPos: details?.cardcontroldata?.allowPos || false,
          allowChip: details?.cardcontroldata?.allowChip || false,
          allowWithoutPin: details?.cardcontroldata?.allowWithoutPin || false,
          allowEcommerceNon:
            details?.cardcontroldata?.allowEcommerceNon || false,
          allowEcommerce: details?.cardcontroldata?.allowEcommerce || false,
          allowCvv: details?.cardcontroldata?.allowCvv || false,
          allowQuasiCash: details?.cardcontroldata?.allowQuasiCash || false,
          allowCountless: details?.cardcontroldata?.allowCountless || false,
        },
        cardcategorycontroldata: {
          gasFuel: details?.cardcategorycontroldata?.gasFuel || false,
          hotels: details?.cardcategorycontroldata?.hotels || false,
          travels: details?.cardcategorycontroldata?.travels || false,
        },
      });
      setSelectedOption(details?.cardcontroldata?.categoryType?.toLowerCase());
      const mccCategoryIds = (details?.cardcontroldata?.mccCategoryIds || [])
        .map((categoryId) => {
          const category = mccCategory?.mccCategory?.find(
            (cat) => cat.id === categoryId
          );
          return {
            id: categoryId,
            value: category ? category.category : "",
          };
        })
        .filter((cat) => cat.value);
      setCategories(mccCategoryIds);
    }
  }, [details]);

  const status = details?.status;
  const isStatusInactiveOrSuspended = status === "inactive" || status === "suspended";
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const [category, key] = name.split(".");
    setCheckboxValues((prevCheckboxValues) => ({
      ...prevCheckboxValues,
      [category]: {
        ...prevCheckboxValues[category],
        [key]: checked,
      },
    }));
    setTriggerEffect(true);
  };

  useEffect(() => {
    if (triggerEffect) {
      setTriggerEffect(false);
      handleCardControl(checkboxValues);
    }
  }, [checkboxValues]);

  const { t } = useTranslation();
  return (
    <div className="cards-custom p-3 mt-4">
      <div className="cardTabs settings">
        <Tabs
          defaultActiveKey={activeTab}
          animation={"false"}
          id="card-tabs"
          onSelect={(eventKey) => {
            setActiveTab(eventKey);
          }}
          style={{
            filter: isStatusInactiveOrSuspended ? "grayscale(2)" : "none",
            cursor: isStatusInactiveOrSuspended ? "not-allowed" : "pointer",
          }}
        >
          <Tab
            eventKey={1}
            title={`${t("cards.card_controls")}`}
            id="card_controls"
          >
            <div
              className="border-grey p-3 mt-4"
              style={{
                position: "relative",
                filter: isStatusInactiveOrSuspended ? "grayscale(2)" : "none",
                cursor: isStatusInactiveOrSuspended ? "not-allowed" : "pointer",
              }}
            >
              {isLoading && <LoadingComponent />}
              <form>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"allowAtm"}
                    name={"cardcontroldata.allowAtm"}
                    checked={checkboxValues["cardcontroldata"]["allowAtm"]}
                    onChange={handleCheckboxChange}
                    disabled={isStatusInactiveOrSuspended}
                  />

                  <label className="form-check-label" htmlFor="allowAtm">
                    {t("cards.allow_atm")}
                  </label>
                </div>
                <div className="form-check form-switch mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"allowPos"}
                    name={"cardcontroldata.allowPos"}
                    checked={checkboxValues["cardcontroldata"]["allowPos"]}
                    onChange={handleCheckboxChange}
                    disabled={isStatusInactiveOrSuspended}
                  />
                  <label className="form-check-label" htmlFor="allowPos">
                    {t("cards.allow_pos")}
                  </label>
                </div>
                <div className="form-check form-switch mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"allowChip"}
                    name={"cardcontroldata.allowChip"}
                    checked={checkboxValues["cardcontroldata"]["allowChip"]}
                    onChange={handleCheckboxChange}
                    disabled={isStatusInactiveOrSuspended}
                  />
                  <label className="form-check-label" htmlFor="allowChip">
                    {t("cards.allow_chip")}
                  </label>
                </div>
                <div className="form-check form-switch mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"allowWithoutPin"}
                    name={"cardcontroldata.allowWithoutPin"}
                    checked={
                      checkboxValues["cardcontroldata"]["allowWithoutPin"]
                    }
                    onChange={handleCheckboxChange}
                    disabled={isStatusInactiveOrSuspended}
                  />
                  <label className="form-check-label" htmlFor="allowWithoutPin">
                    {t("cards.allow_without_pin")}
                  </label>
                </div>
                <div className="form-check form-switch mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"allowEcommerceNon"}
                    name={"cardcontroldata.allowEcommerceNon"}
                    checked={
                      checkboxValues["cardcontroldata"]["allowEcommerceNon"]
                    }
                    onChange={handleCheckboxChange}
                    disabled={isStatusInactiveOrSuspended}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="allowEcommerceNon"
                  >
                    {t("cards.allow_ecommerce_non_3DS")}
                  </label>
                </div>
                <div className="form-check form-switch mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"allowEcommerce"}
                    name={"cardcontroldata.allowEcommerce"}
                    checked={
                      checkboxValues["cardcontroldata"]["allowEcommerce"]
                    }
                    onChange={handleCheckboxChange}
                    disabled={isStatusInactiveOrSuspended}
                  />
                  <label className="form-check-label" htmlFor="allowEcommerce">
                    {t("cards.allow_ecommerce_3DS")}
                  </label>
                </div>
                <div className="form-check form-switch mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"allowCvv"}
                    name={"cardcontroldata.allowCvv"}
                    checked={checkboxValues["cardcontroldata"]["allowCvv"]}
                    onChange={handleCheckboxChange}
                    disabled={isStatusInactiveOrSuspended}
                  />
                  <label className="form-check-label" htmlFor="allowCvv">
                    {t("cards.allow_ecommerce_without_CVV")}
                  </label>
                </div>
                <div className="form-check form-switch mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"allowQuasiCash"}
                    name={"cardcontroldata.allowQuasiCash"}
                    checked={
                      checkboxValues["cardcontroldata"]["allowQuasiCash"]
                    }
                    onChange={handleCheckboxChange}
                    disabled={isStatusInactiveOrSuspended}
                  />
                  <label className="form-check-label" htmlFor="allowQuasiCash">
                    {t("cards.allow_quasi_cash")}
                  </label>
                </div>
                <div className="form-check form-switch mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={"allowCountless"}
                    name={"cardcontroldata.allowCountless"}
                    checked={
                      checkboxValues["cardcontroldata"]["allowCountless"]
                    }
                    onChange={handleCheckboxChange}
                    disabled={isStatusInactiveOrSuspended}
                  />
                  <label className="form-check-label" htmlFor="allowCountless">
                    {t("cards.allow_contactless")}
                  </label>
                </div>
              </form>
            </div>
          </Tab>
          <Tab
            eventKey={2}
            title={`${t("cards.category_control")}`}
            id="category_control"
          >
            <div
              className="border-grey p-3 mt-4"
              style={{
                filter: isStatusInactiveOrSuspended ? "grayscale(2)" : "none",
                cursor: isStatusInactiveOrSuspended ? "not-allowed" : "pointer",
              }}
            >
              <div>
                <div className="form-outline mt-4 mb-3">
                  <label className="form-labels" htmlFor="selectCategory">
                    {t("cards.category_restriction")}
                  </label>
                  <Controller
                    name="categoryRestriction"
                    id="selectCategory"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        id="selectCategory"
                        className="form-select"
                        style={{ width: "32%" }}
                        value={selectedOption}
                        disabled={isStatusInactiveOrSuspended}
                        onChange={(e) => {
                          handleSelectChange(e);
                          field.onChange(e);
                        }}
                      >
                        <option value="">Select an option</option>
                        <option value="allow">Allow Category</option>
                        <option value="block">Block Category</option>
                        <option value="none">None</option>
                      </select>
                    )}
                  />
                </div>

                {["allow", "block"].includes(selectedOption) && (
                  <>
                    <div className=" ">
                      {categories.length === 0 && (
                        <SecondaryButton
                          type={'button'}
                          onClick={handleAddCategory}
                          buttonText={t("cards.add_category")}
                          isEnabled={!isStatusInactiveOrSuspended}
                          id={'addCategory'}
                          style={{ marginBlock: '8px', width: '375px', textAlign: 'center' }}
                          isHighlighted={true}
                        />
                      )}
                    </div>
                    <div className="row" style={{ position: "relative" }}>
                      {categories.map((category, index) => (
                        <div
                          key={index}
                          className="col-4 d-flex justify-content-between align-items-center form-outline mt-2"
                        >
                          {category.id ? (
                            <div
                              className="form-control-lg d-flex justify-content-between align-items-center w-100"
                              style={{
                                backgroundColor: "#f8f9fa",
                                padding: "10px",
                                borderRadius: "8px",
                              }}
                            >
                              <span>{category.value}</span>
                              <button
                                type="button"
                                className="btn btn-link text-danger"
                                onClick={() => handleDeleteCategory(index)}
                                disabled={isStatusInactiveOrSuspended}
                              >
                                <img src={Delete} />
                              </button>
                            </div>
                          ) : (
                            <select
                              id={`category-${index}`}
                              className="form-control form-select form-control-lg text-capitalize"
                              value={category.id}
                              onChange={(event) => {
                                handleCategoryChange(index, event);
                              }}
                            >
                              <option value="">Select Category</option>
                              {mccCategory?.mccCategory?.map((categoryItem) => (
                                <option
                                  key={categoryItem.id}
                                  value={categoryItem.id}
                                  name={categoryItem.category}
                                >
                                  {categoryItem.category}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="my-3">
                      {categories.length > 0 && (
                        <SecondaryButton
                          type={'button'}
                          onClick={handleAddCategory}
                          buttonText={t("cards.add_another")}
                          isEnabled={!isStatusInactiveOrSuspended}
                          id={'addAnotherCategory'}
                          style={{ marginBlock: '8px', width: '400px', textAlign: 'center' }}
                          isHighlighted={true}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
              <div>
                {selectedOption && (
                  <PrimaryButton
                    type={'button'}
                    onClick={handleSave}
                    buttonText={t("save")}
                    isEnabled={true}
                    id={'submit_update_card'}
                    isLoading={isLoading}
                    style={{ width: '85px' }}
                  />
                )}
              </div>
            </div>
          </Tab>
          <Tab
            eventKey={3}
            title={`${t("cards.other_limits")}`}
            id="other_limits"
          >
            <div
              className="table-responsive mt-4 p-3"
              style={{
                filter: isStatusInactiveOrSuspended ? "grayscale(2)" : "none",
                cursor: isStatusInactiveOrSuspended ? "not-allowed" : "pointer",
              }}
            >
              <div className="col-lg-3 col-md-12 form-outline">
                <label className="form-label" htmlFor="card_balance_limit">
                  {t("cards.card_balance_limit")}
                </label>
                <div
                  className="d-flex align-items-center"
                  style={{ position: "relative" }}
                >
                  {isLoading && <LoadingComponent />}
                  <Controller
                    name="employeeName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        value={details?.limit}
                        disabled={isStatusInactiveOrSuspended}
                        id="employeeName"
                        className="form-control form-control-lg input1"
                      />
                    )}
                  />

                  <img src={Edit} className="ms-4" />
                </div>
              </div>
              <div
                className="col-lg-3 col-md-12 form-outline mt-3"
                style={{ position: "relative" }}
              >
                <label
                  className="form-label"
                  htmlFor="transaction_amount_limit "
                >
                  {t("cards.transaction_amount_limit")}
                </label>
                {isLoading && <LoadingComponent />}
                <div className="d-flex align-items-center">
                  <Controller
                    name="employeeName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        value={details?.transactionAmountLimit}
                        disabled={isStatusInactiveOrSuspended}
                        id="employeeName"
                        className="form-control form-control-lg input1"
                      />
                    )}
                  />

                  <img src={Edit} className="ms-4" />
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey={4} title={`${t("cards.spending")}`} id="spendings">
            <div
              className="table-responsive mt-4 p-3"
              style={{ position: "relative" }}
            >
              {isLoading && <LoadingComponent />}
              <table
                id="cardsSpending"
                style={{ borderCollapse: "separate", borderSpacing: "0 16px" }}
                cellSpacing="0"
                width="100%"
              >
                <thead>
                  <tr className="table-head">
                    <th scope="col">{t("cards.no")}</th>
                    <th scope="col">{t("cards.date_and_time")}</th>
                    <th scope="col">{t("teams.description")}</th>
                    <th scope="col">{t("teams.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(spendings).length > 0 &&
                    Object.keys(spendings["rows"]).length > 0 &&
                    spendings["rows"].map((spending, index) => {
                      return (
                        <tr key={index} className="table-body-tr">
                          <td>{index + 1}</td>
                          <td>{formatTime(spending.createdAt)}</td>
                          <td>{spending.description}</td>
                          <td>{spending.amount.toLocaleString()} QAR</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ width: "100%", borderTop: "0px !imporant" }}
            >
              <nav aria-label="Page navigation example">
                <ul className="pagination mt-2 pt-3 ms-6 pe-0">
                  <li className="page-item">
                    <SecondaryButton
                      type={'button'}
                      onClick={() => onPreviousClick()}
                      buttonText={t("previous")}
                      isEnabled={pageNo !== 1}
                      id={'previous'}
                    />
                  </li>
                  <li className="page-item">
                    <SecondaryButton
                      type={'button'}
                      onClick={() => onNextClick()}
                      buttonText={t("next")}
                      isEnabled={!(pageNo == spendings?.totalPages ||
                        spendings?.totalPages == 0)}
                      id={'next'}
                      style={{marginInline: '16px'}}
                    />
                  </li>
                </ul>
              </nav>
              <div
                className="page-count d-flex align-items-center"
                style={{ color: "#00D094" }}
              >
                <h4 className="me-3">
                  Page {spendings?.currentPage} of {spendings?.totalPages}
                </h4>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default SingleCardTabs;
