import { useTranslation } from "react-i18next";
import AccountIcon from '../../assets/images/Accounts-icon.png';
import CardIcon from '../../assets/images/Card-icon.png';
import ReimbursementIcon from '../../assets/images/reimbursement-icon.png';
import ExpenseIcon from '../../assets/images/Expense-icon.png';

export const useInfoSectionProps = () => {
  const { t } = useTranslation();

  const walletContent = [
      t("walkThrough.wallet_content_1"),
      t("walkThrough.wallet_content_2"),
      t("walkThrough.wallet_content_3"),
  ];

  const transactionContent = [
      t("walkThrough.transaction_content_1"),
      t("walkThrough.transaction_content_2"),
  ];

  const reimbursementContent = [
      t("walkThrough.export_content_1"),
      t("walkThrough.export_content_2"),
  ];

  const expenseContent = [
      t("walkThrough.expense_content_1"),
      t("walkThrough.expense_content_2"),
  ];

  return [
      {
          title: t("walkThrough.wallet"),
          content: walletContent,
          iconSrc: AccountIcon,
      },
      {
          title: t("walkThrough.createAndManageCards"),
          content: transactionContent,
          iconSrc: CardIcon,
      },
      {
          title: t("walkThrough.reimbursement"),
          content: reimbursementContent,
          iconSrc: ReimbursementIcon,
      },
      {
          title: t("walkThrough.manageExpense"),
          content: expenseContent,
          iconSrc: ExpenseIcon,
      },
  ];
};