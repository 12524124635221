import React, { useState } from 'react';
import Receipt from "../../assets/images/receipt.png";
import UploadIcon from '../../assets/images/Upload_icon.png';
import { useTranslation } from 'react-i18next';

export default function ReimbursementPage() {
  const selectedIndex = 0;
  const [selectedCategory, setSelectedCategory] = useState("");
  const { t } = useTranslation();

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const items = [
    { label: t('walkThrough.details') },
    { label: t("walkThrough.activities") }
  ];

  return (
    < div style={{marginTop: "50px"}}>
    <div style={{ flex: 1 ,  }} className='left-space'>
        <div
          className="col-lg-8 col-md-12"
          style={{
            border: "1px solid rgba(0, 208, 148, 0.6)",
            borderRadius: 20,
            fontWeight: 600,
            marginTop: "4%"
          }}
        >
          <div className="d-flex justify-content-between" style={{ padding: 5 }}>
            {items.map((item, index) => (
              <div
                key={index}
                className={`reimbursement-box ${selectedIndex === index ? 'selected' : ''}`}
                style={{cursor: 'auto'}}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>

      {/* Reimbursement Details */}
      <div className="export-dropdown mt-4">
        <div className="col-lg-8 col-md-12 mt-2 p-2 form-box">
          <div className="d-flex justify-content-between text-white mb-3" style={{ padding: 5 }}>
            <div style={{fontSize: "18px"}}>
              {t("walkThrough.reimbursement_amount")}
              <span className='mt-5' style={{ fontSize: "12px", color: "white", opacity: 0.5 }}>
                {t("walkThrough.reimbursement_currency")}
              </span>
            </div>
            <div>{t("walkThrough.username")}</div>
          </div>

          <div className="d-flex justify-content-between text-white opacity-80" style={{ padding: 5, opacity: 0.5, fontSize: "14px" }}>
            <div>{t("walkThrough.date")}</div>
            <div>{t("walkThrough.time")}</div>
          </div>
        </div>
      </div>

      {/* Employee Info */}
      <div className="export-dropdown mt-4">
        <div className="col-lg-8 col-md-12 mt-2 p-2 form-box" style={{ fontWeight: 500 }}>
          <div className="d-flex justify-content-between form-section text-white mb-2" style={{ padding: 5 }}>
            <div>{t("walkThrough.name")}</div>
            <div style={{ fontWeight: 300 }}>{t("walkThrough.company_name")}</div>
          </div>

          <div className="d-flex justify-content-between text-white gap-2 form-section mb-2" style={{ padding: 5 }}>
            <div>{t("walkThrough.department")}</div>
            <div className='department-name'>{t("walkThrough.department_name")}</div>
          </div>

          <div className="d-flex flex-column gap-3 text-white" style={{ padding: 5 }}>
            <div className="d-flex align-items-center form-section gap-2">
              <div className="flex-shrink-0 form-section" style={{ width: "40%" }}>
                {t("walkThrough.category_label")}
              </div>
              <div className="flex-grow-1">
                <select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  className="category-select form-section"
                  disabled={true}
                >
                  <option value="">{t("walkThrough.category_select")}</option>
                  <option value="travel">{t("walkThrough.travel")}</option>
                  <option value="food">{t("walkThrough.food")}</option>
                  <option value="office-supplies">{t("walkThrough.office_supplies")}</option>
                </select>
              </div>
            </div>

            <div className="d-flex align-items-center gap-2" style={{ color: "white" }}>
              <div className="flex-shrink-0 form-section gap-2" style={{ width: "40%", color: "white" }}>
                {t("walkThrough.description")}
              </div>
              <div className="flex-grow-1" style={{ color: "white" }}>
                <input
                  type="text"
                  placeholder={t("walkThrough.description")}
                  className="description-input"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Receipt Upload */}
      <div className="upload-container col-lg-8 col-md-12 mt-4">
        <div className="dotted-box ">
          <img className="receipt-img" src={Receipt} alt="Receipt" />
          <div className="upload-info form-section mt-2">
            <div className='form-section' style={{ fontWeight: 500 }}>{t("walkThrough.upload_title")}</div>
            <div className="receipt-text form-section">{t("walkThrough.upload_new_receipt")}</div>
            <div style={{ fontWeight: 400, fontSize: "13px" }}>
              {t("walkThrough.upload_info")}
            </div>
          </div>
          <div className="upload-icon mt-2">
            <img src={UploadIcon} width="23px" height="23px" alt="Upload Icon" />
          </div>
        </div>
      </div>
    </div></div>
  );
}