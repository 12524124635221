import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Export.scss';
import moment from 'moment';
import ExpensesComponent from '../Expenses/ExpensesComponent';
import LoadingComponent from '../Loader/LoadingComponent';
import notFound from '../../assets/images/not_found.png';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

function Exported(props) {
    const { exportedData, isLoading, handleOnExportClick, onPreviousClick, currencies, onNextClick, pageNo, showExpenseDetailModal, type, type1, setChildFunctionRef } = props;
    const { t } = useTranslation();
    const [csvType] = useState('csv');
    const [selectedItems, setSelectedItems] = useState([]);
    const [checkedAllPages, setCheckedAllPages] = useState(false);
    const [pseudoCheckedAll, setPseudoCheckedAll] = useState(false);
    const [uncheckedTransaction, setUncheckedTransaction] = useState([]);

    useEffect(() => {
        setChildFunctionRef(resetAll);
        if (exportedData?.rows?.length > 0) {
            if (pseudoCheckedAll) {
                const selected = [...selectedItems];

                for (const row of exportedData.rows) {
                    if (row?.records?.length > 0) {
                        for (const record of row.records) {
                            if (!uncheckedTransaction?.includes(record?.cardTransactionId) && !selected.some(element => element?.transactionId == record?.cardTransactionId))
                                selected.push({
                                    transactionId: record?.cardTransactionId,
                                    transactionType: record?.type
                                });
                        }
                    }
                }
                setSelectedItems(selected);
            }
        }
    }, [exportedData]);

    useEffect(() => {
        if (type) {
            setSelectedItems([]);
            setCheckedAllPages(false);
            setPseudoCheckedAll(false);
            setUncheckedTransaction([]);
        }
    }, [type]);

    const resetAll = () => {
        setSelectedItems([]);
        setCheckedAllPages(false);
        setPseudoCheckedAll(false);
        setUncheckedTransaction([]);
    };

    const handleCheckboxChange = (id, type) => {
        const selected = [...selectedItems];
        const unchecked = [...uncheckedTransaction];
        const index = selected.findIndex(
            (item) => item.transactionId == id && item.transactionType == type
        );

        if (index === -1) {
            if (pseudoCheckedAll) {
                const uncheckedIndex = unchecked.findIndex((trans) => trans == id);
                unchecked.splice(uncheckedIndex, 1);
                setUncheckedTransaction(unchecked);
            }
            selected.push({ transactionId: id, transactionType: type });
        } else {
            if (pseudoCheckedAll) {
                unchecked.push(id);
                setUncheckedTransaction(unchecked);
            }
            selected.splice(index, 1);
        }
        if (checkedAllPages)
            setCheckedAllPages(false);
        setSelectedItems(selected);
    };

    const handleCheckAllPages = () => {
        if (checkedAllPages) {
            setSelectedItems([]);
            setCheckedAllPages(false);
            setPseudoCheckedAll(false);
            setUncheckedTransaction([]);
        } else {
            let allRecords = [];
            exportedData?.rows?.map((element) =>
                element?.records.map((record) => allRecords.push({
                    transactionId: record?.cardTransactionId,
                    transactionType: record?.type
                }))
            );
            setSelectedItems(allRecords);
            setCheckedAllPages(true);
            setPseudoCheckedAll(true);
            setUncheckedTransaction([]);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <div className='d-flex align-items-center justify-content-between mt-5'>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value=""
                        id="checkAllExported"
                        checked={checkedAllPages}
                        onChange={handleCheckAllPages} />
                    <label className="form-check-label" htmlFor="checkAllExported">
                        {t('export.select_all')}
                    </label>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                    <PrimaryButton
                        type={'button'}
                        onClick={() => handleOnExportClick(pseudoCheckedAll, csvType, selectedItems, uncheckedTransaction)}
                        buttonText={t('export.export')}
                        isEnabled={selectedItems.length >= 1}
                        id={'export_button'}
                        isExportBtn={true}
                        isLoading={isLoading}
                    />
                </div>
            </div>
            {(isLoading || !exportedData?.rows) && <LoadingComponent />}
            {
                (exportedData?.rows && exportedData?.rows?.length > 0)
                    ? (exportedData['rows']?.map((item, i) => {
                        return (
                            <div key={i} className='export-border mt-4'>
                                <h2 className='text-grey'>{moment(item?.date).format('DD MMM YYYY')}</h2>
                                <ExpensesComponent
                                    index={`exported_${i}`}
                                    data={item?.records}
                                    showCheckBox={true}
                                    currencies={currencies}
                                    showExpenseDetailModal={showExpenseDetailModal}
                                    handleCheckboxChange={handleCheckboxChange}
                                    selectedItems={selectedItems}
                                    type={type}
                                    type1={type1}
                                    prevPath={'/export'}
                                    activeTap={'exported'}
                                />
                            </div>);
                    })) : (
                        <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                            <img src={notFound} width={200} height={200} />
                        </div>
                    )
            }
            <div className='d-flex align-items-center justify-content-between mt-2'
                style={{ width: "100%", borderTop: '0px !imporant' }}>
                <nav aria-label="Page navigation example">
                    <ul className="pagination pt-1 mt-3  pe-0 ">
                        <li className="page-item ">

                            <SecondaryButton
                                type={'button'}
                                onClick={() => onPreviousClick()}
                                buttonText={t("previous")}
                                isEnabled={pageNo !== 1}
                                id={'previous'}
                            />
                        </li>
                        <li className="page-item">
                            <SecondaryButton
                                type={'button'}
                                onClick={() => onNextClick()}
                                buttonText={t("next")}
                                isEnabled={!(pageNo == exportedData?.totalPages || exportedData?.totalPages == 0)}
                                id={'next'}
                            />
                        </li>
                    </ul>
                </nav>
                <div className='page-count d-flex align-items-center green-primary'>
                    <h4 className='me-3'>Page {exportedData?.currentPage} of {exportedData?.totalPages || 1}</h4>
                </div>
            </div>
        </div>
    );
}

export default Exported;
