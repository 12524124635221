import React, { useEffect, useState } from 'react';
import './Login.scss';
import LoginLeftImage from '../../assets/images/login_left_image.png';
import ArrowLeftImage from '../../assets/images/arrow-left.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import OtpInput from 'react-otp-input';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { verifyAndSendOtp, verifyLoginOtp } from '../Login/loginSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { setUserLoggedInData, updateRoutes } from '../Users/userSlice';
import Logo2 from '../../assets/images/polo.png';
import axios from 'axios';
import LoadingComponent from '../../components/Loader/LoadingComponent';
import PrimaryButton from '../../components/Buttons/PrimaryButton';

function OtpVerification() {
    const { state } = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const date = new Date().getFullYear();
    const schema = yup.object().shape({
        otp: yup.string().required("Otp is required").matches(/^\d{6}$/, 'OTP must be exactly 6 digits'),
    });
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const { isLoading } = useSelector(state => state.loginSliceReducer);
    const [timer, setTimer] = useState(60);
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    let navigate = useNavigate();
    const [ipAddress, setIpAddress] = useState('');

    const token = localStorage.getItem('token');
    const hasAccess = localStorage.getItem('hasAccess');

    useEffect(() => {
        if (!state?.LoginData) {
            if (token) {
                if (hasAccess === 'true')
                    navigate('/companyverification');
                else
                    navigate('/expenses');
            } else
                navigate("/login");
        } else {
            setIsLoadingPage(false);
        }
    }, []);

    useEffect(() => {
        getIpAddress();
    }, []);

    const getIpAddress = async () => {
        try {
            const response = await axios.get('https://api.ipify.org?format=json');
            setIpAddress(response.data.ip);
            return response.data.ip;
        } catch (error) {
            console.error('Error fetching IP address:', error);
        }
    };

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer]);

    const handleResendClick = () => {
        const newData = { email: state?.LoginData?.email, password: state?.LoginData?.password };
        dispatch(verifyAndSendOtp(newData))
            .unwrap()
            .then(() => {
                toast.success('OTP Sent');
                setTimer(60);
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    const routeLoginChange = () => {
        let path = `/login`;
        navigate(path);
    };

    const onSubmit = async (data) => {
        const requestData = {
            email: state?.LoginData?.email,
            ...data,
            ipAddress: ipAddress ?? await getIpAddress()
        };
        dispatch(verifyLoginOtp(requestData))
            .unwrap()
            .then((res) => {
                if (state?.rememberme) localStorage.setItem('isRemebered', state?.rememberme);
                else localStorage.removeItem('isRemebered');
                toast.success(res?.message);
                dispatch(setUserLoggedInData(res?.data));
                dispatch(updateRoutes());
                reset();
                if (res?.data?.hasAccess)
                    navigate('/companyverification');
                else
                    navigate('/expenses');
            }).catch((error) => {
                toast.error(error?.message);
            });
    };

    if (isLoadingPage) {
        return <Loader />;
    }

    return (
        <section style={{ backgroundColor: '#24473D', position: 'relative', height: '100%' }}>
            <div className="container-fluid">
                <div className="row login-forms" >
                    <div className="col-md-7 col-lg-5 col-xl-6 my-2 soop" style={{ position: 'relative' }}>
                        {isLoading && <LoadingComponent />}
                        <div className='d-flex flex-column justify-content-between align-items-center h-100'>
                            <div></div>
                            <form className='form' onSubmit={handleSubmit(onSubmit)} >
                                <div>
                                    <img src={Logo2} className="logo mt-1 mb-4" alt="Sample image" />
                                </div>

                                <div onClick={routeLoginChange} className="divider d-flex align-items-center justify-content-start  mb-3 pt-2" style={{ border: '1px solid #8B9299', borderRadius: '8px', width: 'fit-content', padding: '8px', cursor: 'pointer' }}>
                                    <img src={ArrowLeftImage} className="arrowLeftLogo" alt="Sample image" />
                                    <p className="text-center mb-0 text-or"><a href='#!' className='form-label forgotPassword' id='back'>{t('login.back')}</a>
                                    </p>
                                </div>

                                <h1 className='text-blue-login my-2'>
                                    {t('login.otp_verification')}
                                </h1>

                                <div className="divider d-flex align-items-center my-2">
                                    <p style={{ width: '320px' }} className="mb-0 text-subtitle">
                                        {t('login.otp_verification_desc')}
                                    </p>
                                </div>

                                <div className="otp form-outline d-flex justify-content-center mt-4 mb-3">
                                    <Controller
                                        name="otp"
                                        control={control}
                                        defaultValue={0}
                                        render={({ field: { onChange, value, ref }, }) => (
                                            <OtpInput
                                                inputStyle="inputStyle"
                                                shouldAutoFocus={true}
                                                ref={ref}
                                                value={value}
                                                onChange={onChange}
                                                numInputs={6}
                                                renderInput={(props) => <input {...props} id='input_otp' onInput={(e) => {
                                                    const value = e.target.value;
                                                    if (!/^\d*$/.test(value)) {
                                                        e.target.value = value.slice(0, -1);
                                                    }
                                                }} />}
                                            />
                                        )}
                                    />

                                </div>
                                {errors?.otp && (
                                    <p className="text-danger text-center">{errors?.otp?.message}</p>
                                )}
                                <div className="d-flex justify-content-end">
                                    {timer === 0 ? (
                                        <a href="#" onClick={handleResendClick} id='resend_otp'>Resend OTP</a>
                                    ) : (
                                        <p>Resend OTP in <span className='text-blue'>{timer} seconds</span> </p>
                                    )}
                                </div>
                                <div className="text-center text-lg-start mt-4 w-100">
                                    <PrimaryButton
                                        type={'submit'}
                                        buttonText={t('login.login')}
                                        isEnabled={true}
                                        id={'login'}
                                        isLoading={isLoading}
                                    />
                                </div>

                            </form>

                            <div className='copyright'>
                                <p className='copyright-text'>{`© Xpendless ${date}`}</p>
                            </div>

                        </div>
                    </div>
                    <div className="col-md-8 col-lg-7 col-xl-6 h-100 d-flex justify-content-center align-items-center imagecontainer">
                        <img src={LoginLeftImage} className='loginImage' />
                        <img className='login_bottom_image' src={login_bottom_image} />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OtpVerification;
