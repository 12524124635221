import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
  isLoading: false,
  isBalanceLoading: false,
  isExpenseDetailLoading: false,
  isReceiptLoading: false,
  isExpenseExportLoading: false,
  isCardLoading: false,
  error: null,
  avaliableBalanceDetails: null,
  expenseList: [],
  cardList: [],
  expenseDetails: null,
  expenseActivityLogs: [],
  categoryList: [],
  tags: [],
  companyAllExpenses: [],
  employeeCardList: [],
  currencies: [],
  filters: null,
  expenseAllCorporateCardList: [],
  expenseCardCorporateList: [],
};


export const getAvaliableBalance = createAsyncThunk("expense/fetchAvaliableBalance", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchAvaliableBalance + queryParams
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const getEmployeeExpenseList = createAsyncThunk("expense/fetchEmployeeExpenses", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchEmployeeExpenses + queryParams
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const getEmployeeExpenseListBySearch = createAsyncThunk("expense/getEmployeeExpenseListBySearch", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchEmployeeExpenses + queryParams
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const getExpensesForApproval = createAsyncThunk("expense/fetchExpensesForApproval", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchExpensesForApproval + queryParams
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const getEmployeeCardList = createAsyncThunk("card/getEmployeeCardList", async () => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.getEmployeeCardList
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const addCardTopUp = createAsyncThunk("card/addCardTopUpRequest", async (postData) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.addCardTopUpRequest,
      data: postData
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});


export const approveRejectExpense = createAsyncThunk("card/approveRejectExpense", async (postData) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.approveRejectExpense,
      data: postData
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const getExpenseDetails = createAsyncThunk("expense/fetchExpenseDetails", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchExpenseDetails + queryParams
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const fetchExpenseActivityLog = createAsyncThunk("expense/fetchExpenseActivityLog", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchExpenseActivityLog + queryParams
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const updateCardTransaction = createAsyncThunk("expense/updateCardTransaction", async (postData) => {
  try {
    let data = {
      method: METHOD_TYPE.put,
      url: API_ENDPOINTS.updateCardTransaction,
      data: postData
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const getCategoryList = createAsyncThunk("expense/fetchCategory", async () => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchCategory
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const uploadTransactionReceipt = createAsyncThunk("expense/uploadTransactionReceipt", async ({ requestData, cardTransactionId }) => {
  try {
    const formData = new FormData();
    formData.append('file', requestData);
    let queryparam = '?cardTransactionId=' + cardTransactionId;
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.uploadTransactionReceipt + queryparam,
      headers: { "Content-Type": 'multipart/form-data' },
      data: formData,
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const simulateTransaction = async (postData) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.simulateTransaction,
      data: postData
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
};

export const getAllTagByGroup = createAsyncThunk("card/getAllTagByGroup", async (postData) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.getAllTagByGroup,
      data: postData
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const saveTags = createAsyncThunk("/setting/saveTags", async ({ postData, cardTransactionId }, /*{ getState }*/) => {
  const token = localStorage.getItem('token');

  try {
    let data = {
      method: METHOD_TYPE.post,
      url: `${API_ENDPOINTS.saveTags}?cardTransactionId=${cardTransactionId}`,
      data: { data: postData },
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await api(data);
    return response.data;
  } catch (error) {
    throw error?.response?.data;
  }
});





export const getAllExpenseListByCompany = createAsyncThunk("expense/fetchAllExpenses", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.getAllExpenseListByCompany + queryParams
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const getCardByemployeeId = createAsyncThunk("expense/getCardByemployeeId", async (employeeId) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.getCardByEmployeeId + "/" + employeeId
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.message;
  }
});

export const addExpenseTransaction = createAsyncThunk("expense/addExpenseTransaction", async (expense) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.addExpenseTransaction,
      data: expense
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});
export const uploadDocument = createAsyncThunk("expense/uploadDocument", async (image) => {
  try {
    let data = {
      method: METHOD_TYPE.post,
      url: API_ENDPOINTS.uploadDocument + "?docType=receiptAttachments",
      data: image,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const fetchCurrencies = createAsyncThunk("expense/fetchCurrencies", async () => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.fetchCurrencies
    };
    const response = await api(data);
    return response.data;

  } catch (error) {
    throw error?.response?.data;
  }
});

export const exportAllExpenses = createAsyncThunk("/employee/exportAllExpenses", async (queryParams) => {
  try {
    let data = {
      method: METHOD_TYPE.get,
      url: API_ENDPOINTS.exportAllExpenses + queryParams,
    };
    const response = await api(data);
    return response;

  } catch (error) {
    throw error?.response?.data;
  }
}
);
export const getAllCorporateCardsExpenses = createAsyncThunk(
  'corporateCardsExpense/fetchAllCorporateCardsExpenses',
  async (queryParams) => {
    try {
      let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.getAllCorporateExpense + queryParams,
      };
      const response = await api(data);
      console.log('response', response.data);
      return response.data;
    } catch (error) {
      console.log('errr', error);

      throw error?.response?.data;
    }
  }
);
export const getExpenseTransactionList = createAsyncThunk(
  'corporateCardsExpense/getEmployeeCorpCardExpenses',
  async (queryParams) => {
    try {
      let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.getCardCorporateTransaction + queryParams,
      };
      const response = await api(data);
      console.log('res', response.data);
      return response.data;
    } catch (error) {
      console.log('errror', error);

      throw error?.response?.data;
    }
  }
);
export const getExpenseReviewList = createAsyncThunk(
  'corporateCardsExpense/fetchCorpCardExpensesForApproval',
  async (queryParams) => {
    try {
      let data = {
        method: METHOD_TYPE.get,
        url: API_ENDPOINTS.getCardCropExpenseForApproval + queryParams,
      };
      const response = await api(data);
      console.log('response', response.data);
      return response.data;
    } catch (error) {
      console.log('error', error);

      throw error?.response?.data;
    }
  }
);

const expensesReducer = createSlice({
  name: "expensesReducer",
  initialState,
  reducers: {
    handleExpenseFilters: (state, action) => {
      state.filters = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvaliableBalance.fulfilled, (state, { payload }) => {
        state.isBalanceLoading = false;
        state.error = null;
        state.avaliableBalanceDetails = payload.data;
      })
      .addCase(getEmployeeExpenseList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.expenseList = payload.data;
      })
      .addCase(getExpensesForApproval.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.expenseList = payload.data;
      })
      .addCase(getEmployeeExpenseListBySearch.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.expenseList = payload.data;
      })
      .addCase(getEmployeeCardList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.cardList = payload.data;
      })
      .addCase(getEmployeeCardList.pending, (state) => {
        state.isLoading = true;
        state.cardList = [];
        state.employeeCardList = [];
      })
      .addCase(getCardByemployeeId.fulfilled, (state, { payload }) => {
        state.isCardLoading = false;
        state.error = null;
        state.employeeCardList = payload.data;
      })
      .addCase(getCardByemployeeId.pending, (state) => {
        state.isCardLoading = true;
        state.employeeCardList = [];
        state.cardList = [];
      })
      .addCase(getAllCorporateCardsExpenses.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.expenseAllCorporateCardList = payload.data;
      })
      .addCase(getExpenseTransactionList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.expenseCardCorporateList = payload.data;
      })
      .addCase(getExpenseReviewList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.expenseCardCorporateList = payload.data;
      })
      .addCase(getAvaliableBalance.pending, (state) => {
        state.isBalanceLoading = true;
        state.avaliableBalanceDetails = [];
      })
      .addCase(getAllExpenseListByCompany.pending, (state) => {
        state.isLoading = true;
        state.companyAllExpenses = [];
      })
      .addCase(uploadTransactionReceipt.pending, (state) => {
        state.isReceiptLoading = true;
      })
      .addCase(uploadTransactionReceipt.fulfilled, (state) => {
        state.isReceiptLoading = false;
        state.error = null;
      })
      .addCase(uploadTransactionReceipt.rejected, (state) => {
        state.isReceiptLoading = false;
      })
      .addCase(exportAllExpenses.fulfilled, (state) => {
        state.isExpenseExportLoading = false;
        state.error = null;
      })
      .addCase(exportAllExpenses.pending, (state) => {
        state.isExpenseExportLoading = true;
      })
      .addCase(exportAllExpenses.rejected, (state, { error }) => {
        state.isExpenseExportLoading = false;
        state.error = error.message
          ? error.message
          : 'Request Failed Please Try Again ';
      })
      .addMatcher(isAnyOf(addExpenseTransaction.fulfilled), (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isAnyOf(addCardTopUp.fulfilled), (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isAnyOf(approveRejectExpense.fulfilled), (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isAnyOf(getExpenseDetails.fulfilled), (state, { payload }) => {
        state.isExpenseDetailLoading = false;
        state.isLoading = false;
        state.error = null;
        state.expenseDetails = payload.data;
      })
      .addMatcher(isAnyOf(fetchExpenseActivityLog.fulfilled), (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.expenseActivityLogs = payload.data;
      })
      .addMatcher(isAnyOf(updateCardTransaction.fulfilled), (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isAnyOf(getCategoryList.fulfilled), (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.categoryList = payload.data;
      })
      .addMatcher(isAnyOf(uploadTransactionReceipt.fulfilled), (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isAnyOf(getAllTagByGroup.fulfilled), (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.tags = payload.data;
      })
      .addMatcher(isAnyOf(saveTags.fulfilled), (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addMatcher(isAnyOf(saveTags.rejected), (state, { error }) => {
        state.isLoading = false;
        state.error = error.message ? error.message : "Request Failed Please Try Again ";
      })
      .addMatcher(isAnyOf(saveTags.pending), (state) => {
        state.isLoading = true;
      })

      .addMatcher(isAnyOf(getAllExpenseListByCompany.fulfilled), (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.companyAllExpenses = payload.data;
      })
      .addMatcher(isAnyOf(fetchCurrencies.fulfilled), (state, { payload }) => {
        state.isLoading = false;
        state.error = null;
        state.currencies = payload.data;
      })
      .addMatcher(isAnyOf(getExpenseDetails.pending), (state) => {
        state.isExpenseDetailLoading = true;
        state.expenseDetails = null;
        state.isLoading = true;
      })
      .addMatcher(
        isAnyOf(
          getAvaliableBalance.rejected,
        ),
        (state, { error }) => {
          state.isBalanceLoading = false;
          state.error = error.message
            ? error.message
            : 'Request Failed Please Try Again ';
        }
      )
      .addMatcher(
        isAnyOf(
          getEmployeeExpenseList.pending,
          getExpensesForApproval.pending,
          addCardTopUp.pending,
          approveRejectExpense.pending,
          fetchExpenseActivityLog.pending,
          updateCardTransaction.pending,
          getCategoryList.pending,
          uploadTransactionReceipt.pending,
          getAllTagByGroup.pending,
          saveTags.pending,
          addExpenseTransaction.pending,
          fetchCurrencies.pending,
          getAllCorporateCardsExpenses.pending,
          getExpenseTransactionList.pending,
          getExpenseReviewList.pending
        ),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getAvaliableBalance.rejected,
          getEmployeeExpenseList.rejected,
          getExpensesForApproval.rejected,
          getEmployeeExpenseListBySearch.rejected,
          getEmployeeCardList.rejected,
          addCardTopUp.rejected,
          approveRejectExpense.rejected,
          getExpenseDetails.rejected,
          fetchExpenseActivityLog.rejected,
          updateCardTransaction.rejected,
          getCategoryList.rejected,
          uploadTransactionReceipt.rejected,
          getAllTagByGroup.rejected,
          saveTags.rejected,
          getAllExpenseListByCompany.rejected,
          getCardByemployeeId.rejected,
          addExpenseTransaction.rejected,
          fetchCurrencies.rejected,
          getAllCorporateCardsExpenses.rejected,
          getExpenseTransactionList.rejected,
          getExpenseReviewList.rejected
        ),
        (state, { error }) => {
          state.isLoading = false;
          state.isCardLoading = false;
          state.error = error.message
            ? error.message
            : 'Request Failed Please Try Again ';
        }
      );
  },
});
const expenseReducer = expensesReducer.reducer;
export const { handleExpenseFilters } = expensesReducer.actions;

export default expenseReducer;