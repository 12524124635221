import React from "react";
import './buttonsStyle.scss';

function DangerButton({ type, onClick, isEnabled = true, buttonText, id, style }) {
    return (
        <button
            type={type}
            {...(type === "button" && { onClick })}
            className= {isEnabled ? "btn btn-sm btn-danger truncated-text" : "disabledButton"}
            onClick={onClick}
            id={id}
            style={style}
            disabled={!isEnabled}
        >
            {buttonText}
        </button>
    );
}

export default DangerButton;
