import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../../utils/apiUrls";
import api from "../../../utils/api";

const initialState = {
    fetchTopUp: [],
    isLoading: false,
    error: null,
    exportResponseData: {},
    isExportLoading: false,
};

export const getCardTopUpRequest = createAsyncThunk("/card/fetchReviewerCardTopUpRequest", async ({ details, saveData }) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchReviewerCardTopUpRequest + details
        };
        const response = await api(data);
        return { ...response.data, saveData };
    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateCardTopUpRequest = createAsyncThunk("/card/approveRejectCardTopUpRequest", async (updateData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.approveRejectCardTopUpRequest,
            data: updateData
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const exportTopUpRecords = createAsyncThunk("/card/exportToFileTopUpRequest", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.exportCardTopUpRequestFile,
            data: postData
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const exportAllPagesTopUpRecords = createAsyncThunk("/card/exportAllPagesTopUpRecords", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.exportAllCardTopUpRequest,
            data: postData
        };
        const response = await api(data);
        return response;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const createAuditTrail = createAsyncThunk("/card/createAuditTrail", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addAuditTrail,
            data: postData
        };
        const response = await api(data);
        const { data: responseData } = response;
        return responseData;
    } catch (error) {
        throw error?.message;
    }
});

const topUpRequestSlice = createSlice({
    name: "topUpRequest",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCardTopUpRequest.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                if (payload.saveData)
                    state.fetchTopUp = payload.data;
                state.error = null;
            })
            .addCase(updateCardTopUpRequest.fulfilled, (state) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(exportTopUpRecords.fulfilled, (state, { payload }) => {
                state.isExportLoading = false;
                state.error = null;
                state.exportResponseData = payload.data;
            })
            .addCase(exportTopUpRecords.pending, (state) => {
                state.isExportLoading = true;
            })
            .addCase(exportTopUpRecords.rejected, (state, { error }) => {
                state.isExportLoading = false;
                state.error = error ? error : "Request Failed Please Try Again ";
            })
            .addCase(exportAllPagesTopUpRecords.fulfilled, (state) => {
                state.isExportLoading = false;
                state.error = null;
            })
            .addCase(exportAllPagesTopUpRecords.pending, (state) => {
                state.isExportLoading = true;
            })
            .addCase(exportAllPagesTopUpRecords.rejected, (state, { error }) => {
                state.isExportLoading = false;
                state.error = error ? error : "Request Failed Please Try Again ";
            })
            .addCase(getCardTopUpRequest.pending, (state) => {
                state.isLoading = true;
                state.fetchTopUp = [];
            })
            .addMatcher(isAnyOf(updateCardTopUpRequest.pending), (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addMatcher(isAnyOf(getCardTopUpRequest.rejected, updateCardTopUpRequest.rejected), (state, { error }) => {
                state.isLoading = false;
                state.error = error ? error : "Request Failed Please Try Again ";
            });
    }

});
export default topUpRequestSlice.reducer;