import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    isLoading: false,
    isOwnerLoading: false,
    companyDetail: null,
    selectedQuestionare: [],
    terms: null,
};

export const fetchQuestionaire = createAsyncThunk("companyVerification/fetchQuestionaire", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchQuestionaire,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.message;
    }
});

export const fetchCompanyStatus = createAsyncThunk("company/getCompanyStatusDetails", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchCompanyStatus,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.message;
    }
});

export const getAllDocuments = createAsyncThunk("company/getAllDocuments", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllDocuments,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.message;
    }
});

export const getCompanyDocument = createAsyncThunk("companyVerification/getCompanyDocument", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getCompanyDocument,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const submitAnswer = createAsyncThunk("companyVerification/submitAnswer", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.submitQuestions,
            data: requestData
        };
        console.log(`requestData: ${JSON.stringify(requestData)}`);
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateCompanyProfile = createAsyncThunk("company/updateCompanyProfile", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateCompanyProfile,
            data: requestData,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const uploadCompanyDoc = createAsyncThunk("company/uploadCompanyDoc", async (requestData, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append('attachmentTypeId', requestData[0].typeID);
        formData.append('file', requestData[0].file);
        formData.append('companyId', requestData[0].companyId);
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.uploadCompanyDoc,
            data: formData, 
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.error("API Error:", error);
        return rejectWithValue(error.response?.data || "An error occurred while uploading documents.");
    }
});

export const deleteDoc = createAsyncThunk("company/deleteCompanyDoc", async (attachmentTypeId, { rejectWithValue }) => {
    try {
        const data = {
            method: METHOD_TYPE.delete,
            url: `${API_ENDPOINTS.deleteDoc}?attachmentTypeId=${attachmentTypeId}`,
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        return rejectWithValue(error?.response?.data || error.message);
    }
}
);

export const generateInvitationLink = createAsyncThunk("/employee/sendInvite", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.sendInvitation,
            data: requestData,
        };

        console.log(JSON.stringify(data));
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const getCompanyDetails = createAsyncThunk("company/getCompanyDetails", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.companyDetails,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getCompanyOwners = createAsyncThunk("employee/getEmployees?isOwner=true", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getOwners + `?isOwner=${true}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.message;
    }
});

export const updateOwnerDetails = createAsyncThunk("/employee/updateOwner", async ({ ownerID, requestData }) => {
    try {
        console.log(JSON.stringify(requestData));
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateOwner+`?employeeId=${ownerID}`,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateKybStatus = createAsyncThunk("/company/setKybStatus", async (requestData) => {
    try {
        console.log(JSON.stringify(requestData));
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.setKybStatus,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteOwner = createAsyncThunk("/employee/deleteOwner", async (ownerID, { rejectWithValue }) => {
    try {
        const data = {
            method: METHOD_TYPE.delete,
            url: `${API_ENDPOINTS.deleteOwner}?employeeId=${ownerID}`,
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        return rejectWithValue(error?.response?.data || error.message);
    }
});

export const getTermsAndConditions = createAsyncThunk("/termsAndConditions/fetchTerms", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: `${API_ENDPOINTS.getTermsConditions}?type=company`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addConfirmedTerms = createAsyncThunk("termsAndConditions/addConfirmedAgreement", async (payload) => {
    try {
      let data = {
        method: METHOD_TYPE.post,
        url: API_ENDPOINTS.addConfirmedTerms,
            data : payload
      };

      console.log(data);
      const response = await api(data);
      return response.data;
    } catch (error) {
      throw error?.response?.data;
    }
});

const verifyCompanySlice = createSlice({
    name: "companyVerification",
    initialState,
    reducers: {
    },

    extraReducers: (builder) => {
        builder
            .addCase(getCompanyDetails.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.companyDetail = payload.data?.companyDetail;
                state.selectedQuestionare = payload.data?.questionare;
            })
            .addCase(getCompanyDetails.pending, (state) => {
                state.isLoading = true;
                state.companyDetail = null;
                state.selectedQuestionare = [];
            })
            .addCase(generateInvitationLink.fulfilled, (state) => {
                state.isOwnerLoading = false;
            })
            .addCase(generateInvitationLink.rejected, (state) => {
                state.isOwnerLoading = false;
            })
            .addCase(generateInvitationLink.pending, (state) => {
                state.isOwnerLoading = true;
            })
            .addCase(updateOwnerDetails.fulfilled, (state) => {
                state.isOwnerLoading = false;
            })
            .addCase(updateOwnerDetails.rejected, (state) => {
                state.isOwnerLoading = false;
            })
            .addCase(updateOwnerDetails.pending, (state) => {
                state.isOwnerLoading = true;
            })
            .addCase(getTermsAndConditions.fulfilled, (state, { payload }) => {
                state.isLoading = false;
                state.terms = payload.data;
            })
            .addMatcher(
                (action) =>
                    action.type === fetchQuestionaire.pending.type ||
                    action.type === fetchQuestionaire.fulfilled.type ||
                    action.type === fetchQuestionaire.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === fetchQuestionaire.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === fetchCompanyStatus.pending.type ||
                    action.type === fetchCompanyStatus.fulfilled.type ||
                    action.type === fetchCompanyStatus.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === fetchCompanyStatus.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === getAllDocuments.pending.type ||
                    action.type === getAllDocuments.fulfilled.type ||
                    action.type === getAllDocuments.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === getAllDocuments.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === getCompanyOwners.pending.type ||
                    action.type === getCompanyOwners.fulfilled.type ||
                    action.type === getCompanyOwners.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === getCompanyOwners.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === submitAnswer.pending.type ||
                    action.type === submitAnswer.fulfilled.type ||
                    action.type === submitAnswer.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === submitAnswer.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === addConfirmedTerms.pending.type ||
                    action.type === addConfirmedTerms.fulfilled.type ||
                    action.type === addConfirmedTerms.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === addConfirmedTerms.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === uploadCompanyDoc.pending.type ||
                    action.type === uploadCompanyDoc.fulfilled.type ||
                    action.type === uploadCompanyDoc.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === uploadCompanyDoc.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === deleteDoc.pending.type ||
                    action.type === deleteDoc.fulfilled.type ||
                    action.type === deleteDoc.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === deleteDoc.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === updateCompanyProfile.pending.type ||
                    action.type === updateCompanyProfile.fulfilled.type ||
                    action.type === updateCompanyProfile.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === updateCompanyProfile.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === updateOwnerDetails.pending.type ||
                    action.type === updateOwnerDetails.fulfilled.type ||
                    action.type === updateOwnerDetails.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === updateOwnerDetails.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === updateKybStatus.pending.type ||
                    action.type === updateKybStatus.fulfilled.type ||
                    action.type === updateKybStatus.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === updateKybStatus.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === getCompanyDocument.pending.type ||
                    action.type === getCompanyDocument.fulfilled.type ||
                    action.type === getCompanyDocument.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === getCompanyDocument.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === getTermsAndConditions.pending.type ||
                    action.type === getTermsAndConditions.fulfilled.type ||
                    action.type === getTermsAndConditions.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === getTermsAndConditions.pending.type;
                }
            )
            .addMatcher(
                (action) =>
                    action.type === getCompanyDetails.pending.type ||
                    action.type === getCompanyDetails.fulfilled.type ||
                    action.type === getCompanyDetails.rejected.type,
                (state, action) => {
                    state.isLoading = action.type === getCompanyDetails.pending.type;
                }
            );
    }
});
export default verifyCompanySlice.reducer;