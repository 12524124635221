import React from 'react';
import { useTranslation } from 'react-i18next';
import ReceiptIcon from '../../assets/images/spending_recipts.png';
import { fetchWebCardSpendings } from '../../pages/Cards/slice/cardListSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import spending from "../../assets/images/spending.png";
import cookies from 'js-cookie';
import { formatQAR, formatTime } from '../../utils/XpendlessUtils';
import LoadingComponent from '../Loader/LoadingComponent';
import SecondaryButton from '../Buttons/SecondaryButton';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

function PersonSpending(props) {
    const { t } = useTranslation();
    const { employeeId } = props;
    const dispatch = useDispatch();
    const [pageNo, setPageNo] = useState(1);
    const { isLoading, employeeSpendings } = useSelector(state => state.cardListReducer);
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

    useEffect(() => {
        if (employeeId) {
            let queryParams = `?page=${pageNo}&employeeId=${employeeId}`;
            dispatch(fetchWebCardSpendings(queryParams));
        }
    }, [dispatch, pageNo]);

    const onPreviousClick = () => {
        setPageNo(prev => prev - 1);
    };
    const onNextClick = () => {
        setPageNo(prev => prev + 1);
    };

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title');
    }, [currentLanguage, t]);

    return (
        <>
            {
                employeeSpendings?.rows?.length ?
                    (
                        <div className='team_white-container'>
                            {employeeSpendings?.rows?.length > 0 &&
                                <div>
                                    <div className=''>
                                        <div className='d-flex align-items-center justify-content-betwee green-header' style={{ marginBottom: 24 }}>
                                            <img src={spending} width={20} style={{ objectFit: 'contain' }} className='mx-3' />
                                            <p className='green-header-text mb-0' >{t('teams.spending')}</p>
                                        </div>
                                        <div className='table-responsive p-3 py-3' style={{ position: 'relative' }}>
                                            {isLoading && <LoadingComponent />}
                                            <table id="personSpending" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                                                <thead>
                                                    <tr style={{ borderTopWidth: 0 }} className='table-head'>
                                                        <th scope="col">{t('teams.date')}</th>
                                                        <th scope="col">{t('teams.card')}</th>
                                                        <th scope="col">{t('teams.receipt')}</th>
                                                        <th scope="col">{t('teams.description')}</th>
                                                        <th scope="col">{t('teams.amount')}</th>
                                                        <th scope="col">{t('actions')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(employeeSpendings?.rows?.length > 0) &&
                                                        employeeSpendings?.rows?.map((spending, index) => (
                                                            <tr key={index} className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`}>
                                                                <td>{formatTime(spending?.createdAt)}</td>
                                                                <td>{spending?.cardToken}</td>
                                                                <td><img src={ReceiptIcon} style={{ width: 24, objectFit: 'contain' }} alt="Receipt Icon" /></td>
                                                                <td>{spending?.description}</td>
                                                                <td>{formatQAR(spending?.amount)} QAR</td>
                                                                <td>
                                                                    <a id={`more_${index}`}><span className='green-primary' style={{ marginLeft: 10, marginRight: 10 }}>{t('teams.more')}</span></a>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between  mt-3'
                                        style={{ width: "100%", borderTop: '0px !imporant' }}>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination mt-2 pt-1 mb-3 pe-0">
                                                <li className="page-item">
                                                    <SecondaryButton
                                                        type={'button'}
                                                        onClick={() => onPreviousClick()}
                                                        buttonText={t("previous")}
                                                        isEnabled={pageNo !== 1}
                                                        id={'previous'}
                                                    />
                                                </li>
                                                <div className='pe-3'></div>
                                                <li className="page-item">
                                                    <SecondaryButton
                                                        type={'button'}
                                                        onClick={() => onNextClick()}
                                                        buttonText={t("next")}
                                                        isEnabled={!(pageNo == employeeSpendings?.totalPages || employeeSpendings?.totalPages == 0)}
                                                        id={'next'}
                                                    />
                                                </li>
                                            </ul>
                                        </nav>
                                        <div className='page-count d-flex align-items-center green-primary' >
                                            <h4 className='me-3'>Page {employeeSpendings?.currentPage} of {employeeSpendings?.totalPages}</h4>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    ) :
                    (<></>)
            }
        </>
    );
}

export default PersonSpending;
