import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpenseTable from "../../assets/images/expense-table.png";

export default function ExpensePage() {
  const selectedIndex = 1;
  const { t } = useTranslation();


  const dropdownItems = [
    { label: t("walkThrough.all") },
    { label: t("walkThrough.my_transaction") },
    { label: t("walkThrough.for_your_review") }
  ];

  return (
    <div className="mt-5">
      <div className="d-flex flex-column align-items-start shared-width-container">
        <div className="balance-box" style={{width: '360px'}}>
          <div className="balance-card-box pt-4 pb-4">
            <div className="balance-item">
              <div className="balance-label">{t("walkThrough.currrent_balance")}</div>
              <div className="balance-value">
                {t("walkThrough.balance_value")}.
                <span className="mt-5" style={{ fontSize: "12px", color: "white", opacity: 0.8 }}>
                  {t("walkThrough.wallet_currency")}
                </span>
              </div>
            </div>
            <div className="balance-item">
              <div className="card-status">{t("walkThrough.active_card")}</div>
            </div>
          </div>

          <div className="card-spending-limit mt-2">
            <div className="spending-limit-label">{t("walkThrough.card_spending-limit")}</div>
            <button className="change-button">{t("walkThrough.change_btn")}</button>
          </div>

          <div className="progress-container mt-2">
            <div className="progress-bar">
              <div className="progress-bar-fill"></div>
            </div>
          </div>

          <div className="card-spending-limit mt-1">
            <div className="spending-limit">
              {t("walkThrough.spending_limit")}
              <span className="card-status" style={{ fontWeight: 500 }}>{t("walkThrough.total_limit")}</span>
            </div>
            <div className="monthly-limit">{t("walkThrough.monthly_limit")}</div>
          </div>
        </div>

        <div className="expense-btns-container d-flex justify-content-between mt-3 w-100">
          <div className="justify-content-between"
            style={{
              border: "1px solid rgba(0, 208, 148, 0.6)",
              borderRadius: 10,
              padding: 5,
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              width: "360px",
              height: "50px",
              alignItems: "center"
            }}
          >
            {dropdownItems.map((item, index) => (
              <div
                key={index}
                className={`dropdown-item ${selectedIndex === index ? "selected" : "text-white"}`}
                style={{
                  flex: "1 1 auto",
                  textAlign: "center",
                  padding: "5px",
                  borderRadius: '10px',
                  height: '40px'
                }}
              >
                {item.label}
              </div>
            ))}
          </div>
          <button className="btn btn-primary add-expense-button" style={{width: '160px'}}>
            {t('walkThrough.add_expenses')}
          </button>
        </div>

        <div className="mt-3 mb-2 w-100">
          <img
            src={ExpenseTable}
            alt="Transaction Table"
            className="img-fluid"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>
    </div>


  );
}