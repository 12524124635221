import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Export.scss';
import moment from 'moment';
import ExpensesComponent from '../Expenses/ExpensesComponent';
import notFound from '../../assets/images/not_found.png';
import LoadingComponent from '../Loader/LoadingComponent';
import PrimaryButton from '../Buttons/PrimaryButton';
import SecondaryButton from '../Buttons/SecondaryButton';

function ExportQueue({ exportQueueData, handleOnExportClick, isLoading, onPreviousClick, pageNo, currencies, showExpenseDetailModal, type, type1, handleOnMoveBackUnExported, setChildFunctionRef }) {
    const { t } = useTranslation();
    const [csvType] = useState('csv');
    const [selectedItems, setSelectedItems] = useState([]);
    const [checkedAllPages, setCheckedAllPages] = useState(false);
    const [pseudoCheckedAll, setPseudoCheckedAll] = useState(false);
    const [uncheckedTransaction, setUncheckedTransaction] = useState([]);
    useEffect(() => {
        setChildFunctionRef(resetAll);
        if (exportQueueData?.rows?.length > 0) {
            if (pseudoCheckedAll) {
                const selected = [...selectedItems];

                for (const row of exportQueueData.rows) {
                    if (row?.records?.length > 0) {
                        for (const record of row.records) {
                            if (!uncheckedTransaction?.includes(record?.cardTransactionId) && !selected.some(element => element?.transactionId == record?.cardTransactionId))
                                selected.push({
                                    transactionId: record?.cardTransactionId,
                                    transactionType: record?.type,
                                    exportQueueId: record?.exportQueueId
                                });
                        }
                    }
                }
                setSelectedItems(selected);
            }
        }
    }, [exportQueueData]);

    useEffect(() => {
        if (type) {
            setSelectedItems([]);
            setCheckedAllPages(false);
            setPseudoCheckedAll(false);
            setUncheckedTransaction([]);
        }
    }, [type]);

    const resetAll = () => {
        setSelectedItems([]);
        setCheckedAllPages(false);
        setPseudoCheckedAll(false);
        setUncheckedTransaction([]);
    };

    const handleCheckboxChange = (id, type, exportQueueId) => {
        const selected = [...selectedItems];
        const unchecked = [...uncheckedTransaction];

        const index = selected.findIndex(
            (item) => item.exportQueueId == exportQueueId && item.transactionType == type
        );

        if (index === -1) {
            if (pseudoCheckedAll) {
                const uncheckedIndex = unchecked.findIndex((trans) => trans == id);
                unchecked.splice(uncheckedIndex, 1);
                setUncheckedTransaction(unchecked);
            }
            selected.push({ transactionId: id, transactionType: type, exportQueueId: exportQueueId });
        } else {
            if (pseudoCheckedAll) {
                unchecked.push(id);
                setUncheckedTransaction(unchecked);
            }
            selected.splice(index, 1);
        }
        if (checkedAllPages)
            setCheckedAllPages(false);
        setSelectedItems(selected);
    };

    const handleCheckAllPages = () => {
        if (checkedAllPages) {
            setSelectedItems([]);
            setCheckedAllPages(false);
            setPseudoCheckedAll(false);
            setUncheckedTransaction([]);
        } else {
            let allRecords = [];
            exportQueueData?.rows?.map((element) =>
                element?.records.map((record) => allRecords.push({
                    transactionId: record?.cardTransactionId,
                    transactionType: record?.type,
                    exportQueueId: record?.exportQueueId
                }))
            );
            setSelectedItems(allRecords);
            setCheckedAllPages(true);
            setPseudoCheckedAll(true);
            setUncheckedTransaction([]);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <div className={window.innerWidth > 500 ? 'd-flex align-items-center justify-content-between mt-5' : 'mt-5'}>
                <div className="form-check">
                    <input className="form-check-input " type="checkbox" value=""
                        id="checkAll"
                        checked={checkedAllPages}
                        onChange={handleCheckAllPages} />
                    <label className="form-check-label" htmlFor="checkAll">
                        {t('export.select_all')}
                    </label>
                </div>
                <div className={window.innerWidth > 500 ? 'd-flex align-items-center justify-content-between' : ''}>
                    <PrimaryButton
                        type={'button'}
                        onClick={() => { handleOnMoveBackUnExported(selectedItems); setCheckedAllPages(false); }}
                        buttonText={t('export.move_back_to_unexport')}
                        isEnabled={selectedItems.length >= 1}
                        id={'back_to_queue'}
                        style={{ marginInline: '16px', marginBottom: '8px', width: '60%' }}
                        isExportBtn={true}
                        isLoading={isLoading}
                    />

                    <PrimaryButton
                        type={'button'}
                        onClick={() => handleOnExportClick(pseudoCheckedAll, csvType, selectedItems, uncheckedTransaction)}
                        buttonText={t('export.export')}
                        isEnabled={selectedItems.length >= 1}
                        id={'move_to_queue'}
                        style={{ marginBottom: '8px', width: '15%' }}
                        isExportBtn={true}
                        isLoading={isLoading}
                    />
                </div>
            </div>
            {(isLoading && !exportQueueData?.rows) && <LoadingComponent />}
            {
                (exportQueueData?.rows && exportQueueData?.rows?.length > 0)
                    ? (exportQueueData['rows']?.map((item, i) => {
                        return (
                            <div key={i} className='export-border mt-4'>
                                <h2 className='text-grey'>{moment(item?.date).format('DD MMM YYYY')}</h2>
                                <ExpensesComponent
                                    index={`exportQueue_${i}`}
                                    showCheckBox={true}
                                    data={item?.records}
                                    currencies={currencies}
                                    showExpenseDetailModal={showExpenseDetailModal}
                                    handleCheckboxChange={handleCheckboxChange}
                                    selectedItems={selectedItems}
                                    type={type}
                                    type1={type1}
                                    prevPath={'/export'}
                                    activeTap={'queue'}
                                />
                            </div>);
                    })) : (
                        <div className='d-flex align-items-center justify-content-center p-3 px-2'>
                            <img src={notFound} width={200} height={200} />
                        </div>
                    )
            }
            <div className='d-flex align-items-center justify-content-between mt-2 ' style={{ width: "100%", borderTop: '0px !imporant' }}>
                <nav aria-label="Page navigation example">
                    <ul className="pagination  pt-1 mt-3  pe-0">
                        <li className="page-item">
                            <SecondaryButton
                                type={'button'}
                                onClick={() => onPreviousClick()}
                                buttonText={t("previous")}
                                isEnabled={pageNo !== 1}
                                id={'previous'}
                            />
                        </li>
                        <li className="page-item">
                            <SecondaryButton
                                type={'button'}
                                onClick={() => onPreviousClick()}
                                buttonText={t("next")}
                                isEnabled={!(pageNo == exportQueueData?.totalPages || exportQueueData?.totalPages == 0)}
                                id={'next'}
                                style={{marginInline: '16px'}}
                            />
                        </li>

                    </ul>
                </nav>
                <div className='page-count d-flex align-items-center green-primary'>
                    <h4 className='me-3'>Page {exportQueueData?.currentPage} of {exportQueueData?.totalPages || 1}</h4>
                </div>
            </div>
        </div>
    );
}

export default ExportQueue;
