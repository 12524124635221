import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Dashboard.scss';
import { Modal } from 'react-bootstrap';
import ImagesCross from '../../assets/images/close-square-8.png';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import List2 from '../../assets/images/list1.png';
import cardsIssued from '../../assets/images/cards_issued.png';
import UserProfile from '../../assets/images/profile-2user.png';
import { Dropdown } from 'react-bootstrap';
import SecondaryButton from '../Buttons/SecondaryButton';
import PrimaryButton from '../Buttons/PrimaryButton';
import LoadingComponent from '../Loader/LoadingComponent';
import { Grid, Stack } from '@mui/material';

function DashboardCards(props) {
    const { cardIssueCountLoading, cardIssueCount, totalUsersCount, totalUsersCountLoading, totalTransactionCountLoading, totalTransactionCount, handleFilterClick, globalSelectedFil, setSelectedFilterCards, selectedFilterCards, setSelectedFilterUsers, selectedFilterUsers, setSelectedFilterTransactions, selectedFilterTransactions, selectedIndex, approvedReimbursmentTransaction, approvedReimbursmentTransactionLoading, pendingReimbursmentTransactionLoading, pendingReimbursmentTransaction, rejectedReimbursmentTransactionLoading, rejectedReimbursmentTransaction, settledReimbursmentTransactionLoading, settledReimbursmentTransaction } = props;
    const [selectedSection, setSelectedSection] = useState('');
    const [approvedUser, setApprovedUser] = useState('');
    const [pendingUser, setPendingUser] = useState('');
    const [rejectedUser, setRejectedUser] = useState('');
    const [settledUser, setsettledUser] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const setLoader = () => {
        setIsLoading(true);

        const timerId = setTimeout(() => {
            setIsLoading(false);
        }, 5000);

        return () => clearTimeout(timerId);
    };
    useEffect(() => {
        if (globalSelectedFil) {
            setApprovedUser('');
            setPendingUser('');
            setRejectedUser('');
            setsettledUser('');
        }
    }, [globalSelectedFil]);

    const [show, setShow] = useState(false);
    const currentDate = new Date().toISOString().split('T')[0];
    const { t } = useTranslation();
    const options = [
        { value: 'current_month', label: t('dashboard.current_month') },
        { value: 'current_year', label: t('dashboard.current_year') },
        { value: 'last_3_year', label: t('dashboard.last_3_years') },
        { value: 'custom', label: t('dashboard.custom') },
    ];

    const schema = yup.object().shape({
        startDate: yup
            .string()
            .required('Start Date is required'),
        endDate: yup
            .string()
            .required('End Date is required')
            .test('is-valid-end-date', 'End date should not be before start date', function (value) {
                const startDate = this.resolve(yup.ref('startDate'));
                return !startDate || !value || value >= startDate;
            }),
    });

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const handleClose = (e) => {
        e.preventDefault();
        setShow(false);
        reset();
    };

    const handleOptionChange = (option, section) => {
        switch (section) {
            case 'approved_user':
                setSelectedSection('approved_user');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, approved_user: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, approved_user: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, approved_user: 'Last 3 Years' }));
                }
                break;
            case 'pending_user':
                setSelectedSection('pending_user');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, pending_user: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, pending_user: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, pending_user: 'Last 3 Years' }));
                }
                break;
            case 'rejected_user':
                setSelectedSection('rejected_user');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, rejected_user: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, rejected_user: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, rejected_user: 'Last 3 Years' }));
                }
                break;
            case 'settled_user':
                setSelectedSection('settled_user');
                if (option === 'current_month') {
                    setSelectedFilterCards(prev => ({ ...prev, settled_user: 'Current Month' }));
                }
                if (option === 'current_year') {
                    setSelectedFilterCards(prev => ({ ...prev, settled_user: 'Current Year' }));
                }
                if (option === 'last_3_year') {
                    setSelectedFilterCards(prev => ({ ...prev, settled_user: 'Last 3 Years' }));
                }
                break;
            default:
                break;
        }
        if (option != '' && typeof option === 'string') {
            if (option === 'custom') {
                setSelectedFilterCards(prev => ({ ...prev, [section]: null }));
                setShow(true);
            } else {
                handleFilterClick(section, option, {}, true);
            }
        }
    };

    const handleCloseMenu = (option, section) => {
        const { value, label } = option;
        if (value === 'custom') {
            setShow(true);
        } else {
            handleFilterClick(section, value, {}, true);
        }
        switch (section) {
            case 'card_issue':
                setSelectedSection('card_issue');
                setSelectedFilterCards(label);
                break;
            case 'total_users':
                setSelectedSection('total_users');
                setSelectedFilterUsers(label);
                break;
            case 'no_of_transactions':
                setSelectedSection('no_of_transactions');
                setSelectedFilterTransactions(label);
                break;
            default:
                break;
        }
    };
    const onSubmit = (data) => {
        setLoader();
        const date = `${moment(data?.startDate).format('DD/MM/YYYY')} - ${moment(data?.endDate).format('DD/MM/YYYY')}`;
        if (selectedSection === 'approved_user') {
            setApprovedUser(date);
        }
        if (selectedSection === 'pending_user') {
            setPendingUser(date);
        }
        if (selectedSection === 'rejected_user') {
            setRejectedUser(date);
        }
        if (selectedSection === 'settled_user') {
            setsettledUser(date);
        }
        handleFilterClick(selectedSection, 'custom', data, true);
        setShow(false);
        reset();
    };

    return (
        <Grid container rowSpacing={2.5} columnSpacing={{ xs: 0.5, sm: 1, xl: 2.5 }}>
            <Grid item xs={12} md={4.6}>
                <div className='border-grey small-graph-chart p-3 cards-custom position-relative marginbottoncustomDashboard'>
                    {totalTransactionCountLoading && <LoadingComponent />}
                    <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src={List2} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
                            <p className='header-text mb-0'>{t('dashboard.no_of_transactions')}</p>
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle
                                id="custom-dropdown"
                                className="custom-dropdown-toggle btn-back custom-dashboard-button"
                            >
                                {selectedFilterTransactions === null ? globalSelectedFil : selectedFilterTransactions}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="custom-dropdown-menu">
                                {options.map((option, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        onClick={() => handleCloseMenu(option, 'no_of_transactions')}
                                        className="custom-dropdown-item"
                                    >
                                        {option.label}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Stack>
                    <h1 className="custom-heading mt-3 d-flex align-items-center flex-nowrap" style={{ color: "#ffa07a" }}>
                        {totalTransactionCount?.transactionCount}
                        <span className="total-cards mx-1 mt-3" >
                            /{totalTransactionCount?.TotalTransactionsCount}
                        </span>
                        <span className="total-cards-text mt-3">
                            ({t('dashboard.total_transactions')})
                        </span>
                    </h1>
                </div>
            </Grid>
            {(selectedIndex === 0) && (
                <Grid item xs={12} md={7.4}>
                    <Grid container rowSpacing={2.5} columnSpacing={{ xs: 0.5, sm: 1, xl: 2.5 }} className='dashboardCardpageCss'>
                        <Grid item xs={12} md={6}>
                            <div className='border-grey small-graph-chart p-3 cards-custom position-relative marginbottoncustomDashboard'>
                                {cardIssueCountLoading && <LoadingComponent />}
                                <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={cardsIssued} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
                                        <p className='header-text mb-0'>{t('dashboard.card_issued')}</p>
                                    </div>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="custom-dropdown"
                                            className="custom-dropdown-toggle btn-back custom-dashboard-button"
                                        >
                                            {selectedFilterCards === null ? globalSelectedFil : selectedFilterCards}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="custom-dropdown-menu">

                                            {options.map((option, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => handleCloseMenu(option, 'card_issue')}
                                                    className="custom-dropdown-item"
                                                >
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Stack>

                                <h1 className="custom-heading mt-3 d-flex align-items-center flex-nowrap" style={{ color: "#ff5733" }}>
                                    {cardIssueCount?.cardCount}
                                    <span className="total-cards mx-1 mt-3" >

                                        /{cardIssueCount?.totalCards}</span>
                                    <span className="total-cards-text mt-3">
                                        ({t('dashboard.total_cards')})
                                    </span>
                                </h1>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className='border-grey small-graph-chart p-3 cards-custom  position-relative marginbottoncustomDashboard'>
                                {totalUsersCountLoading && <LoadingComponent />}
                                <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={UserProfile} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
                                        <h2 className='header-text mb-0'>{t('dashboard.total_users')}</h2>
                                    </div>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="custom-dropdown"
                                            className="custom-dropdown-toggle btn-back custom-dashboard-button"
                                        >
                                            {selectedFilterUsers === null ? globalSelectedFil : selectedFilterUsers}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="custom-dropdown-menu">
                                            {options.map((option, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => handleCloseMenu(option, 'total_users')}
                                                    className="custom-dropdown-item"
                                                >
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Stack>
                                <h1 className="custom-heading mt-3 d-flex align-items-center flex-nowrap" style={{ color: "#1a9a76" }}>

                                    {totalUsersCount?.employeeCount}
                                    <span className="total-cards mx-1 mt-3" >
                                        /{totalUsersCount.totalEmployeesCount}</span>
                                    <span className="total-cards-text mt-3">

                                        ({t('dashboard.total_users')})</span>
                                </h1>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {(selectedIndex === 1) && (
                <Grid item xs={12} md={7.4}>
                    <div className='border-grey small-graph-chart p-3 cards-custom position-relative marginbottoncustomDashboard'>
                        {settledReimbursmentTransactionLoading && <LoadingComponent />}
                        <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
                            <div className='d-flex justify-content-center align-items-center'>
                                <img src={cardsIssued} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
                                <p className='header-text mb-0'>Settled Transactions</p>
                            </div>
                            <Dropdown>
                                <Dropdown.Toggle
                                    id="custom-dropdown"
                                    className="custom-dropdown-toggle btn-back custom-dashboard-button"
                                >
                                    {selectedFilterCards?.settled_user ? selectedFilterCards?.settled_user : settledUser ? settledUser : globalSelectedFil}
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="custom-dropdown-menu">
                                    {options.map((option, index) => (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => handleOptionChange(option.value, 'settled_user')}
                                            className="custom-dropdown-item"
                                        >
                                            {option.label}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Stack>
                        <h1 className="custom-heading mt-3 d-flex align-items-center flex-nowrap" style={{ color: "#ff5733" }}>
                            {settledReimbursmentTransaction?.data?.transactionCount}
                            <span className="total-cards mx-1 mt-3" >

                                /{settledReimbursmentTransaction?.data?.totalTransactionCount}</span>
                            <span className="total-cards-text mt-3">
                                ({t('dashboard.total_settled')})
                            </span>
                        </h1>
                    </div>
                </Grid>
            )}
            {(selectedIndex === 1) && (
                <Grid item xs={12}>
                    <Grid container rowSpacing={2.5} columnSpacing={{ xs: 0.5, sm: 1, xl: 2.5 }} className='dashboardCardpageCss'>
                        <Grid item xs={12}>
                            <div className='border-grey small-graph-chart p-3 cards-custom position-relative marginbottoncustomDashboard'>
                                {approvedReimbursmentTransactionLoading && <LoadingComponent />}
                                <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={cardsIssued} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
                                        <p className='header-text mb-0'>Approved Transactions</p>
                                    </div>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="custom-dropdown"
                                            className="custom-dropdown-toggle btn-back custom-dashboard-button"
                                        >
                                            {selectedFilterCards?.approved_user ? selectedFilterCards?.approved_user : approvedUser ? approvedUser : globalSelectedFil}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="custom-dropdown-menu">
                                            {options.map((option, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => handleOptionChange(option.value, 'approved_user')}
                                                    className="custom-dropdown-item"
                                                >
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Stack>
                                <h1 className="custom-heading mt-3 d-flex align-items-center flex-nowrap" style={{ color: "#ff5733" }}>
                                    {approvedReimbursmentTransaction?.data?.transactionCount}
                                    <span className="total-cards mx-1 mt-3" >

                                        /{approvedReimbursmentTransaction?.data?.totalTransactionCount}</span>
                                    <span className="total-cards-text mt-3">
                                        ({t('dashboard.total_approved')})
                                    </span>
                                </h1>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className='border-grey small-graph-chart p-3 cards-custom  position-relative marginbottoncustomDashboard'>
                                {pendingReimbursmentTransactionLoading && <LoadingComponent />}
                                <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={UserProfile} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
                                        <h2 className='header-text mb-0'>Pending Transactions</h2>
                                    </div>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="custom-dropdown"
                                            className="custom-dropdown-toggle btn-back custom-dashboard-button"
                                        >
                                            {selectedFilterCards?.pending_user ? selectedFilterCards?.pending_user : pendingUser ? pendingUser : globalSelectedFil}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="custom-dropdown-menu">
                                            {options.map((option, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => handleOptionChange(option.value, 'pending_user')}
                                                    className="custom-dropdown-item"
                                                >
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Stack>
                                <h1 className="custom-heading mt-3 d-flex align-items-center flex-nowrap" style={{ color: "#ff5733" }}>
                                    {pendingReimbursmentTransaction?.data?.transactionCount}
                                    <span className="total-cards mx-1 mt-3" >

                                        /{pendingReimbursmentTransaction?.data?.totalTransactionCount}</span>
                                    <span className="total-cards-text mt-3">
                                        ({t('dashboard.total_pending')})
                                    </span>
                                </h1>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className='border-grey small-graph-chart p-3 cards-custom  position-relative marginbottoncustomDashboard'>
                                {rejectedReimbursmentTransactionLoading && <LoadingComponent />}
                                <Stack direction={'row'} spacing={0.5} justifyContent={'space-between'} alignItems={'center'} className='green-header'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img src={UserProfile} width={23} height={23} style={{ objectFit: 'contain' }} className='mx-2' />
                                        <h2 className='header-text mb-0'>Rejected Transactions</h2>
                                    </div>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="custom-dropdown"
                                            className="custom-dropdown-toggle btn-back custom-dashboard-button"
                                        >
                                            {selectedFilterCards?.rejected_user ? selectedFilterCards?.rejected_user : rejectedUser ? rejectedUser : globalSelectedFil}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="custom-dropdown-menu">
                                            {options.map((option, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => handleOptionChange(option.value, 'rejected_user')}
                                                    className="custom-dropdown-item"
                                                >
                                                    {option.label}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Stack>
                                <h1 className="custom-heading mt-3 d-flex align-items-center flex-nowrap" style={{ color: "#ff5733" }}>
                                    {rejectedReimbursmentTransaction?.data?.transactionCount}
                                    <span className="total-cards mx-1 mt-3" >

                                        /{rejectedReimbursmentTransaction?.data?.totalTransactionCount}</span>
                                    <span className="total-cards-text mt-3">
                                        ({t('dashboard.total_rejected')})
                                    </span>
                                </h1>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Modal className='TaxcodeModalOne' show={show} onHide={(e) => handleClose(e)} style={{ paddingRight: 0 }}>
                <form className='form' onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Body>
                        <div className='d-flex justify-content-between align-items-start green-header p-2'>
                            <div className='col-9'>
                                <p className='text-green modal-title' style={{ fontSize: 18 }}>
                                    Select Custom Date
                                </p>
                            </div>
                            <div onClick={handleClose} className='clo-2' style={{ cursor: 'pointer' }}>
                                <img src={ImagesCross} style={{ width: 20, objectFit: 'contain' }} />
                            </div>
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="startDate">{`Start Date`}</label>
                            <Controller
                                name="startDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="startDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        max={currentDate}
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.startDate && (
                                <div style={{ color: 'red' }}>{errors?.startDate?.message}</div>
                            )}
                        </div>
                        <div className="form-outline my-3">
                            <label className="form-label" htmlFor="endDate">{`End Date`}</label>
                            <Controller
                                name="endDate"
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <input
                                        type="date"
                                        id="endDate"
                                        className="form-control form-control-lg"
                                        placeholder="Start Date"
                                        autoComplete="off"
                                        max={currentDate}
                                        {...field}
                                    />
                                )}
                            />
                            {errors?.endDate && (
                                <div style={{ color: 'red' }}>{errors?.endDate?.message}</div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <SecondaryButton
                            type={'button'}
                            onClick={(e) => handleClose(e)}
                            buttonText={t('cancel')}
                            id={'cancel_custom_date'}
                            style={{ width: '104px' }}
                        />

                        <PrimaryButton
                            type={'submit'}
                            buttonText={t('submit')}
                            isEnabled={true}
                            id={'submit_custom_date'}
                            style={{ width: '104px' }}
                            isLoading={isLoading}
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        </Grid>

    );
}

export default DashboardCards;
