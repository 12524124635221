import "./Setting.scss";

import { Nav, Tab, Tabs } from 'react-bootstrap';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { addReviewerPolicy, getAllReviewerPolicy, getAllSubscription, getBillingAmount, getCompanySettings, updateSubscription, getConnectedIntegration, getReviewerPolicyDetails, postPayNow, removeReviewerPolicy, updateCompanyDetails, updateReviewerPolicy, uploadLogo } from '../../pages/Settings/taxSlice';
import { useDispatch, useSelector } from 'react-redux';

import Categories from '../../components/SettingsComponent/Categories';
import CheckIconGreen from '../../assets/images/Check_icon_green.png';
import CompanyColorLogo from '../../assets/images/company-color-logo.png';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import CorporateCards from '../../components/SettingsComponent/CorporateCards';
import Cropper from 'react-easy-crop';
import CurrencyInput from 'react-currency-input-field';
import Employee from '../../components/SettingsComponent/Employee';
import ImagesCross from '../../assets/images/close-square-8.png';
import Modal from 'react-bootstrap/Modal';
import PicEditIcon from '../../assets/images/editicon.png';
import PlanIcon from '../../assets/images/planIcon.png';
import ProfileIcon from '../../assets/images/profile-green-icon.png';
import Setup from "../../components/SettingsComponent/Setup";
import { SketchPicker } from 'react-color';
import Tags from '../../components/SettingsComponent/Tags';
import TaxCodes from '../../components/SettingsComponent/TaxCodes';
import Transaction from '../../assets/images/transaction.png';
import UploadImgColorPickerSec from '../../assets/images/uploadImgColorPicker.png';
import UploadLogoIcon from '../../assets/images/uploadlogoIcon.png';
import cookies from 'js-cookie';
import { formatQAR } from '../../utils/XpendlessUtils';
import lineBackground from '../../assets/images/backgroundLineDesign.png';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MAX_VALUE } from '../../utils/constants';
import LoadingComponent from "../../components/Loader/LoadingComponent";
import { active, activeBlocked, verifyCompanyStatus } from "../../utils/verifyCompanyStatus";
import { allowedUserRoles, verifyUserRole } from "../../utils/verifyUserRole";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import DangerButton from "../../components/Buttons/DangerButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

function Settings() {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (title) => {
    if (title === 'add') {
      setTitle(`${t('settingstab.add_new_policy')}`);
    }
    else if (title === 'edit') {
      setTitle(`${t('settingstab.edit_policy')}`);
    }
    setShow(true);
  };
  const [showColorPicker, setShowColorPicker] = useState(false);
  const handleShowColorPicker = () => setShowColorPicker(true);

  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors, isDirty }, setValue, reset, watch } = useForm();
  const { isLoading, subscriptionPlans, reviewerPolicyData, billingAmountData, companyDetails, connectedIntegration } = useSelector((state) => state?.taxSlice);
  const dispatch = useDispatch();
  const [policyId, setPolicyId] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [details, setDetails] = useState({
    companyUrl: null,
    companyLogo: null,
    backgroundColor: "#FFFFF",
    file: null
  });
  const location = useLocation();
  const [color, setColor] = useState("#ffffff");
  const companyLogoRef = useRef(null);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [accountingTabs, setAccountingTabs] = useState("first");
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [companyURlError, setCompanyURlError] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [isModalVisibleOnSuccess, setIsModalVisibleOnSuccess] = useState(false);
  const [lastValidTransactions, setLastValidTransactions] = useState('');
  const [lastValidTopup, setLastValidTopup] = useState('');
  const [lastValidRequests, setLastValidRequests] = useState('');
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const queryParams = new URLSearchParams(location.search);
  const queryTab = queryParams.get('tab');

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDetails(prev => ({
        ...prev,
        file
      }));
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageSrc(reader.result);
        setShowCropper(true);
      };
      event.target.value = null;
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      const tempFile = new File([croppedBlob], details?.file?.name, { type: details?.file?.type });
      setDetails((prev) => ({
        ...prev,
        file: tempFile,
        companyLogo: URL.createObjectURL(croppedBlob)
      }));
      companyLogoRef.current.value = '';
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setShowCropper(false);
    } catch (e) {
      console.error('Error cropping image:', e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const handleCloseColorPicker = () => {
    if (showCropper && imageSrc) {
      setDetails(prev => ({
        ...prev,
        file: null
      }));
      setImageSrc(null);
      setShowCropper(true);
    } else {
      setDetails((prev) => ({
        ...prev,
        companyLogo: companyDetails?.companyLogo || null,
        companyUrl: companyDetails?.companyUrl || null,
        backgroundColor: companyDetails?.backgroundColor || "#FFFFF",
      }));
      setColor(companyDetails?.backgroundColor);
      setShowColorPicker(false);
      setCompanyURlError(false);
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleCloseColor = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    setColor(newColor.hex);
    setDetails((prev) => ({
      ...prev,
      backgroundColor: newColor.hex,
    }));
  };

  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ];

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);

  useEffect(() => {
    if(verifyCompanyStatus() && verifyUserRole(allowedUserRoles.adminAndAccountant)){
    dispatch(getAllSubscription());
    }
    dispatch(getCompanySettings());
    if(verifyCompanyStatus(active) && verifyUserRole(allowedUserRoles.adminAndAccountant)){
    dispatch(getConnectedIntegration());
    }
    if (queryTab && parseInt(queryTab)) {
      setActiveTab(parseInt(queryTab));
    }
  }, []);

  useEffect(() => {
    dispatch(getAllReviewerPolicy());
    if(verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)){
    dispatch(getBillingAmount());
    }
  }, [dispatch]);

  useEffect(() => {
    if (companyDetails) {
      setDetails((prev) => ({
        ...prev,
        companyUrl: companyDetails?.companyUrl || null,
        companyLogo: companyDetails?.companyLogo,
        backgroundColor: companyDetails?.backgroundColor || "#FFFFF",
      }));
      setColor(companyDetails?.backgroundColor);
    }
  }, [companyDetails]);

  const handleConfirm = () => {
    if (policyId) {
      if(verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)){
      dispatch(removeReviewerPolicy(policyId))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          dispatch(getAllReviewerPolicy());
          setShowConfirmation(false);
          setPolicyId(null);
        }).catch((error) => {
          toast.error(error?.message);
          setShowConfirmation(false);
          setPolicyId(null);
        });
      }
    }
  };

  const getDetail = (reviewerPolicyId) => {
    setPolicyId(reviewerPolicyId);
    if(verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)){
    dispatch(getReviewerPolicyDetails(reviewerPolicyId)).unwrap().then((reviewerPolicyDetail) => {
      if (reviewerPolicyDetail && Object.keys(reviewerPolicyDetail)?.length > 0) {
        setValue('policyName', reviewerPolicyDetail?.policyName);
        setValue('maxcardTransactionsAmount', formatQAR(parseFloat(reviewerPolicyDetail?.maxcardTransactionsAmount || '') || 0));
        setValue('maxCardTopupAmount', formatQAR(parseFloat(reviewerPolicyDetail?.maxCardTopupAmount || '') || 0));
        setValue('maxReimbursementsAmount', formatQAR(parseFloat(reviewerPolicyDetail?.maxReimbursementsAmount || '') || 0));
        setValue('reviewerPolicyId', reviewerPolicyDetail?.reviewerPolicyId);
        handleShow('edit');
      }
    }).catch((error) => {
      toast.error(error?.message || 'Error fetching details');
    });
  }
};

  const postPayAmount = () => {
    dispatch(postPayNow())
      .then((res) => {
        toast.success(res?.payload?.name);
        window.location.reload();
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const onSubmit = (data) => {
    if (policyId) {
      console.log('isDirty', isDirty);
      if (!isDirty) {
        handleClose();
        reset();
        return;
      }
      if(verifyCompanyStatus(activeBlocked) && verifyUserRole(allowedUserRoles.adminAndAccountant)){
      dispatch(updateReviewerPolicy(data))
        .unwrap()
        .then((res) => {
          toast.success(res?.message);
          handleClose();
          dispatch(getAllReviewerPolicy());
        }).catch((error) => {
          toast.error(error?.message);
        });
      }
    } else {
      dispatch(addReviewerPolicy(data))
        .unwrap()
        .then((res) => {
          handleClose();
          reset();
          toast.success(res?.message);
          dispatch(getAllReviewerPolicy());
        }).catch((error) => {
          toast.error(error?.message);
        });
    }
  };

  const handleSave = async () => {
    const urlPattern = /^(https?:\/\/|www\.)\w+(\.\w+)+([/?].*)?$/;
    if (!details?.companyLogo) {
      toast.error("Please select company logo");
      return;
    }
    if (details?.companyUrl && !urlPattern.test(details?.companyUrl)) {
      setCompanyURlError(true);
      return;
    }
    const requestData = { ...details };

    if (details?.file) {
      const formData = new FormData();
      formData.append('file', details?.file);
      const response = await dispatch(uploadLogo(formData)).unwrap();
      requestData["companyLogo"] = response[0]?.data?.url;
    }

    setShowColorPicker(false);
    delete requestData.file;

    if (!requestData?.companyUrl || requestData?.companyUrl === '')
      requestData['companyUrl'] = null;

    await dispatch(updateCompanyDetails(requestData))
      .unwrap()
      .then(res => {
        toast.success(res?.message);
        dispatch(getCompanySettings());
      })
      .catch(error => {
        toast.error(error?.message);
      });
  };

  const requiredFieldLabel = (labelText, htmlFor) => {
    return (
      <label className="form-label" htmlFor={htmlFor}>
        {labelText}
        <span
          className="absolute top-0 left-0"
          style={{ transform: "translate(-50%, -50%)", color: "#FF0000" }}
        >
          {" "}
          *
        </span>
      </label>
    );
  };

  const handleUpdateSubscription = (id) => {
    const requestData = {
      subscriptionId: id,
    };
    dispatch(updateSubscription(requestData))
      .unwrap()
      .then((data) => {
        const response = data.response.data;
        if (response.name === 'Success') {
          toast.success(response.message || "Updated Successfully");
          setIsModalVisibleOnSuccess(true);
        } else {
          toast.error(response.message || "Something went wrong");
        }
        handleClose();
      })
      .catch((error) => {
        toast.error(error.message || "Request Failed Please Try Again ");
      });
  };


  const isAnyCustomPlan = subscriptionPlans.filter(item => item.isCustom);
  return (
    <>
      <div className={`settings h-auto ${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`}>
        <div className='d-flex justify-content-start'>
          <h1 className='settingTittle'>{t('settings')}</h1>
        </div>

        <Tabs defaultActiveKey={1}
          activeKey={activeTab}
          onSelect={(id) => setActiveTab(id)} animation='false' id="settings-tabs">
          <Tab eventKey={1} title={`${t('settingstab.general')}`}>
            <div className='white_container mt-4 p-3 py-0' style={{ position: 'relative' }}>
              <div className='d-flex align-items-center justify-content-between green-header px-2'>
                <div className='d-flex align-items-center'>
                  <img src={ProfileIcon} style={{ width: 24, objectFit: 'contain' }} />
                  <h2 className='text-green px-1'>{t('teams.profile')}</h2>
                </div>
              </div>
              <div className='profile-White-Card'>
                {isLoading && <LoadingComponent />}
                <div className='uploadCompanylogo' style={{ backgroundColor: companyDetails?.backgroundColor ?? '#ffffff' }}>
                  <div>
                    {
                      companyDetails?.companyLogo ?
                        <img className='uploadCompanylogoImg' src={companyDetails?.companyLogo} /> :
                        <img className='uploadCompanylogoImg' src={CompanyColorLogo} />
                    }
                  </div>
                  <div className="picEditIconCss">
                    <img src={PicEditIcon} className="picEditIconCss" onClick={handleShowColorPicker} />
                  </div>
                </div>
                <div>
                  <div className='companyTextCss'>Company</div>
                  <div className='companyTextCssTwo mx-4'>{companyDetails?.companyName}</div>
                </div>
              </div>
              <form>
                <label className="form-label" htmlFor="companyURL">Company URL</label>
                <input type="text" disabled={true} value={companyDetails?.companyUrl || ""} id="companyURL" className="form-control form-control-lg" />
              </form>
            </div>
          </Tab>
          <Tab eventKey={2} title={`${t('settingstab.accounting')}`}>
            <div className='inner-tabs mt-4 pb-1'>
              <Tab.Container
                activeKey={accountingTabs}
                onSelect={(key) => setAccountingTabs(key)}
              >
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      {t("settingstab.setup")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      {t("settingstab.tax_codes")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      {t("settingstab.categories")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="forth">
                      {t("settingstab.tags")}
                    </Nav.Link>
                  </Nav.Item>
                  {connectedIntegration?.isConnected && (
                    <Nav.Item>
                      <Nav.Link eventKey="fifth">
                        {t("settingstab.employee")}
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {connectedIntegration?.isConnected && (
                    <Nav.Item>
                      <Nav.Link eventKey="sixth">
                        {t("settingstab.corporate-cards")}
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
                <Tab.Content className="mt-4">
                  <Tab.Pane eventKey="first">
                    <Setup
                      connectedIntegration={connectedIntegration}
                      selectedIntegration={selectedIntegration}
                      setSelectedIntegration={setSelectedIntegration}
                      setAccountingTabs={setAccountingTabs}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <TaxCodes connectedIntegration={connectedIntegration} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Categories connectedIntegration={connectedIntegration} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="forth">
                    <Tags connectedIntegration={connectedIntegration} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <Employee connectedIntegration={connectedIntegration} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth">
                    <CorporateCards connectedIntegration={connectedIntegration} />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Tab>
          <Tab eventKey={3} title={`${t('settingstab.reviewers_settings')}`}>
            <div className='d-flex align-items-center flex-row-reverse mt-3 p-3 mb-3'>
              <PrimaryButton 
                type={'button'}
                onClick={() => { handleShow('add'); setPolicyId(null); reset(); }}
                buttonText={`+ ${t('settingstab.add_new_policy')}`}
                isEnabled={true}
                id={'show_add_policy'}
                isLoading={isLoading}
                style={{width: '170px'}}
              />
            </div>
            <div className='inner-tabs' style={{ position: 'relative' }}>
              {isLoading && <LoadingComponent />}
              {reviewerPolicyData?.length > 0 &&
                <div>
                  <div className='table-responsive' style={{ border: 0 }}>
                    <table id="reviewerPolicyTable" style={{ borderCollapse: 'separate', borderSpacing: '0 16px' }} cellSpacing="0" width="100%">
                      <thead>
                        <tr className='table-head' style={{ verticalAlign: 'top' }}>
                          <th scope="col">{t('settingstab.policy_name')}</th>
                          <th scope="col">{t('settingstab.max_card_transactions_amount')}</th>
                          <th scope="col">{t('settingstab.topup_requests_amount')}</th>
                          <th scope="col">{t('settingstab.max_reimbursement_requests_amount')}</th>
                          <th scope="col">{t('actions')}</th>
                        </tr>
                      </thead>
                      <tbody className='table-body'>
                        {reviewerPolicyData && reviewerPolicyData?.map(policy => (
                          <tr className={`${currentLanguageCode === 'en' ? 'table-body-tr' : 'table-body-tr-arabic'}`} key={policy?.reviewerPolicyId}>
                            <td>{policy?.policyName}</td>
                            <td>{formatQAR(policy?.maxcardTransactionsAmount || 0)} {t('teams.QAR')}</td>
                            <td>{formatQAR(policy?.maxCardTopupAmount || 0)} {t('teams.QAR')}</td>
                            <td>{formatQAR(policy?.maxReimbursementsAmount || 0)} {t('teams.QAR')}</td>
                            <td>
                              <a style={{ cursor: 'pointer' }} id={`edit-policy-${policy?.reviewerPolicyId}`}>
                                <span onClick={() => getDetail(policy?.reviewerPolicyId)} className='tableEditBtn'>
                                  {t('edit')}
                                </span>
                              </a>
                              <a style={{ cursor: 'pointer' }} id={`delete-policy-${policy?.reviewerPolicyId}`}>
                                <span 
                                  onClick={() => { setShowConfirmation(true); setPolicyId(policy?.reviewerPolicyId); }}
                                  className='tableDeleteBtn' style={{ marginLeft: 10, marginRight: 10 }}>
                                    {t('delete')}
                                </span>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </div>
          </Tab>
          <Tab eventKey={4} title={`${t('settingstab.billing_subscription')}`}>
            <div className='col-12 inner-tabs mt-4 p-3' style={{ position: 'relative' }}>
              <div className='d-flex align-items-center green-header px-3'>
                <img src={Transaction} style={{ width: 24, objectFit: 'contain' }} />
                <h2 className='text-green px-2'>{t('settingstab.outstanding_amount')}</h2>
              </div>
              <div className='d-flex align-items-end flex-wrap px-2' style={{ marginBottom: 24, marginTop: 32, zIndex: 10 }}>
                <div className='box-1 d-flex align-items-end' style={{ width: 'fit-content', position: 'relative' }}>
                  {isLoading && <LoadingComponent />}
                  <h1 className='greenAmountTextCss' style={{ fontSize: 36 }}>{billingAmountData.outstandingamount ? billingAmountData.outstandingamount.toLocaleString() : 0}.</h1>
                  <h3 className='qarSmallText'>{t('settingstab.00QAR')}</h3>
                </div>
                <h3 className='paidAutomaticallyText' style={{ marginLeft: 10, marginRight: 10 }}>{t('settingstab.billing_date')}</h3>
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <PrimaryButton 
                  type={'button'}
                  onClick={() => postPayAmount()}
                  buttonText={t('settingstab.pay_now')}
                  isEnabled={true}
                  id={'pay_now'}
                  isLoading={isLoading}
                  style={{width: '100px', zIndex: 2}}
                />
                <div className='pe-3'></div>
                <DangerButton 
                  type={'button'}
                  buttonText={t('settingstab.billing_history')}
                  id={'billing_history'}
                  style={{width: '150px', zIndex: 2}}
                />
              </div>
              <img src={lineBackground} height={118} style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 1, width: '100%' }} />
            </div>
            <div className='col-12 border-grey mt-4 p-3 inner-tabs'>
              <div className='d-flex align-items-center green-header px-3' style={{ marginBottom: 24 }}>
                <img src={PlanIcon} style={{ width: 24, objectFit: 'contain' }} />
                <h2 className='green-header-text ps-1'>{t('settingstab.subscription')}</h2>
              </div>
              <div className="row ml-3 subscriptionPlansCss" style={{ position: 'relative' }}>
                {isLoading && <LoadingComponent />}
                {subscriptionPlans &&
                  subscriptionPlans.map((sub, index) => {
                    const isActive = sub?.activesub === "1";
                    const isCurrent = sub?.currentSubs === true;
                    const colorStyle = { color: isCurrent || isActive ? "#333333" : '#8B9299' };
                    return (
                      <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-2">
                        <div className={`subscriptionPlansCard ${isActive ? "subscriptionPlansCard-Active" : ""}`}
                          style={{ backgroundColor: isCurrent ? '#5DDCB7' : "#fff" }}>
                          <div className="cardContent">
                            <p className={isActive ? "subscriptionPlanMainTittle-Active" : "subscriptionPlanMainTittle"}>
                              {t(sub?.subscriptionName)}
                            </p>
                            <p className={isActive ? "billedMonthlyText-Active" : "billedMonthlyText"}
                              style={colorStyle}>
                              {t("settingstab.billed_monthly.")}
                            </p>
                            <div style={{ maxHeight: '2rem' }}>
                              <p className={`${isActive ? "PlanGrayDes-Active" : "PlanGrayDes"}`}
                                style={colorStyle}>
                                {sub.description}
                              </p>
                            </div>
                            <div className="d-flex align-items-end mb-2">
                              <p className={isActive ? "subscriptionPlanMainTittle-Active mb-0" : "subscriptionPlanMainTittle mb-0"}>
                                {sub.licensePrice} QAR
                              </p>
                              <p className={isActive ? "PlanGrayText-Active mb-1 ps-1" : "PlanGrayText mb-1 ps-1"}
                                style={colorStyle}>/monthly</p>
                            </div>
                            <p className={isActive ? "whatIncludedText-Active" : "whatIncludedText"}>What’s included</p>
                            {[sub?.pricePerCard, sub?.noOfUsers, sub?.noOfCards].map((item, idx) => (
                              <div className="d-flex align-items-center mb-2" key={idx}>
                                <img src={CheckIconGreen} style={{ width: 24, objectFit: "contain", marginRight: 10, marginLeft: 10 }} />
                                <p className={isActive ? "PlanGrayText-Active mb-0" : "PlanGrayText mb-0"}
                                  style={colorStyle}>
                                  {t(idx === 1 ? "settingstab.users_numbers" : idx === 2 ? "settingstab.cards_numbers" : "settingstab.QAR_for_card", {
                                    number: item,
                                    cards: 1
                                  })}
                                </p>
                              </div>
                            ))}

                            {/* Render modules */}
                            {sub?.modules?.map((el, idx) => (
                              <div className="d-flex align-items-center mb-2" key={idx}>
                                <img src={CheckIconGreen} style={{ width: 24, objectFit: "contain", marginRight: 10, marginLeft: 10 }} />
                                <p className={`${isActive ? "PlanGrayText-Active mb-0" : "PlanGrayText mb-0"}`}
                                  style={colorStyle}>
                                  {t(el)}
                                </p>
                              </div>
                            ))}
                          </div>
                          {!isAnyCustomPlan?.length ?
                            <PrimaryButton 
                              type={'button'}
                              onClick={() => {
                                if (!isCurrent) {
                                  setShowModal(sub?.subscriptionId);
                                }
                              }}
                              buttonText={isCurrent ? t('users.current_Plan') : t('users.choose')}
                              isEnabled={!isCurrent}
                              id={`upgrade-toPlan-${sub?.subscriptionId}`}
                              isLoading={isLoading}
                            />
                            : <></>}

                        </div>

                        <ConfirmationDialog
                          show={showModal === sub?.subscriptionId}
                          onConfirm={() => {
                            handleUpdateSubscription(sub?.subscriptionId);
                            setShowModal(null);
                          }}
                          onClose={() => setShowModal(null)}
                          message="Are you sure you want to upgrade your subscription plan?"
                        />
                        <ConfirmationDialog
                          show={isModalVisibleOnSuccess}
                          onConfirm={() => setIsModalVisibleOnSuccess(false)}
                          confirmTitle={'ok'}
                          onClose={() => setIsModalVisibleOnSuccess(false)}
                          message="Please logout and login again to see the updated subscriptions"
                        />
                      </div>
                    );
                  })}
              </div>

            </div>

          </Tab>
        </Tabs>
      </div>
      <Modal className='UserModal' show={show} onHide={handleClose} style={{ paddingRight: 0 }}>
        <div style={{position: 'relative'}}>
          {isLoading && <LoadingComponent />}
          <Modal.Body>
            <div className='d-flex align-items-center justify-content-between green-header px-3' style={{ marginBottom: 8 }}>
              <h2 className='green-header-text'> {title}</h2>
              <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                <img src={ImagesCross} style={{ width: 24, objectFit: 'contain' }} />
              </div>
            </div>
            <form>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t('settingstab.policy_name'), 'policyName')}
                <input type="text" id="policyName" className="form-control form-control-lg"
                  placeholder={`Enter ${t('settingstab.policy_name')}`}
                  {...register('policyName', {
                    required: `${t('settingstab.policy_name')} Is Required.`,
                  })}
                />
                {errors?.policyName && (
                  <p style={{ color: 'red' }}>{errors?.policyName?.message}</p>
                )}
              </div>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t('settingstab.max_card_transactions_amount'))}
                <CurrencyInput
                  type="text"
                  id="maxCardTransactions"
                  className="form-control form-control-lg"
                  placeholder={`Enter ${t('settingstab.max_card_transactions_amount')}`}
                  autoComplete="off"
                  value={watch('maxcardTransactionsAmount')}
                  disablenegative="true"
                  decimalsLimit={2}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === '-') {
                      e.preventDefault();
                    }
                  }}
                  {...register('maxcardTransactionsAmount', {
                    required: `${t('settingstab.max_card_transactions_amount')} Is Required.`,
                  })}
                  onValueChange={(value) => {
                    const numericValue = parseFloat(value?.replace(/,/g, ''));

                    if (!isNaN(numericValue) && numericValue < MAX_VALUE && String(Math.floor(numericValue)).length <= 6) {
                      setLastValidTransactions(value);
                      setValue(
                        'maxcardTransactionsAmount',
                        value === '' ? '' : value?.startsWith('.') ? `0${value}` : value
                      );
                    } else {
                      setValue('maxcardTransactionsAmount', lastValidTransactions);
                    }
                  }}
                />
                {errors?.maxcardTransactionsAmount && (
                  <p style={{ color: 'red' }}>{errors?.maxcardTransactionsAmount?.message}</p>
                )}
              </div>
              <div className="form-outline mt-4 mb-3">
                {requiredFieldLabel(t('settingstab.max_topup_requests'))}
                <CurrencyInput
                  type="text"
                  id="maxTopupRequests"
                  className="form-control form-control-lg"
                  placeholder={`Enter ${t('settingstab.max_topup_requests')}`}
                  value={watch('maxCardTopupAmount')}
                  disablenegative={'true'}
                  decimalsLimit={2}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === '-') {
                      e.preventDefault();
                    }
                  }}
                  {...register('maxCardTopupAmount', {
                    required: `${t('settingstab.max_topup_requests')} Is Required.`,
                  })}
                  onValueChange={(value) => {
                    const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;

                    if (!isNaN(numericValue) && numericValue < MAX_VALUE && String(Math.floor(numericValue)).length <= 6) {
                      setLastValidTopup(value);
                      setValue(
                        'maxCardTopupAmount',
                        value === '' ? '' : value?.startsWith('.') ? `0${value}` : value
                      );
                    } else {
                      setValue('maxCardTopupAmount', lastValidTopup);
                    }
                  }}
                />
                {errors?.maxCardTopupAmount && (
                  <p style={{ color: 'red' }}>{errors?.maxCardTopupAmount?.message}</p>
                )}
              </div>
              <div className="form-outline mt-4 mb-4">
                {requiredFieldLabel(t('settingstab.max_reimbursement_requests_amount'))}
                <CurrencyInput
                  type="text"
                  id="maxReimbursementRequests"
                  className="form-control form-control-lg"
                  placeholder={`Enter ${t('settingstab.max_reimbursement_requests_amount')}`}
                  value={watch('maxReimbursementsAmount')}
                  disablenegative={'true'}
                  decimalsLimit={2}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === '-') {
                      e.preventDefault();
                    }
                  }}
                  {...register('maxReimbursementsAmount', {
                    required: `${t('settingstab.max_reimbursement_requests_amount')} Is Required.`,
                  })}
                  onValueChange={(value) => {
                    const numericValue = parseFloat(value?.replace(/,/g, '')) || 0;

                    if (!isNaN(numericValue) && numericValue < MAX_VALUE && String(Math.floor(numericValue)).length <= 6) {
                      setLastValidRequests(value);
                      setValue(
                        'maxReimbursementsAmount',
                        value === '' ? '' : value?.startsWith('.') ? `0${value}` : value
                      );
                    } else {
                      setValue('maxReimbursementsAmount', lastValidRequests);
                    }
                  }}
                />
                {errors?.maxReimbursementsAmount && (
                  <p style={{ color: 'red' }}>{errors?.maxReimbursementsAmount?.message}</p>
                )}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer className='px-0 pt-4'>
            <SecondaryButton 
              type={'button'}
              onClick={() => { handleClose(); reset(); setPolicyId(null); }}
              buttonText={t('cancel')}
              id={'cancel_add_policy'}
              style={{width: '96px'}}
            />
            
            <PrimaryButton 
              type={'button'}
              onClick={handleSubmit(onSubmit)}
              buttonText={policyId ? t('update') : t('add')}
              isEnabled={true}
              id={'submit_add_policy'}
              isLoading={isLoading}
              style={{width: '149px'}}
            />
          </Modal.Footer>
        </div>
      </Modal>
      <Modal size='lg' centered className='ColorPickerModal' show={showColorPicker} onHide={handleCloseColorPicker} animation='false'>
        <Modal.Header>
          <div onClick={handleCloseColorPicker} className=' align-items-center' style={{ cursor: 'pointer' }}>
            <img src={ImagesCross} width={24} style={{ objectFit: 'contain' }} />
          </div>
        </Modal.Header>
        <Modal.Body style={{ position: 'relative' }}>
          <div>
            <div className='colorPickerModalDiv'>
              <div className='uploadImgSection'>
                {showCropper && imageSrc ? (
                  <>
                    <div style={{ position: 'absolute', width: '770px', height: '430px', backgroundColor: '#fafafa', padding: 20, borderRadius: 20, border: 'solid 1px #000' }}>
                      <div style={{ position: 'absolute', width: '730px', height: '320px' }}>
                        <Cropper
                          image={imageSrc}
                          crop={crop}
                          zoom={zoom}
                          aspect={25 / 12}
                          onCropChange={setCrop}
                          onZoomChange={setZoom}
                          onCropComplete={onCropComplete}
                          style={{ borderRadius: 15 }}
                        />
                      </div>
                      <PrimaryButton 
                        type={'button'}
                        onClick={showCroppedImage}
                        buttonText={t("settingstab.crop_image")}
                        isEnabled={true}
                        id={'crop_image'}
                        style={{position: 'absolute', bottom: 10, width: '149px'}}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {details?.companyLogo ? (
                      <img
                        src={details?.companyLogo ?? CompanyColorLogo}
                        style={{ backgroundColor: color }}
                        className="prvImgInUploadSec"
                      />
                    ) : (
                      <div
                        className="clickToUpload"
                        style={{ backgroundColor: color }}
                        onClick={() => companyLogoRef.current.click()}
                      >
                        <div
                          className="d-flex align-items flex-column"
                          style={{ height: "inherit" }}
                        >
                          <div className="mb-auto mx-auto">
                            <img
                              className="uploadImgsectionImg"
                              src={UploadImgColorPickerSec}
                              style={{ marginLeft: 10 }}
                            ></img>
                            <div className="clickToUploadText">
                              Click to upload
                            </div>
                          </div>
                          <div
                            className="logoText"
                            style={{ textAlign: "center" }}
                          >
                            LOGO
                          </div>
                          <div
                            className="pngSizeText"
                            style={{ textAlign: "center" }}
                          >
                            PNG (max 800 x 400px)
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className='colorPickerSection'>
                <div className="d-flex align-items-start flex-column" style={{ minHeight: 263 }}>
                  <div className='mb-auto'>
                    <div className='pickColorText'>Select Background Color <span className="absolute top-0 left-0" style={{ transform: 'translate(-50%, -50%)', color: '#FF0000' }}> *</span></div>
                    <div className='d-flex align-items-center'>
                      <div className='colorPickerCustom'>
                        <div className='swatch' onClick={handleClick}>
                          <div className='color' style={{ background: color }} />
                        </div>
                        {displayColorPicker && (
                          <div className='popover'>
                            <div className='cover' onClick={handleCloseColor} />
                            <SketchPicker disableAlpha color={color} onChange={handleChange} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='uploadLogoLink' onClick={() => companyLogoRef.current.click()} >
                    <img className='uploadLogoImgLink' src={UploadLogoIcon}></img>
                    <input type="file" id='upload_image' accept=".jpg, .jpeg, .png" ref={companyLogoRef} style={{ display: 'none' }} onChange={handleImageUpload} />
                    <div className="pngSizeCss">{details?.companyLogo ? "Reupload Logo" : "Upload Logo"}</div>
                  </div>
                </div>
              </div>
            </div>
            <label className="form-label" htmlFor="companyurl">Company URL</label>
            <input value={details?.companyUrl} placeholder='www.company.com' onChange={event => { setDetails(prev => ({ ...prev, companyUrl: event.target.value })); setCompanyURlError(false); }} type="text" id="companyurl" className="form-control form-control-lg" />
            {
              companyURlError && <p style={{ color: 'red', fontSize: 15, paddingLeft: 10 }}>Please enter a valid URL.</p>
            }
            <div className='d-flex justify-content-end'>
              <PrimaryButton 
                type={'button'}
                onClick={handleSave}
                buttonText={"save"}
                isEnabled={true}
                id={'save'}
                isLoading={isLoading}
                style={{marginTop: '16px', width:'149px'}}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ConfirmationDialog
        show={showConfirmation}
        onConfirm={handleConfirm}
        onClose={() => { setShowConfirmation(false); setPolicyId(null); }}
        message="Are you sure you want to proceed to delete?"
      />
    </>
  );
}

export default Settings;