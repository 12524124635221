import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addDepartment, deleteDepartment, fetchAllDepartments } from './teamSlice';
import { Tree, TreeNode } from 'react-organizational-chart';
import './Teams.scss';
import Modal from 'react-bootstrap/Modal';
import LoadingComponent from '../../components/Loader/LoadingComponent';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import CrossImage from '../../assets/images/close-square-8.png';
import cookies from 'js-cookie';
import { isCompanyActive } from '../../utils/verifyCompanyStatus';
import SecondaryButton from '../../components/Buttons/SecondaryButton';
import PrimaryButton from '../../components/Buttons/PrimaryButton';

const TreeButton = ({ onClick, title, plusClick, hideCircle, hideArrow, minusClick, employeeCount = 0 }) => {
  return (
    <div className='teams-button-bg'>
      <div className='position-relative d-flex justify-content-center'>
        <div hidden={hideArrow} style={{ background: '#24473D', width: 'fit-content', borderRadius: '15px 0px 0px 15px', border: '0.61px #EDEDEDED' }}>
          <button onClick={minusClick} className='btn-team-main-secondary team1 ' style={{ color: '#000 !important' }} id='minus'>-</button>
        </div>
        <button
          onClick={onClick}
          className={`btn-team-main-primary text-capitalize`}
          style={{
            ...(hideArrow && {
              borderTopLeftRadius: '15px',
              borderBottomLeftRadius: '15px'
            })
          }}
          id='employees'
        >
          <div style={{ display: 'block' }}>
            <div>{title}</div>
            <div>({employeeCount})</div>
          </div>
        </button>
        <div style={{ background: '#24473D', width: 'fit-content', borderRadius: '0px 15px 15px 0px', border: '0.61px #EDEDEDED' }}>
          <button onClick={plusClick} className='btn-team-main-secondary team2' id='plus'>+</button>
        </div>
        <div hidden={hideCircle} className='teams-tree-circle' />
        <div hidden={hideArrow} className='teams-tree-arrow' ><i className="arrow down"></i></div>
      </div>
    </div>

  );
};

function Teams() {
  const { loggedInUser } = useSelector((state) => state.userReducer);
  const { isLoading, departments } = useSelector((state) => state.teamSlice);
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showThree, setShowThree] = useState(false);
  const [parentDep, setParentDep] = useState(null);
  const form3 = useForm();
  const form3Error = form3.formState?.errors;
  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'gb',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
    },
  ];
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    dispatch(fetchAllDepartments());
  }, []);


  const handleCloseModalThree = () => setShowThree(false);

  let navigate = useNavigate();
  const routeChange = (selectedDepartment) => {
    let state = { departmentName: selectedDepartment.departmentName, departmentId: selectedDepartment.departmentId };
    let path = `/team/maincompany/${selectedDepartment.departmentName}/${selectedDepartment.departmentId}`;
    navigate(path, { state });
  };

  const handlePlusClick = (id) => {
    if (!isCompanyActive()) return;

    setParentDep(id);
    setShowThree(!showThree);
  };
  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title');
  }, [currentLanguage, t]);


  const ChildNode = ({ node }) => {
    return (
      <>
        {
          node?.map(el =>
            <TreeNode key={`TreeBtn-${el?.departmentId}`} label={
              <TreeButton
                hideCircle={!(el?.children?.length > 0)}
                title={el?.departmentName}
                onClick={() => routeChange({ departmentName: el?.departmentName, departmentId: el?.departmentId })}
                plusClick={() => handlePlusClick(el?.departmentId)}
                minusClick={() => removeDepartment(el?.departmentId)}
                currentLanguageCode={currentLanguageCode}
                employeeCount={el?.employeeCount}
              />
            }>
              {
                el?.children && el?.children?.length > 0 &&
                <ChildNode node={el?.children} key={`childNode-${el?.departmentId}-${el?.parentId}`} />
              }
            </TreeNode>
          )
        }
      </>
    );
  };

  const onSubmit = (data) => {
    data.parentId = parentDep;
    dispatch(addDepartment(data))
      .unwrap()
      .then((res) => {
        dispatch(fetchAllDepartments());
        toast.success(res?.name);
        form3.reset();
        handleCloseModalThree();
        setParentDep(null);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };

  const removeDepartment = (departmentId) => {
    dispatch(deleteDepartment(departmentId))
      .unwrap()
      .then((res) => {
        dispatch(fetchAllDepartments());
        toast.success(res?.message);
      }).catch((error) => {
        toast.error(error?.message);
      });
  };


  return (
    <div className={`main-teams-page ${currentLanguageCode === 'en' ? 'white-container' : 'white-container-arabic'}`} style={{ position: 'relative' }}>
      <div className='d-flex align-items-center justify-content-between'>
        <h1 className='text-green mb-3'>{t('sidebar.teams')}</h1>
      </div>
      {
        (isLoading || departments === null) && <LoadingComponent />
      }
      <div dir="ltr" style={{ overflow: 'hidden', overflowX: 'scroll', paddingBottom: 20 }}>
        <Tree
          lineWidth={'3px'}
          lineColor={'#00825D'}
          lineBorderRadius={'30px'}
          lineHeight={departments?.children?.length > 0 ? '50px' : '0px'}
          label={
            <TreeButton
              hideCircle={!(departments?.children?.length > 0)}
              title={loggedInUser.departmentParentName}
              onClick={() => routeChange({ departmentName: loggedInUser.departmentParentName, departmentId: loggedInUser.departmentParent })}
              hideArrow={true}
              plusClick={() => handlePlusClick(loggedInUser.departmentParent)}
              employeeCount={departments?.employeeCount}
            />
          }
        >
          {
            departments && departments?.children?.length > 0 &&
            <ChildNode node={departments?.children} key={`childNode-${loggedInUser.departmentParent}`} />
          }
        </Tree>
      </div>
      <Modal className='TaxcodeModalOne' show={showThree} onHide={handleCloseModalThree} style={{ paddingRight: 0 }}>
        {
          isLoading && <LoadingComponent />
        }
        <form>
          <Modal.Body>
            <div className='d-flex justify-content-between align-items-start green-header p-2'>
              <div className='col-9'>
                <p className='text-green modal-title ' style={{ fontSize: 20 }}>
                  {t('teams.add_department')}
                </p>

              </div>
              <div onClick={() => { handleCloseModalThree(); form3.reset(); }} style={{ cursor: 'pointer' }} id='close_modal'>
                <img src={CrossImage} style={{ width: 20, objectFit: 'contain' }} />
              </div>

            </div>
            <p className='text-grey'>{t('teams.add_department_desc')}</p>
            <div className="form-outline mt-4 mb-3">
              <label className="form-label" htmlFor="depatmentName">{t('teams.new_department_name')}</label>
              <input type="text" id="depatmentName" className="form-control form-control-lg"
                placeholder={`${t('enter_name')}`}
                {...form3.register('departmentName', {
                  required: `${t('enter_name')}`,
                  pattern: {
                    value: /^[A-Za-z0-9 ]+$/,
                    message: "Only alphanumeric are allowed"
                  }
                })}
              />
              {form3Error?.departmentName && (
                <p style={{ color: 'red' }}>{form3Error?.departmentName?.message}</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <SecondaryButton 
              type={'button'}
              onClick={(e) => { handleCloseModalThree(); form3.reset(); e.preventDefault(); }}
              buttonText={t('cancel')}
              isEnabled={true}
              id={'cancel_add'}
              style={{width: '96px'}}
            />
            <PrimaryButton 
              type={'button'}
              onClick={form3.handleSubmit(onSubmit)}
              buttonText={t('add')}
              isEnabled={true}
              id={'submit_add'}
              style={{width: '148px'}}
              isLoading={isLoading}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default Teams;
