import React from 'react';
import PhysicalCard from "../../assets/images/physical_card.png";
import VirtualCard from "../../assets/images/virtual_card.png";
import XpendlessLeftCurveCard from "../../assets/images/xpendless_left_curve_card.png";
import XpendlessRightCurveCard from "../../assets/images/xpendless_right_curve_card.png";
import { useTranslation } from 'react-i18next';

export default function TransactionPage() {
  const { t } = useTranslation();

  return (
    <div 
      className="d-flex flex-column" 
      style={{ 
        position: 'relative', 
        alignItems: 'flex-start', 
        overflowX: 'hidden', 
        width: "100%"
      }}
    >
      <div className="cards-display">
        <div className="cards-wrapper">
          <img
            src={XpendlessRightCurveCard}
            alt="Xpendless Card"
            className="card-image right-curve"
          />
          <img
            src={XpendlessLeftCurveCard}
            alt="Xpendless Card"
            className="card-image left-curve"
          />
        </div>
      </div>

      <div 
        className="d-flex align-items-center flex-wrap text-white  card-selection-container relative" 
        style={{ 
          gap: '25px', 
          zIndex: 50 
        }}
      >
        <div className="d-flex align-items-center">
          <input 
            className="form-check-input m-2 custom-radio" 
            type="radio" 
            value="physical" 
            checked={true} 
            style={{cursor: 'auto'}}
            disabled={true}
          />
          <img
            src={PhysicalCard}
            alt="Physical Card Icon"
            className="mx-1 physical-card-img"
          />
          <span className='physical-card' >
            {t("walkThrough.physical_card")}
          </span>
        </div>

        <div className="d-flex align-items-center ml-5">
          <input 
            className="form-check-input m-2 custom-radio" 
            type="radio" 
            value="virtual" 
            style={{cursor: 'auto'}}
            disabled={true}
          />
          <img
            src={VirtualCard}
            alt="Virtual Card Icon"
            className="mx-1"
          />
          <span  className='virtual-card'>
            {t("walkThrough.virtual_card")}
          </span>
        </div>
      </div>
    </div>
  );
}