import React, { useState } from 'react';
import './Login.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import Logo2 from '../../assets/images/polo.png';
import login_bottom_image from '../../assets/images/login_bottom_image.png';
import PrimaryButton from '../../components/Buttons/PrimaryButton';

function SetPasscode() {
    const { state } = useLocation();
    const { t } = useTranslation();

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(yup.object().shape({
            password: yup
                .string()
                .required('Password is required')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%?&+=*^<>/|()"':,.;`~{}_[\]\\-])[A-Za-z\d@#$!%?&+=*^<>/|()"':,.;`~{}_[\]\\-]{8,}$/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.'
                )
        })),
        defaultValues: {
            password: '',
        },
    });

    const [passwordType, setPasswordType] = useState("password");
    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    };

    let navigate = useNavigate();

    const onSubmit = (data) => {
        if (state?.registerData) navigate('/confirmPassword', { state: { registerData: { ...state?.registerData, password: data?.password } } });
        if (state?.forgotData) navigate('/confirmPassword', { state: { forgotData: { ...state?.forgotData, password: data?.password } } });
        if (!state?.registerData && !state?.forgotData) {
            toast.info('Please try again');
            navigate('/login');
        }
    };

    return (
        <section className="vh-100">
            <div className="container-fluid vh-100" style={{ backgroundColor: '#24473D' }}>
                <div className="row d-flex justify-content-between align-items-center h-100 " >
                    <div className=" d-flex flex-column justify-content-between align-items-center my-2">
                        <div className='form-div'>
                            <form className='form' onSubmit={handleSubmit(onSubmit)} >
                                <div>
                                    <img src={Logo2} className="logo mt-1 mb-4" alt="Sample image" />
                                </div>
                                <h1 style={{ maxWidth: '320px' }} className='text-blue-login my-2'>
                                    {t('login.create_your_password')}
                                </h1>
                                <div className="divider d-flex align-items-center my-2">
                                    <p className="mb-0 text-subtitle2" style={{ width: '320px', color: '#8B9299' }} >
                                        {t('login.you_will_use_this_password')}

                                    </p>
                                </div>

                                <div className="otp form-outline mt-4 mb-3">
                                    <div className="form-outline mb-4 position-relative">
                                        <Controller
                                            name="password"
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    id='password'
                                                    {...field}
                                                    type={passwordType}
                                                    className="form-control form-control-lg"
                                                    placeholder={`${t('login.enter_password')}`}
                                                />
                                            )}
                                        />

                                        <p className="text-danger" style={{ width: 305 }}>{errors?.password?.message}</p>

                                        <div className='eye-icon-password' onClick={togglePassword} id='show_password'>
                                            {passwordType === "password" ? <FaEyeSlash size={25} color='#037AD0' /> : <FaEye color='#037AD0' size={25} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center text-lg-start mt-4 w-100">
                                    <PrimaryButton
                                        type={'submit'}
                                        buttonText={t('login.continue')}
                                        isEnabled={true}
                                        id={'continue'}
                                        style={{ position: 'relative', zIndex: '1' }}
                                    />
                                </div>
                            </form>
                        </div>
                        <img className='login_bottom_image-1' src={login_bottom_image} />

                    </div>
                </div>
            </div>
        </section>
    );
}

export default SetPasscode;
