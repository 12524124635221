import React from 'react';
import "./index.scss";
import NoImage from '../assets/images/no-image.jpg';

const ProfileIcon = (props) => {
    const { imageUrl, name, style, imageStyle, textStyle, altText } = props;
    const spitWord = (word) => {
        if (word) {
            // Split the string into words
            const words = word?.split(" ");

            // Get the first letter of the first word and the first letter after a space
            const firstLetter = words[0][0];
            const secondLetter = words.length > 1 ? words[1][0] : words[0][1];

            return firstLetter + secondLetter;
        } else return '';
    };

    const profileIconSize = imageUrl ? '37px' : '47px';

    if (imageUrl || name) {
        return (
            <div className='profileicon' style={{
                ...style,
                '--profile-icon-size': profileIconSize,
            }}>
                {imageUrl &&
                    <img onError={(e) => {
                        e.target.onerror = null;
                        e.target.style.display = 'none';
                        e.target.nextSibling.style.display = 'flex';
                    }} src={imageUrl} style={{ width: 37, height: 37, objectFit: 'fill', borderRadius: 20 }} alt={altText} />
                }
                <div style={{ ...textStyle, display: imageUrl ? 'none' : 'flex', }}>{spitWord(name || '').slice(0, 2).toUpperCase()}</div>
            </div>
        );
    } else {
        return (
            <img src={NoImage} style={{ width: 37, height: 37, objectFit: 'contain', border: "1px solid #EAECF0", borderRadius: 5, ...imageStyle }} alt={altText} />
        );
    }
};

export default ProfileIcon;
