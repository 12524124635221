import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";

import api from "../../utils/api";

const initialState = {
    taxData: [],
    taxTypeData: [],
    isLoading: false,
    taxError: null,
    taxDetail: {},
    iconData: [],
    categoryGroupData: [],
    categoryDetail: {},
    tagGroupData: [],
    tagDetail: {},
    subscriptionPlans: [],
    subscriptionPlansList: [],
    reviewerPolicyData: [],
    reviewerPolicyDetail: {},
    billingAmountData: {},
    companyDetails: null,
    integrations: [],
    connectedIntegration: {},
    oddoCompanyList: [],
    oddoJournalsList: [],
    oddoAccountsList: [],
    oddoReimbursementJournalsList: [],
    oddoTaxList: [],
    oddoExpenseCategory: [],
    oddoTagsList: [],
    oddoEmployeeList: [],
    oddoCorporateCardsList: [],
    updateSubscription: null,
};

export const addTax = createAsyncThunk("registerTax/addTax", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addTax,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const removeTax = createAsyncThunk("registerTax/removeTax", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.deleteTax + `?taxId=${id}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllTaxType = createAsyncThunk("registerTax/getAllTaxType", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllTaxType
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllTax = createAsyncThunk("registerTax/getAllTax", async (page) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllTax + `?page=${page ? page : 1}&isActive=true`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getTaxDetail = createAsyncThunk("registerTax/getTaxDetail", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getTaxDetailsById + `?taxId=${id}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateTax = createAsyncThunk("registerTax/updateTax", async (updateData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateTax,
            data: updateData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const updateMapping = createAsyncThunk("registerTax/updateMapping", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateMapping,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const updateCategories = createAsyncThunk("registerTax/updateCategories", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateCategories,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const updateTags = createAsyncThunk("registerTax/updateTags", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateTags,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const updateEmployee = createAsyncThunk("registerTax/updateEmployee", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateEmployee,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const updateCorporateCards = createAsyncThunk("registerTax/updateCorporateCards", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateCorporateCards,
            data: payload
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addCategoryGroup = createAsyncThunk("registerTax/addCategoryGroup", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addCategoryGroup,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllIcon = createAsyncThunk("registerTax/getAllIcon", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllIcon,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllCategoryByGroup = createAsyncThunk("registerTax/getAllCategoryByGroup", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllCategoryByGroup + '?isActive=true',
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addCategory = createAsyncThunk("registerTax/addCategory", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addCategory,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getCategoryDetail = createAsyncThunk("registerTax/getCategoryDetail", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.getCategoryDetail + `?categoryId=${id}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateCategory = createAsyncThunk("registerTax/updateCategory", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateCategory,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const addTagGroup = createAsyncThunk("registerTax/addTagGroup", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addTagGroup,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getOddoEmployeeList = createAsyncThunk("registerTax/getOddoEmployeeList", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getOddoEmployeeList,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllTagByGroup = createAsyncThunk("registerTax/getAllTagByGroup", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllTagByGroup,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllIntegrations = createAsyncThunk("registerTax/getAllIntegrations", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllIntegrations,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getJournals = createAsyncThunk("registerTax/getJournals", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getJournals,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const getCorporateCardsList = createAsyncThunk("registerTax/getCorporateCardsList", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getCorporateCardsList,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAccounts = createAsyncThunk("registerTax/getAccounts", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAccounts,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const getTagsList = createAsyncThunk("registerTax/getTagsList", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getTagsList,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getTaxList = createAsyncThunk("registerTax/getTaxList", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getTaxList,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getCompany = createAsyncThunk("registerTax/getCompany", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getCompany,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateData = createAsyncThunk("registerTax/updateData", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateData,
            data: payload
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});



export const getExpenseCategory = createAsyncThunk("registerTax/getExpenseCategory", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getExpenseCategory,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const getConnectedIntegration = createAsyncThunk("registerTax/getConnectedIntegration", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getConnectedIntegration,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addTag = createAsyncThunk("registerTax/addTag", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addTag,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const connectIntegration = createAsyncThunk("registerTax/connectIntegration", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.connectIntegration,
            data: postData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getTagDetailsById = createAsyncThunk("registerTax/getTagDetailsById", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.getTagDetailsById + `?tagId=${id}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateTag = createAsyncThunk("registerTax/updateTag", async (updateData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateTag,
            data: updateData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllSubscription = createAsyncThunk("registerTax/getAllSubscription", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.getAllSubscription + `?tagId=${id}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getSubscriptionWithoutAuth = createAsyncThunk("registerTax/getAllSubscriptionWithoutAuth", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllSubscriptionWithoutAuth,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getAllReviewerPolicy = createAsyncThunk("registerTax/fetchReviewerPolicy", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getAllReviewerPolicy,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const removeReviewerPolicy = createAsyncThunk("registerTax/deleteReviewerPolicy", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.deleteReviewerPolicy + `?reviewerPolicyId=${id}`,
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const getReviewerPolicyDetails = createAsyncThunk("registerTax/getReviewerPolicyDetails", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getReviewerPolicyDetailsById + `?reviewerPolicyId=${id}`,
        };
        const response = await api(data);
        return response.data.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateReviewerPolicy = createAsyncThunk("registerTax/updateReviewerPolicy", async (updateData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateReviewerPolicy,
            data: updateData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const addReviewerPolicy = createAsyncThunk("registerTax/addReviewerPolicy", async (postData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addReviewerPolicy,
            data: postData
        };
        const response = await api(data);
        return response.data;
    } catch (error) {
        throw error?.response?.data;
    }
});

export const getBillingAmount = createAsyncThunk("registerTax/getBillingAmount", async () => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getBillingAmount,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const postPayNow = createAsyncThunk("registerTax/postPayNow", async () => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.postPayNow,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteTag = createAsyncThunk("tag/deleteTag", async (tagId) => {
    try {
        let data = {
            method: METHOD_TYPE.delete,
            url: API_ENDPOINTS.deleteTag + `?tagId=${tagId}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const disconnectIntegration = createAsyncThunk("integration/disconnectIntegration", async (id) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.disconnectIntegration,
            data: {
                companyIntegrationId: id
            }
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteTagGroup = createAsyncThunk("tag/deleteTagGroup", async (tagGroupId) => {
    try {
        let data = {
            method: METHOD_TYPE.delete,
            url: API_ENDPOINTS.deleteTagGroup + `?tagGroupId=${tagGroupId}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteCategoryGroup = createAsyncThunk("setting/deleteCategoryGroup", async (categoryGroupId) => {
    try {
        let data = {
            method: METHOD_TYPE.delete,
            url: API_ENDPOINTS.deleteCategoryGroup + `?categoryGroupId=${categoryGroupId}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const deleteCategory = createAsyncThunk("setting/deleteCategory", async (categoryId) => {
    try {
        let data = {
            method: METHOD_TYPE.delete,
            url: API_ENDPOINTS.deleteCategory + `?categoryId=${categoryId}`,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const updateCategoryGroup = createAsyncThunk("setting/updateCategoryGroupName", async (category) => {
    const { body, categoryGroupId } = category;
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateCategoryGroupName + `?categoryGroupId=${categoryGroupId}`,
            data: body
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});
export const updateTagGroup = createAsyncThunk("setting/updateTagGroupName", async (tag) => {
    const { body, tagGroupId } = tag;
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateTagGroupName + `?tagGroupId=${tagGroupId}`,
            data: body
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const getCompanySettings = createAsyncThunk("setting/getCompanySettings", async (fromSetting = true) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getCompanySettings + "?fromSetting=" + fromSetting,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateCompanyDetails = createAsyncThunk("setting/updateCompanyDetails", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateCompanyDetails,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const uploadLogo = createAsyncThunk("setting/uploadLogo", async (image) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.uploadDocument + "?docType=companyLogo",
            data: image,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        throw error?.response?.data;
    }
});

export const updateSubscription = createAsyncThunk("/setting/updateSubscription", async (payload) => {
    try {
        let data = {
            method: METHOD_TYPE.put,
            url: API_ENDPOINTS.updateSubscription,
            data: payload
        };
        const response = await api(data);
        return { params: payload, response };
    } catch (error) {
        throw error?.response?.data;
    }
});

const registerTax = createSlice({
    name: "tax",
    initialState,
    reducers: {
        addTaxData: (state, action) => {
            return {
                ...state,
                registerTax: action.payload.data
            };
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(isAnyOf(getAllTax.pending, getAllTaxType.pending, removeTax.pending, getTaxDetail.pending, addCategoryGroup.pending, getAllIcon.pending, addCategory.pending, getCategoryDetail.pending, updateCategory.pending, addTagGroup.pending, getAllTagByGroup.pending, getAllIntegrations.pending, getCompany.pending, getConnectedIntegration.pending, addTag.pending, getTagDetailsById.pending, updateTag.pending, getAllSubscription.pending, getSubscriptionWithoutAuth.pending, getAllReviewerPolicy.pending, removeReviewerPolicy.pending, getReviewerPolicyDetails.pending, updateReviewerPolicy.pending, addReviewerPolicy.pending, getBillingAmount.pending, postPayNow.pending, deleteTag.pending, deleteCategoryGroup.pending, deleteCategory.pending, updateCategoryGroup.pending, updateSubscription.fulfilled, updateTagGroup.pending, getCompanySettings.pending, updateCompanyDetails.pending, uploadLogo.pending, addTax?.pending, getJournals.pending, getAccounts.pending, getTaxList.pending, getExpenseCategory.pending, updateData.pending, updateMapping.pending, updateCategories.pending, getTagsList.pending, updateTags.pending, getOddoEmployeeList.pending, updateEmployee.pending, getCorporateCardsList.pending, updateCorporateCards.pending), (state) => {
                state.isLoading = true;
            }
            )
            .addMatcher(isAnyOf(getAllTax.rejected, getAllTaxType.rejected, removeTax.rejected, getTaxDetail.rejected, addCategoryGroup.rejected, getAllIcon.rejected, addCategory.rejected, getCategoryDetail.rejected, updateCategory.rejected, addTagGroup.rejected, getAllTagByGroup.rejected, getAllIntegrations.rejected, getCompany.rejected, getConnectedIntegration.rejected, addTag.rejected, getTagDetailsById.rejected, updateTag.rejected, getAllSubscription.rejected, getSubscriptionWithoutAuth.rejected, getAllReviewerPolicy.rejected, removeReviewerPolicy.rejected, getReviewerPolicyDetails.rejected, updateReviewerPolicy.rejected, addReviewerPolicy.rejected, getBillingAmount.rejected, postPayNow.rejected, deleteTag.rejected, deleteCategoryGroup.rejected, deleteCategory.rejected, updateCategoryGroup.rejected, updateSubscription.rejected, updateTagGroup.rejected, getCompanySettings.rejected, updateCompanyDetails.rejected, uploadLogo.rejected, addTax?.rejected, getJournals.rejected, getAccounts.rejected, getTaxList.rejected, getExpenseCategory.rejected, updateData.rejected, updateMapping.rejected, updateCategories.rejected, getTagsList.rejected, updateTags.rejected, getOddoEmployeeList.rejected, updateEmployee.rejected, getCorporateCardsList.rejected, updateCorporateCards.rejected), (state, { error }) => {
                state.isLoading = false;
                state.taxError = error.message ? error.message : "Request Failed Please Try Again ";
            }
            )
            .addMatcher(isAnyOf(getAllTax.fulfilled, removeTax.fulfilled), (state, { payload }) => {
                state.isLoading = false;
                state.taxError = null;
                state.taxData = payload.data;
            })
            .addMatcher(isAnyOf(getAllTaxType.fulfilled), (state, { payload }) => {
                state.isLoading = false;
                state.taxError = null;
                state.taxTypeData = payload.data;
            })
            .addMatcher(isAnyOf(getTaxDetail.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.taxDetail = payload.data;
            })
            .addMatcher(isAnyOf(getAllIcon.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.iconData = payload.message;
            })
            .addMatcher(isAnyOf(addCategoryGroup.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(getAllCategoryByGroup.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.categoryGroupData = payload.message;
            })
            .addMatcher(isAnyOf(addCategory.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(getCategoryDetail.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.categoryDetail = payload.message;
            })
            .addMatcher(isAnyOf(updateCategory.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(addTagGroup.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(getAllTagByGroup.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.tagGroupData = payload.data;
            })
            .addMatcher(isAnyOf(getOddoEmployeeList.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.oddoEmployeeList = payload.data;
            })
            .addMatcher(isAnyOf(getAllIntegrations.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.integrations = payload.data;
            })
            .addMatcher(isAnyOf(getCompany.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.oddoCompanyList = payload.data;
            })
            .addMatcher(isAnyOf(updateData.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.connectedIntegration = {
                    ...state.connectedIntegration,
                    data: payload.data
                };
            })
            .addMatcher(isAnyOf(updateMapping.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(updateCategories.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(updateTags.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(updateEmployee.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(updateCorporateCards.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(getJournals.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.oddoJournalsList = payload.data?.filter(x => x.type === "general");
                state.oddoReimbursementJournalsList = payload.data?.filter(x => x.type === "purchase");
                state.oddoExpenseCategory = payload.data?.filter(x => ["cash", "bank"].includes(x.type));
            })
            .addMatcher(isAnyOf(getAccounts.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.oddoAccountsList = payload.data;
            })
            .addMatcher(isAnyOf(getCorporateCardsList.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.oddoCorporateCardsList = payload.data;
            })
            .addMatcher(isAnyOf(getTaxList.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.oddoTaxList = payload.data;
            })
            .addMatcher(isAnyOf(getTagsList.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.oddoTagsList = payload.data;
            })
            .addMatcher(isAnyOf(getExpenseCategory.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.oddoExpenseCategory = payload.data;
            })
            .addMatcher(isAnyOf(getConnectedIntegration.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.connectedIntegration = payload.data;
            })
            .addMatcher(isAnyOf(addTag.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(getTagDetailsById.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.tagDetail = payload.data;
            })
            .addMatcher(isAnyOf(updateTag.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(getAllSubscription.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.subscriptionPlans = payload.message;
            })
            .addMatcher(isAnyOf(getSubscriptionWithoutAuth.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.subscriptionPlansList = payload.message.filter(item => item.subscriptionName !== 'Special');
            })
            .addMatcher(isAnyOf(getAllReviewerPolicy.fulfilled, removeReviewerPolicy.fulfilled), (state, { payload }) => {
                state.isLoading = false;
                state.taxError = null;
                state.reviewerPolicyData = payload.data;
            })
            .addMatcher(isAnyOf(getReviewerPolicyDetails.fulfilled), (state, { payload }) => {
                state.taxError = null;
                state.isLoading = false;
                state.reviewerPolicyDetail = payload.data;
            })
            .addMatcher(isAnyOf(updateReviewerPolicy.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(addReviewerPolicy.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(getBillingAmount.fulfilled), (state, { payload }) => {
                state.isLoading = false;
                state.taxError = null;
                state.billingAmountData = payload.message;
            })
            .addMatcher(isAnyOf(postPayNow.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(deleteTag.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(deleteCategoryGroup.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(deleteCategory.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(updateCategoryGroup.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(updateTagGroup.fulfilled), (state) => {
                state.taxError = null;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(getCompanySettings.fulfilled), (state, { payload }) => {
                state.companyDetails = payload.data;
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(updateCompanyDetails.fulfilled), (state) => {
                state.isLoading = false;
            })
            .addMatcher(isAnyOf(updateSubscription.fulfilled), (state, { payload }) => {
                const { response, params } = payload;
                const isSuccess = response.data.name === 'Success';
                state.taxError = null;
                state.isLoading = false;
                if (isSuccess) {
                    state.subscriptionPlans = state.subscriptionPlans.map(item => {
                        if (item.subscriptionId === params?.subscriptionId) {
                            return {
                                ...item,
                                currentSubs: true
                            };
                        }
                        return { ...item, currentSubs: false };
                    });
                }
            });
    }


});
export const { addTaxData } = registerTax.actions;
export default registerTax.reducer;