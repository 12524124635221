import React from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../components/Buttons/PrimaryButton';
import DangerButton from '../../components/Buttons/DangerButton';
import Table from '../../assets/images/table.png';

export default function CompanyWalletPage() {
  const { t } = useTranslation();

  return (
    <div className="section-container p-0">
      <div className="mt-2">
        <button className="text-green amount-box" style={{ cursor: 'auto' }}>
          <span className="text-white amount">{t("walkThrough.wallet_amount")}.</span>
          <span className="mt-5" style={{ fontSize: "12px", color: "white", fontWeight: 400 }}>
            {t("walkThrough.wallet_currency")}
          </span>
        </button>
      </div>

      <div className="d-flex align-items-center flex-wrap" style={{ borderRadius: "10px" }}>
        <PrimaryButton
          type={'button'}
          buttonText={t("walkThrough.add_funds")}
          isEnabled={true}
          id={'addFunds'}
          style={{ width: '150px', marginTop: '16px', zIndex: 2, fontWeight: 600, borderRadius: 18, cursor: 'auto' }}
        />
        <div className="pe-2"></div>
        <DangerButton
          type={'button'}
          buttonText={t("walkThrough.withdraw_funds")}
          isEnabled={true}
          id={'withdrawFunds'}
          style={{ width: '170px', marginTop: '16px', zIndex: 2, fontWeight: 500, borderRadius: '18px', border: 'none', cursor: 'auto' }}
        />
      </div>
      <div className="col-md-12 col-lg-11 col-sm-7 p-0">
        <div className="table-image-container mt-2" style={{
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: '15px',
          boxShadow: '2px 4px 5.7px 3px #0000002b'
        }}>
          <img
            src={Table}
            alt="Transaction Table"
            className="table-image"
            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          />
        </div>
      </div>
    </div>
  );
}