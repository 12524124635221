import React from "react";
import './buttonsStyle.scss';

function SecondaryButton({ type, onClick, buttonText, isEnabled = true, id, style, isHighlighted = false, isLoading = false, children }) {
  return (
    <button
      type={type}
      {...(type === "button" && { onClick })}
      className={isEnabled ? isHighlighted ? 'btn-small' : 'btn-secondary-small truncated-text' : 'disabledButton truncated-text'}
      disabled={!isEnabled}
      id={id}
      style={style}
    >
      {isLoading ? (
        <>
          <div
            className="spinner-border spinner-border-sm"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
          &nbsp;<>
            {buttonText}
            {children && <span style={{ marginLeft: 5 }}>{children}</span>}
          </>
        </>
      ) : (
        <>
          {buttonText}
          {children && <span style={{ marginLeft: 5 }}>{children}</span>}
        </>
      )}
    </button>
  );
}

export default SecondaryButton;
